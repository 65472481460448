import theme from '@/theme/themeConfig';
import { ConfigProvider } from 'antd';
import { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';

function Iframe({ children }) {
  const [ref, setRef] = useState<HTMLIFrameElement | null>(null);
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const container = ref?.contentWindow?.document?.body;

  useEffect(() => {
    if (ref?.contentWindow?.document) {
      const doc = ref.contentWindow.document;

      // Load Tailwind CSS
      const script = document.createElement('script');
      script.src = 'https://cdn.tailwindcss.com';
      script.async = true;
      script.onload = () => setIsScriptLoaded(true);
      doc.head.appendChild(script);

      // Add custom CSS
      const style = document.createElement('style');
      style.textContent = `
        .form-item-selected,
        .component-selected {
          position: relative;
        }
        .component-selected::after {
          content: ' ';
          border: 1px solid #005ce6;
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          pointer-events: none;
          display: block;
        }
        .form-item-selected::after {
          content: ' ';
          outline: 1px solid #005ce6;
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          pointer-events: none;
          display: block;
        }
        .gtg-collapse .ant-collapse-item.ant-collapse-item-active {
          outline: 1px solid #005ce6 !important;
        }
        .react-tel-input .form-control {
          width: 100% !important;
          height: 40px !important;
        }
      `;
      doc.head.appendChild(style);

      // Load Ant Design CSS
      const styleLinkAntd = document.createElement('link');
      styleLinkAntd.rel = 'stylesheet';
      styleLinkAntd.href =
        'https://cdnjs.cloudflare.com/ajax/libs/antd/4.24.16/antd.min.css';
      doc.head.appendChild(styleLinkAntd);

      // Load react-phone-input-2 CSS
      const styleLinkPhoneInput = document.createElement('link');
      styleLinkPhoneInput.rel = 'stylesheet';
      styleLinkPhoneInput.href =
        'https://cdn.jsdelivr.net/npm/react-phone-input-2@2.15.1/lib/style.min.css';
      doc.head.appendChild(styleLinkPhoneInput);
    }
  }, [ref]);

  return (
    <iframe
      ref={(instance) => setRef(instance)}
      style={{
        width: '100%',
        height: '100%',
        minHeight: 'calc(100vh - 70px)',
      }}
    >
      {isScriptLoaded && container && createPortal(children, container)}
    </iframe>
  );
}

export default Iframe;
