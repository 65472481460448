export function formatFieldName(input: string): string {
  if (!input) return ""
  // Split the string by underscores
  const words = input.split('_');

  // Capitalize the first letter of each word and join them with spaces
  return words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
}

export const isEqualsValue = (a: any, b: any) => {
  if (Array.isArray(a) && Array.isArray(b)) {
    return a.join('') === b.join('')
  }
  return a === b
}