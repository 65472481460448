import { SearchOutlined } from '@ant-design/icons';
import { Checkbox, Input, List } from 'antd';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { DropdownFilterOption } from './GTGSDropdownFilter';

interface DropdownRenderProps {
  singleValue?: boolean;
  onSelect?: (value: string[], keyword: string) => void;
  options: DropdownFilterOption[];
}

const _DropdownRender = ({
  singleValue,
  onSelect,
  options,
}: DropdownRenderProps) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredOptions, setFilteredOptions] = useState(options);
  const debounceTimerRef = useRef<NodeJS.Timeout | null>(null);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  const debouncedSearch = useCallback((value: string) => {
    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
    }
    debounceTimerRef.current = setTimeout(() => {
      setSearchTerm(value);
    }, 400);
  }, []);

  useEffect(() => {
    return () => {
      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current);
      }
    };
  }, []);

  const handleSelect = useCallback(
    (value: string) => {
      setSelectedItems((prev) => {
        let newSelected: string[];
        if (singleValue) {
          newSelected = prev.includes(value) ? [] : [value];
        } else {
          newSelected = prev.includes(value)
            ? prev.filter((item) => item !== value)
            : [...prev, value];
        }
        onSelect?.(newSelected, searchTerm);
        return newSelected;
      });
    },
    [onSelect, searchTerm, singleValue]
  );

  return (
    <div className="border bg-white rounded-lg">
      <List
        style={{
          maxHeight: '200px',
          overflowY: 'auto',
          backgroundColor: 'white',
          borderRadius: '0 0 4px 4px',
        }}
        dataSource={filteredOptions}
        renderItem={(item) => (
          <List.Item
            style={{ cursor: 'pointer', padding: '8px 12px' }}
            onClick={() => handleSelect(item.value)}
          >
            {singleValue ? (
              <div
                className={`flex items-center ${selectedItems.includes(item.value) ? 'text-blue-500' : ''}`}
              >
                <strong>{item.label}</strong>
                {!!item.subLabel && (
                  <div
                    style={{
                      color: '#888',
                      fontSize: '12px',
                      marginLeft: '8px',
                    }}
                  >
                    {item.subLabel}
                  </div>
                )}
              </div>
            ) : (
              <Checkbox
                checked={selectedItems.includes(item.value)}
                onChange={(e) => handleSelect(item.value)}
              >
                <strong>{item.label}</strong>
                {!!item.subLabel && (
                  <div style={{ color: '#888', fontSize: '12px' }}>
                    {item.subLabel}
                  </div>
                )}
              </Checkbox>
            )}
          </List.Item>
        )}
      />
    </div>
  );
};

const DropdownRender = memo(_DropdownRender);

export { DropdownRender, type DropdownRenderProps };
