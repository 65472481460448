import { useEditor, useNode } from '@craftjs/core';
import { useCallback, useEffect, useRef } from 'react';

import { GTGSIcon } from '@/components/GTGS';
import GTGSTooltip from '@/components/GTGS/GTGSTooltip/GTGSToolTip';
import { DeleteOutlined } from '@ant-design/icons';
import { Divider, message, Modal } from 'antd';
import { createPortal } from 'react-dom';
import { useShallow } from 'zustand/react/shallow';
import { usePageBuilder } from '../store/usePageBuilderStore';
import useJsonSchema from '../store/useJsonSchema';

const { confirm } = Modal;

export const RenderNode = ({ render }) => {
  const { formSettingVisible, setFormSettingVisible } = usePageBuilder(
    useShallow((state) => ({
      formSettingVisible: state.formSettingVisible,
      setFormSettingVisible: state.setFormSettingVisible,
    }))
  );
  message.config({
    duration: 3,
    top: 96,
  });
  const { id } = useNode();
  const { actions, query, isActive } = useEditor((_, query) => ({
    isActive: query.getEvent('selected').contains(id),
  }));

  const {
    isHover,
    dom,
    node,
    connectors: { drag },
  } = useNode((node) => ({
    isHover: node.events.hovered,
    dom: node.dom,
    node: node,
  }));

  const isFormBuilderNode = dom ? dom.getAttribute('data-form') : null;
  const isFormBox = node.data.name === 'FormBox';

  const { requiredProperties } = useJsonSchema();

  const showDeleteConfirm = () => {
    confirm({
      centered: true,
      width: 384,
      title: 'Delete this component?',
      icon: null,
      content:
        'You can add this component in again by drag and drop from the left menu',
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        actions.delete(id);
        message.success({
          content: 'Delete successfully',
        });
      },
      onCancel() {},
      closable: true,
    });
  };

  useEffect(() => {
    if (dom) {
      if (!isFormBox && (isActive || isHover)) {
        dom.classList.add('component-selected');
      } else {
        dom.classList.remove('component-selected');
      }

      if (isFormBuilderNode && (isActive || isHover)) {
        dom.classList.add('form-item-selected');
      } else {
        dom.classList.remove('form-item-selected');
      }
    }
  }, [dom, isActive, isFormBuilderNode, isHover, isFormBox]);

  const currentRef = useRef<HTMLDivElement>(null);

  // TODO: refactor later
  const getPos = useCallback((dom: HTMLElement) => {
    const { top, left, right, bottom } = dom
      ? dom.getBoundingClientRect()
      : { top: 0, right: 0, bottom: 0 };
    return {
      top: `${top > 0 ? top + 38 : bottom}px`,
      right: `${right > 0 ? window.innerWidth - right - 320 + 8 : left}px`,
    };
  }, []);

  const getDragPointPos = useCallback((dom: HTMLElement) => {
    const { top, left, right, bottom } = dom
      ? dom.getBoundingClientRect()
      : { top: 0, right: 0, bottom: 0 };
    return {
      top: `${top > 0 ? top + 70 - 1 : bottom}px`,
      left: `${left ? left + 320 - 8 : 0}px`,
    };
  }, []);

  const scroll = useCallback(() => {
    const { current: currentDOM } = currentRef;

    if (!dom) return;
    if (!currentDOM) return;
    const { top, right } = getPos(dom);
    currentDOM.style.top = top;
    currentDOM.style.right = right;

    const dragPoint = document.querySelector('.drag-point');
    if (dragPoint) {
      const { top: pointTop, left: leftPoint } = getDragPointPos(dom);
      (dragPoint as HTMLElement).style.top = pointTop;
      (dragPoint as HTMLElement).style.left = leftPoint;
    }
  }, [dom, getDragPointPos, getPos]);

  useEffect(() => {
    const iframe = document.querySelector('iframe');
    const renderer = iframe?.contentWindow;
    if (renderer) {
      renderer.addEventListener('scroll', scroll);
    }

    return () => {
      if (renderer) {
        renderer.removeEventListener('scroll', scroll);
      }
    };
  }, [scroll]);

  return (
    <div className="relative">
      {!isFormBox && (isHover || isActive) ? (
        isFormBuilderNode ? (
          <>
            {createPortal(
              <div
                ref={drag as React.LegacyRef<HTMLDivElement>}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'fixed',
                  left: dom ? getDragPointPos(dom).left : '0px',
                  top: dom ? getDragPointPos(dom).top : '0px',
                  zIndex: 10,
                  cursor: 'move',
                  width: 8,
                  background: '#005ce6',
                  gap: 8,
                  height: (dom?.offsetHeight ?? 0) + 2,
                }}
                className="drag-point"
              >
                <GTGSIcon name="dots-three-vertical" size="small" fill="#fff" />
              </div>,
              document.body
            )}
            {createPortal(
              <div
                ref={currentRef}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  position: 'fixed',
                  right: dom ? getPos(dom).right : '0px',
                  top: dom ? getPos(dom).top : '0px',
                  zIndex: 10,
                  cursor: 'pointer',
                  padding: 8,
                  background: '#0F172A',
                  borderRadius: 4,
                  gap: 8,
                }}
              >
                <GTGSTooltip title="Edit" placement="top" color="black">
                  <GTGSIcon
                    name="pencil"
                    size={16}
                    fill="#fff"
                    onClick={() => {
                      actions.selectNode(id);
                      setFormSettingVisible(true);
                    }}
                  />
                </GTGSTooltip>
                <Divider type="vertical" style={{ borderColor: '#fff' }} />
                <GTGSTooltip title="Delete" placement="top" color="black">
                  <GTGSIcon
                    name="trash3"
                    size={16}
                    className={
                      !requiredProperties.includes(node.data.props.name)
                        ? 'cursor-pointer'
                        : 'cursor-not-allowed'
                    }
                    fill="#fff"
                    onClick={() => {
                      if (!requiredProperties.includes(node.data.props.name)) {
                        showDeleteConfirm();
                      }
                    }}
                  />
                </GTGSTooltip>
              </div>,
              document.body
            )}
          </>
        ) : (
          // <GTGSTooltip title="Delete this area" placement="left" color="black">
          <div
            onMouseDown={showDeleteConfirm}
            style={{
              position: 'absolute',
              right: 0,
              top: 0,
              zIndex: 10,
              cursor: 'pointer',
              padding: 10,
              background: '#005CE6',
              borderRadius: '0 0 0 8px',
              width: 36,
              height: 36,
            }}
          >
            <DeleteOutlined style={{ color: '#fff' }} />
          </div>
          // </GTGSTooltip>
        )
      ) : null}
      {render}
    </div>
  );
};
