import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { getCrmJsonSchema } from '@/services/crmService';
import { E_CRM_OBJECT_TYPE } from '@/pages/CRM/store/constants';

export type PropertyType = {
  key: string;
  value: {
    maxLength?: number;
    minLength?: number;
    type: string;
    format?: string;
    items?: {
      format?: string;
      maxLength?: number;
      type: string;
      pattern?: string;
    };
    uniqueItems?: boolean;
    enum?: string[];
  };
};

const ignoreFormat = ['uuid']; // Define the formats to ignore

const useJsonSchema = () => {
  const { data, error, isLoading } = useQuery(
    ['crm-json-schema'],
    () => getCrmJsonSchema({ type: E_CRM_OBJECT_TYPE.CONTACTS }),
    { staleTime: 30 * 60 * 1000 } // cache for 30 minutes
  );

  const properties = useMemo(() => {
    if (!data?.jsonSchema?.properties) return [];

    const _properties: PropertyType[] = Object.entries(
      data.jsonSchema.properties
    ).map(([key, value]) => ({
      key,
      value: value as PropertyType['value'],
    }));

    return _properties.filter(
      (property) =>
        !ignoreFormat.includes(
          property.value.format || property.value.items?.format || ''
        )
    );
  }, [data]);

  const inputProperties = useMemo(
    () =>
      properties.filter(
        (property) =>
          !property.value.enum &&
          property.value.format !== 'date-time' &&
          property.value.items?.format !== 'date-time'
      ),
    [properties]
  );

  const optionProperties = useMemo(
    () =>
      properties.filter(
        (property) =>
          property.value.format !== 'date-time' &&
          property.value.items?.format !== 'date-time'
      ),
    [properties]
  );

  const dateProperties = useMemo(
    () =>
      properties.filter(
        (property) =>
          property?.value?.format === 'date-time' ||
          property?.value?.items?.format === 'date-time'
      ),
    [properties]
  );

  const arrayTypeProperties = useMemo(
    () => properties.filter((property) => property.value.type === 'array'),
    [properties]
  );

  const requiredProperties = useMemo(
    () =>
      data?.jsonSchema?.anyOf?.reduce((acc, schema) => {
        if (schema.required) {
          acc.push(...schema.required);
        }
        return acc;
      }, [] as string[]) || [],
    [data]
  );

  return {
    jsonSchema: data?.jsonSchema?.properties,
    properties,
    inputProperties,
    arrayTypeProperties,
    dateProperties,
    optionProperties,
    requiredProperties,
    error,
    isLoading,
  };
};

export default useJsonSchema;
