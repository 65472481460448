export const routesMenu = {
  DASHBOARD: '',
  BOOKMARK: 'bookmark',
  WORKSPACE: 'workspaces',
  CRM: 'crm',
  MARKETING: 'marketing',
  CONTENT: 'content',
  CONTENT_BUILDER: 'content-builder',
  REPORTING: 'reporting',
  AUTOMATIONS: 'automations',
  MESSAGING_ROOT: 'messaging',
  COMMERCE: 'commerce',
  PRICING: 'pricing',
  LIBRARY: 'library',
  METRIC: 'metric',
  SETTINGS: 'settings',
  PAYMENT_PLAN: 'payment-plan',
  EMAIL_BUILDER: 'email-builder',
};

export const routesPath = {
  DASHBOARD: `/${routesMenu.DASHBOARD}`,
  EMAIL: `/${routesMenu.CONTENT}/email`,
  BOOKMARK: `/${routesMenu.BOOKMARK}`,
  WORKSPACE: `/${routesMenu.WORKSPACE}`,
  PAYMENT_PLAN_REVIEW: `/${routesMenu.PAYMENT_PLAN}/review`,
  CRM: `/${routesMenu.CRM}`,
  CONTACTS: `/${routesMenu.CRM}/contacts`,
  CONTACTS_EDIT: `/${routesMenu.CRM}/contacts/edit`,
  CONTACTS_IMPORT: `/${routesMenu.CRM}/contacts/import`,
  CONTACTS_FORM: `/${routesMenu.CRM}/contacts/form`,
  COMPANIES: `/${routesMenu.CRM}/companies`,
  COMPANIES_EDIT: `/${routesMenu.CRM}/companies/edit`,
  COMPANIES_FORM: `/${routesMenu.CRM}/companies/form`,
  TASKS: `/${routesMenu.CRM}/tasks`,
  DEALS: `/${routesMenu.CRM}/deals`,
  CONTENT_BUILDER: `/${routesMenu.CONTENT_BUILDER}`,
  CONTENT: `/${routesMenu.CONTENT}`,
  REPORTING: `/${routesMenu.REPORTING}`,
  AUTOMATIONS: `/${routesMenu.AUTOMATIONS}`,
  WEBSITE_PAGE: `/${routesMenu.CONTENT}/website-page`,
  BLOG: `/${routesMenu.CONTENT}/blog`,
  MESSAGING_ROOT: `/${routesMenu.MESSAGING_ROOT}`,
  MESSAGING: `/${routesMenu.MESSAGING_ROOT}/messaging`,
  COMMERCE: `/${routesMenu.COMMERCE}`,
  PRICING: `/${routesMenu.PRICING}`,
  LIBRARY: `/${routesMenu.LIBRARY}`,
  MESSAGING_TEMPLATES: `/${routesMenu.LIBRARY}/messaging-templates`,
  CHANNELS_INTEGRATION: `/${routesMenu.MESSAGING_ROOT}/channels`,
  TAG_AND_WORKSPACE: `/${routesMenu.LIBRARY}/tag-and-workspace`,
  STAFF: `/${routesMenu.LIBRARY}/staff`,
  METRIC: `/${routesMenu.METRIC}`,
  // Landing page
  LANDING_PAGES: `/${routesMenu.CONTENT}/landing-pages`,
  LANDING_PAGE_DETAIL: `/${routesMenu.CONTENT}/landing-pages/:id`,
  LANDING_PAGE_EDITOR: `/${routesMenu.CONTENT}/landing-pages/:id/editor`,
  LANDING_PAGE_PREVIEW: `/${routesMenu.CONTENT}/landing-pages/:id/preview`,
  LANDING_PAGE_SELECT_TEMPLATE: `/${routesMenu.CONTENT}/landing-pages/:id/select-template`,
  LANDING_PAGE_PREVIEW_TEMPLATE: `/${routesMenu.CONTENT}/landing-pages/:id/select-template/:templateCode`,

  // Email builder
  EMAIL_BUILDER: `/${routesMenu.EMAIL_BUILDER}`,
  EMAIL_BUILDER_AUTOMATED_TEMPLATES: `/${routesMenu.EMAIL_BUILDER}/automated-templates`,
  EMAIL_BUILDER_CREATE_TEMPLATE: `/${routesMenu.CONTENT}/${routesMenu.EMAIL_BUILDER}/create-template`,
  // Marketing
  MARKETING: `/${routesMenu.MARKETING}`,
  CAMPAIGNS: `/${routesMenu.MARKETING}/campaigns`,
  EMAIL_MARKETING: `/${routesMenu.MARKETING}/email-marketing`,
  SOCIAL: `/${routesMenu.MARKETING}/social`,
  ADS: `/${routesMenu.MARKETING}/ads`,
  FORMS: `/${routesMenu.MARKETING}/forms`,
  SETTINGS: `/${routesMenu.SETTINGS}`,
  ACCESS_LEVEL_CONTROL: `/${routesMenu.SETTINGS}/access`,
  EDIT_ACCESS_LEVEL: `/${routesMenu.SETTINGS}/access/:id/edit`,
  USER_MANAGEMENT: `/${routesMenu.SETTINGS}/user-management`,
  DEACTIVATE_WORKSPACE: `/${routesMenu.SETTINGS}/workspaces/deactivate`,
};
