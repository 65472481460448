import React from 'react';
import { useNode } from '@craftjs/core';
import { GTGSIcon } from '@/components/GTGS';

type TestimonialCosmeticProps = {
  productImage?: string;
  productName?: string;
  productPrice?: string;
  testimonialText?: string;
  customerName?: string;
};

const TestimonialCosmetic = ({
  productImage = '/cosmetic/vitamin.png',
  productName = 'Vitamin E Moisture Cream',
  productPrice = '$23.00 USD',
  testimonialText = 'I absolutely love the designs and styles that Ehya creates...They are so different and innovative. I have bought several times from the collections and what I love is that they really are timeless pieces.',
  customerName = 'Anje Keizer - Ehya Cosmetics Customer',
}: TestimonialCosmeticProps) => {
  const {
    connectors: { connect, drag },
  } = useNode();

  return (
    <div
      ref={(ref) => ref && connect(drag(ref))}
      className="w-full p-5 md:p-10 bg-white flex justify-center items-center"
    >
      <div className="flex flex-col md:flex-row justify-center items-stretch w-full md:w-[85%] bg-[#F9FBFE] px-5 py-5 md:px-20 md:py-10 rounded-lg shadow-md gap-5 md:gap-10">
        <div className="relative w-full md:w-1/3 h-full flex flex-col">
          <img
            src={productImage}
            alt="Product Image"
            className="rounded-lg w-full h-auto md:h-full"
          />
          <div className="absolute bottom-[-5%] right-[-5%] md:bottom-[-7%] md:right-[-10%] bg-white p-1 md:p-2 rounded-md shadow-lg w-28 md:w-36 h-16 md:h-20">
            <p className="ml-1 md:ml-2 text-xs md:text-sm font-semibold">
              {productName}
            </p>
            <p className="text-green-600 font-semibold -mt-1 md:-mt-2 ml-1 md:ml-2 text-xs md:text-sm">
              {productPrice}
            </p>
          </div>
        </div>

        <div className="w-full md:w-2/3 mt-4 md:mt-0 flex flex-col items-end relative h-full">
          <p className="text-[#183B56] text-[14px] md:text-[18px] leading-relaxed">
            "{testimonialText}"
          </p>
          <p className="font-bold text-[#183B56] text-[16px] md:text-[20px] mt-2 md:mt-4">
            {customerName}
          </p>
          <div className="text-green-600 text-[24px] md:text-[32px]">
            <GTGSIcon name="quote-left" size={80}></GTGSIcon>
          </div>
        </div>
      </div>
    </div>
  );
};

const TestimonialCosmeticSettings = () => {
  const {
    actions: { setProp },
    productImage,
    productName,
    productPrice,
    testimonialText,
    customerName,
  } = useNode((node) => ({
    productImage: node.data.props.productImage,
    productName: node.data.props.productName,
    productPrice: node.data.props.productPrice,
    testimonialText: node.data.props.testimonialText,
    customerName: node.data.props.customerName,
  }));

  return (
    <div>
      <div className="mb-5">
        <label className="block mb-2 font-medium text-gray-700">
          Product Image URL
        </label>
        <input
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          value={productImage}
          onChange={(e) =>
            setProp(
              (props: TestimonialCosmeticProps) =>
                (props.productImage = e.target.value)
            )
          }
        />
      </div>
      <div className="mb-5">
        <label className="block mb-2 font-medium text-gray-700">
          Product Name
        </label>
        <input
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          value={productName}
          onChange={(e) =>
            setProp(
              (props: TestimonialCosmeticProps) =>
                (props.productName = e.target.value)
            )
          }
        />
      </div>
      <div className="mb-5">
        <label className="block mb-2 font-medium text-gray-700">
          Product Price
        </label>
        <input
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          value={productPrice}
          onChange={(e) =>
            setProp(
              (props: TestimonialCosmeticProps) =>
                (props.productPrice = e.target.value)
            )
          }
        />
      </div>
      <div className="mb-5">
        <label className="block mb-2 font-medium text-gray-700">
          Testimonial Text
        </label>
        <textarea
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          value={testimonialText}
          onChange={(e) =>
            setProp(
              (props: TestimonialCosmeticProps) =>
                (props.testimonialText = e.target.value)
            )
          }
        />
      </div>
      <div className="mb-5">
        <label className="block mb-2 font-medium text-gray-700">
          Customer Name
        </label>
        <input
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          value={customerName}
          onChange={(e) =>
            setProp(
              (props: TestimonialCosmeticProps) =>
                (props.customerName = e.target.value)
            )
          }
        />
      </div>
    </div>
  );
};

TestimonialCosmetic.craft = {
  props: {
    productImage: '/cosmetic/vitamin.png',
    productName: 'Vitamin E Moisture Cream',
    productPrice: '$23.00 USD',
    testimonialText:
      'I absolutely love the designs and styles that Ehya creates...They are so different and innovative. I have bought several times from the collections and what I love is that they really are timeless pieces.',
    customerName: 'Anje Keizer - Ehya Cosmetics Customer',
  },
  related: {
    settings: TestimonialCosmeticSettings,
  },
  displayName: 'Testimonial Cosmetic',
};

export default TestimonialCosmetic;
