import { memo } from 'react';
import { Form, FormItem, useForm } from '../../Form';
import { ColorPicker } from '../../SettingControls/ColorPicker';
import { Flex, Select } from 'antd';
import { FontStyle } from '../../SettingControls/FontStyle';
import { FontSelect } from '../../SettingControls/FontSelect';
import { FontSizeSelect } from '../../SettingControls/FontSizeSelect';
import { LineHeightSelect } from '../../SettingControls/LineHeightSelect';

type ITextStyleProps = {
  name: string;
  label: string;
};
const TextStyle = memo((props: ITextStyleProps) => {
  const { name, label } = props;
  const font = `${name}Font`;
  const fontSize = `${name}FontSize`;
  const color = `${name}Color`;

  return (
    <FormItem label={label}>
      <Flex gap={8}>
        <FormItem noStyle name={font}>
          <FontSelect />
        </FormItem>
        <FormItem noStyle name={fontSize}>
          <FontSizeSelect />
        </FormItem>
        <FormItem noStyle name={color}>
          <ColorPicker />
        </FormItem>
      </Flex>
    </FormItem>
  );
});

export const TextStyleSettings = memo(() => {
  return (
    <>
      {/* Paragraph */}
      <TextStyle name="paragraph" label="Paragraph" />
      {/* Heading 1 */}
      <TextStyle name="heading1" label="Heading 1" />
      {/* Heading 2 */}
      <TextStyle name="heading2" label="Heading 2" />
      <FormItem name="lineHeight" label="Line height">
        <LineHeightSelect />
      </FormItem>
      <Flex>
        <FormItem
          className="flex-1"
          name="textLinkColor"
          label="Text link color"
        >
          <ColorPicker />
        </FormItem>
        <Flex className="flex-1">
          <FontStyle name="text" label="Style" />
        </Flex>
      </Flex>
    </>
  );
});
