import { ROLE_USER } from '@/constants/role-user';
import { AlertProps } from 'antd';
import { KeycloakTokenParsed } from 'keycloak-js';
import { ReactNode } from 'react';

export interface JobRoles {
  value: string;
  label: string;
}

interface ApprovalRoles {
  approvalRoleName: string;
  approvalRoleDesc: string;
  rank: string;
}
export interface BusinessFunctions {
  bfDesc: string;
  bfId: string;
  bfName: string;
  isRelatedPayment: boolean;
  privileges: string;
  bfCode: string;
  bfgCode: string;
  bfgName: string;
  approvalRoles?: ApprovalRoles[];
}

export interface RoleUserStoreState {
  canApproval?: boolean;
  canRequest?: boolean;
  soleAuthority?: boolean;
  businessFunctions?:
    | Record<ROLE_USER, BusinessFunctions | undefined>
    | undefined;
}

interface BICMessageProps
  extends Omit<AlertProps, 'message' | 'description' | 'type'> {
  title?: ReactNode;
  subText?: ReactNode;
  type?:
    | 'info'
    | 'warning'
    | 'success'
    | 'danger'
    | 'error'
    | 'tips-banner'
    | 'tips-banner-CTA'
    | undefined;
}

export type Bank = {
  accountId: string;
  bin: string;
  participantCode: string;
  logoUuid: string;
};

export interface MetaDataStoreState {
  jobRoles: JobRoles[];
  roleUser?: RoleUserStoreState;
  messageStore: BICMessageProps;
  userProfile?: KeycloakTokenParsed;

}


export enum IStep {
  setup = 'setup',
  initial = 'initial',
  workspaceName = 'workspace-name',
  userDetail = 'user-detail',
  addRole = 'add-role',
  addTemplate = 'add-template',
  setupIntegration = 'setup-integration'
}

export const StatusStep = {
[IStep.setup]: 0,
[IStep.initial]: 1,
[IStep.workspaceName]: 2,
[IStep.userDetail]: 3,
[IStep.addRole]: 4,
[IStep.addTemplate]: 5,
[IStep.setupIntegration]: 6
}


export enum IMenu {
updateProfile = 'update-profile',
password = 'password',
addPaymentMethod = 'add-payment-method',
}

export const StatusMenu = {
[IMenu.updateProfile]: "Personal information",
[IMenu.password]: "Password",
[IMenu.addPaymentMethod]: "Add a payment method",
}

export enum IMenuBilling {
  START = 'start',
  VIEW_OR_DOWNLOAD = 'view-or-download',
  MANAGE_SUBSCRIPTION_DETAIL = 'manage-subscription-detail',
  ADD_BILLING = 'add-billing',
  }
  
  export const StatusMenuBilling = {
  [IMenuBilling.START]: "",
  [IMenuBilling.VIEW_OR_DOWNLOAD]: "View or download invoices",
  [IMenuBilling.MANAGE_SUBSCRIPTION_DETAIL]: "Manage subscription detail",
  [IMenuBilling.ADD_BILLING]: "Add a billing contact",
  }

export interface MetaDataStoreActions {
  updateJobRoles: (newInfo: Partial<MetaDataStoreState['jobRoles']>) => void;
  updateRoleUser: (newInfo: Partial<MetaDataStoreState['roleUser']>) => void;
  updateMessageStore: (
    newInfo: Partial<MetaDataStoreState['messageStore']>
  ) => void;
  updateUser: (userProfile: Partial<MetaDataStoreState['userProfile']>) => void;
}
