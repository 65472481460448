import {
  GTGSButton,
  GTGSIcon,
  GTGSTabs,
  GTGSTabsProps,
} from '@/components/GTGS';
import { DownOutlined } from '@ant-design/icons';
import { Divider, Dropdown, Space } from 'antd';
import { MenuProps } from 'antd/lib';
import { memo } from 'react';

import styled from '@emotion/styled';
import { useHistory } from '@/pages/EmailBuilder/components/Editor/hooks/useHistory';

const TabsStyle = styled((props: GTGSTabsProps) => {
  return <GTGSTabs {...props} />;
})`
  .ant-tabs-tab {
    justify-content: center;
    align-items: center;
    margin: 0;
    height: 62px;
  }
  .ant-tabs-nav {
    margin: 0;
  }
  .ant-tabs-content-holder {
    display: none;
  }
  .ant-tabs-tab {
    min-width: 150px;
  }
`;

const Tabs = memo(() => {
  const items: GTGSTabsProps['items'] = [
    {
      key: '1',
      label: 'Edit',
    },
    {
      key: '2',
      label: 'Settings',
    },
    {
      key: '3',
      label: 'Sending',
    },
  ];
  return (
    <TabsStyle centered className="h-full" defaultActiveKey="1" items={items} />
  );
});

const ButtonUndo = memo(() => {
  const { undo, canUndo } = useHistory();
  return (
    <GTGSButton
      onClick={() => undo()}
      disabled={!canUndo}
      className="rounded-tr-none rounded-br-none"
      type="primary"
    >
      <GTGSIcon fill={canUndo ? 'white' : '#929BB2'} name="undo" size={14} />
    </GTGSButton>
  );
});

const ButtonRedo = memo(() => {
  const { redo, canRedo } = useHistory();
  return (
    <GTGSButton
      disabled={!canRedo}
      className="rounded-tl-none rounded-bl-none"
      type="primary"
      onClick={() => redo()}
    >
      <GTGSIcon fill={canRedo ? 'white' : '#929BB2'} name="redo" size={14} />
    </GTGSButton>
  );
});

const RightAction = memo(() => {
  const items: MenuProps['items'] = [
    {
      label: '1st menu item',
      key: '1',
    },
    {
      label: '2nd menu item',
      key: '2',
    },
    {
      label: '3rd menu item',
      key: '3',
      danger: true,
    },
    {
      label: '4rd menu item',
      key: '4',
      danger: true,
      disabled: true,
    },
  ];
  return (
    <div className="flex flex-row items-center gap-2">
      <div className="flex items-center">
        <ButtonUndo />
        <ButtonRedo />
      </div>
      <div className="flex-1 flex">
        <Dropdown
          menu={{
            items,
          }}
        >
          <GTGSButton>
            <Space>
              ACTIONS
              <DownOutlined />
            </Space>
          </GTGSButton>
        </Dropdown>
      </div>
    </div>
  );
});

export const TabsAction = memo(() => {
  return (
    <div className="flex flex-col">
      <div className="flex flex-row px-4">
        <div className="flex-1"></div>
        <div className="flex-1">
          <Tabs />
        </div>
        <div className="flex flex-1 flex-row justify-end items-center">
          <RightAction />
        </div>
      </div>
      <Divider className="!m-0" />
    </div>
  );
});
