import Show from '@/components/GTGS/GTGSShow/GTGSShow';
import { Segmented } from 'antd';
import { memo, useCallback, useState } from 'react';
import { ControlList } from './ControlList';
import { TemplateSettings } from './TemplateSetting';
import { useSelected } from '../hooks/useSelected';
import { GTGSButton, GTGSIcon } from '@/components/GTGS';

type ITab = 'Add' | 'Design';

const Tabs = (props: { value: ITab; onChange: (value: ITab) => void }) => {
  const { onChange, value } = props;
  return (
    <Segmented
      options={['Add', 'Design']}
      onChange={(value) => {
        onChange(value as ITab);
      }}
      value={value}
      block
    />
  );
};

const LayoutToolBox = memo(() => {
  const [currentTab, setTabs] = useState<ITab>('Add');
  return (
    <div className="w-full p-12 pt-6 gap-4 flex flex-col">
      <div className="tabs">
        <Tabs value={currentTab} onChange={(v) => setTabs(v)} />
      </div>
      <Show>
        <Show.When isTrue={currentTab === 'Add'}>
          <ControlList />
        </Show.When>
        <Show.When isTrue={currentTab === 'Design'}>
          <TemplateSettings />
        </Show.When>
      </Show>
    </div>
  );
});

const SelectedSettings = memo((props: { onDone: () => void }) => {
  const { onDone } = props;
  const { settings: Settings } = useSelected();
  return (
    <div className="w-full p-12 pt-6 gap-4 flex flex-col">
      <div className="">
        <GTGSButton
          icon={
            <GTGSIcon
              name="chevron-left"
              size={14}
              style={{ color: '#005CE6' }}
            />
          }
          iconPosition="start"
          onClick={() => onDone()}
          type="link"
        >
          Done
        </GTGSButton>
      </div>
      <div>{Boolean(Settings) && <Settings />}</div>
    </div>
  );
});

export const Toolbox = memo(() => {
  const [mode, setMode] = useState<'layout' | 'selected'>('layout');

  const onChange = useCallback((id) => {
    if (id) {
      setMode('selected');
    }
  }, []);

  const _backToLayoutSetting = useCallback(() => {
    setMode('layout');
  }, []);
  useSelected({
    onChange,
  });
  return (
    <Show>
      <Show.When isTrue={mode === 'selected'}>
        <SelectedSettings onDone={_backToLayoutSetting} />
      </Show.When>
      <Show.When isTrue={mode === 'layout'}>
        <LayoutToolBox />
      </Show.When>
    </Show>
  );
});
