import { Button as AntdButton, ButtonProps } from 'antd';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { memo } from 'react';
interface AntdButtonProps extends ButtonProps {
  timeDelayClicked?: number; // move from BICButton - Will'be apply this logic
  iconEnd?: any;
}

const _GTGSButton = ({ children, className, ...rest }: AntdButtonProps) => {
  return (
    <AntdButton {...rest} className={classNames(styles.button, className)}>
      {children}
      {rest?.iconEnd}
    </AntdButton>
  );
};


const GTGSButton = memo(_GTGSButton);

export { GTGSButton, type AntdButtonProps };
