import { Button, Dropdown, Space } from 'antd';
import { DropdownProps } from 'antd/lib';
import { memo, useCallback, useState } from 'react';
import { GTGSIcon } from '../GTGSIcon/GTGSIcon';
import { GTGSTypography } from '../GTGSTypography/GTGSTypography';
import { SearchBox } from './SearchBox';
import { DropdownRender } from './DropdownRender';

export type DropdownFilterOption = {
  label: string;
  subLabel?: string;
  value: string;
};

interface DropdownFilterProps extends DropdownProps {
  name: string;
  options: DropdownFilterOption[] | [];
  placeHolder?: string;
  onSelect?: (name: string, value: string[], keyword: string) => void;
  singleValue?: boolean;
  isSearch?: boolean;
  renderLabel?: (option: DropdownFilterOption) => React.ReactNode;
}

const _GTGSDropdownFilter = ({
  name,
  children,
  className,
  placeHolder = 'Select',
  options,
  onSelect,
  singleValue = false,
  isSearch = true,
  renderLabel,
  ...rest
}: DropdownFilterProps) => {
  const [length, setLength] = useState<null | number>(null);

  const handleSelect = useCallback(
    (value: string[], keyword: string) => {
      setLength(value.length);
      onSelect?.(name, value, keyword);
    },
    [onSelect, name]
  );

  return (
    <Dropdown
      trigger={['click']}
      placement={isSearch ? 'bottomLeft' : 'bottom'}
      arrow={{ pointAtCenter: true }}
      dropdownRender={(menu) =>
        isSearch ? (
          <SearchBox
            options={options}
            onSelect={handleSelect}
            singleValue={singleValue}
            renderLabel={renderLabel}
          />
        ) : (
          <DropdownRender
            options={options}
            onSelect={handleSelect}
            singleValue={singleValue}
          />
        )
      }
    >
      <Button className="border-[#DCDEE4]">
        <Space>
          <GTGSTypography.Text size="sm" className="font-normal">
            {length ? `(${length}) ${placeHolder}` : placeHolder}
          </GTGSTypography.Text>
          <GTGSIcon name="chevron-down" size={12} />
        </Space>
      </Button>
    </Dropdown>
  );
};

const GTGSDropdownFilter = memo(_GTGSDropdownFilter);

export { GTGSDropdownFilter, type DropdownFilterProps };
