import { useNode } from '@craftjs/core';
import React from 'react';
// import InfoForm from './InfoForm';

type HeroSectionProps = {
  title: string;
  subTitle: string;
  description: string;
};

function HeroSection({ title, subTitle, description }: HeroSectionProps) {
  const {
    connectors: { connect, drag },
  } = useNode();
  return (
    <>
      <div className="bg-transparent grid grid-cols-1 md:grid-cols-2 gap-0 items-center justify-end">
        <div
          className="md:h-[430px] max-w-[590px] text-[#0F172A]flex justify-start"
          ref={connect as unknown as React.RefObject<HTMLDivElement>}
        >
          <h1 className="font-light leading-tight mb-2">
            <span className="block text-[20px] xl:text-[44px]">{subTitle}</span>
            <span className="block text-[30px] xl:text-[64px] font-bold tracking-tight">
              {title}
            </span>
          </h1>
          <p className="w-full font-normal md:text-[13px] xl:text-[18px] break-words">
            {description}
          </p>
        </div>

        <div className=" w-[90%] justify-center md:w-full flex md:justify-end ">
          {/* <InfoForm /> */}
        </div>
      </div>
    </>
  );
}

HeroSection.defaultProps = {
  title: 'With GTG CRM',
  subTitle: 'Unleash Your Potential',
  description:
    'Effortlessly connect your data, teams, and customers on a single AI-driven customer platform that scales with your business.',
};

const HeroSectionSettings = () => {
  const {
    actions: { setProp },
    title,
    subTitle,
    description,
  } = useNode((node) => ({
    title: node.data.props.title,
    subTitle: node.data.props.subTitle,
    description: node.data.props.description,
  }));
  return (
    <div>
      <div className="mb-5">
        <label className="block mb-2 font-medium text-gray-700">Title</label>
        <input
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          defaultValue={title}
          onChange={(e) => {
            setProp(
              (props: { title: string }) => (props.title = e.target.value),
              1000
            );
          }}
        />
      </div>
      <div className="mb-5">
        <label className="block mb-2 font-medium text-gray-700">
          Sub Title
        </label>
        <input
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          defaultValue={subTitle}
          onChange={(e) => {
            setProp(
              (props: { subTitle: string }) =>
                (props.subTitle = e.target.value),
              1000
            );
          }}
        />
      </div>
      <div className="mb-5">
        <label className="block mb-2 font-medium text-gray-700">
          Description
        </label>
        <textarea
          rows={8}
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          defaultValue={description}
          onChange={(e) => {
            setProp(
              (props: { description: string }) =>
                (props.description = e.target.value),
              1000
            );
          }}
        />
      </div>
    </div>
  );
};

HeroSection.craft = {
  props: HeroSection.defaultProps,
  related: {
    settings: HeroSectionSettings,
  },
};

export default HeroSection;
