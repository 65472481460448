import { createRoute } from '@/helpers';
import { lazy } from 'react';
import { routesPath } from './constants';

const CRMContactPage = lazy(() => import('@/pages/CRM/Contacts'));

const ImportContact = lazy(() => import('@/pages/CRM/Contacts/ImportContact'));

const EditContact = lazy(() => import('@/pages/CRM/Contacts/Edit'));

const ContactFormBuilder = lazy(() => import('@/pages/CRM/Contacts/FormBuilder'));

const CompanyFormBuilder = lazy(() => import('@/pages/CRM/Companies/FormBuilder'));

const CompaniesPage = lazy(() => import('@/pages/CRM/Companies'));

const EditCompany = lazy(() => import('@/pages/CRM/Companies/Edit'));

const DealsPage = lazy(() => import('@/pages/CRM/Deals'));

const TasksPage = lazy(() => import('@/pages/CRM/Tasks'));

// --------------------------------------------------------------------------- ROUTER
const RouterCRM = {
  path: routesPath.CRM,
  children: [
    createRoute(routesPath.CONTACTS, <CRMContactPage />),
    createRoute(routesPath.CONTACTS_IMPORT, <ImportContact />),
    createRoute(routesPath.CONTACTS_EDIT, <EditContact />),
    createRoute(routesPath.COMPANIES, <CompaniesPage />),
    createRoute(routesPath.COMPANIES_EDIT, <EditCompany />),
    createRoute(routesPath.DEALS, <DealsPage />),
    createRoute(routesPath.TASKS, <TasksPage />),

  ],
};

export const RouterCRMWithoutLayout = createRoute(routesPath.CONTACTS_FORM, <ContactFormBuilder />);

export const RouterCRMWithoutLayoutCompany = createRoute(routesPath.COMPANIES_FORM, <CompanyFormBuilder />); 

export default RouterCRM;
