import { createRoute } from '@/helpers';
import React from 'react';
import { routesPath } from './constants';

const AutomationsPage = React.lazy(() => import('@/pages/Automations'));

// --------------------------------------------------------------------------- ROUTER
const RouterAutomations = {
  path: routesPath.AUTOMATIONS,
  children: [
    createRoute(routesPath.AUTOMATIONS, <AutomationsPage />),
  ],
};

export default RouterAutomations;
