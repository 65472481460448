import { useEditor } from '@craftjs/core';
import { useEffect } from 'react';

type ISelectedNode = {
  id: string;
  name: string;
  settings: any;
  isDeletable: boolean;
};

type IUseSelectedProps = {
  onChange?: (selectedId?: string) => void;
};

export const useSelected = (props?: IUseSelectedProps) => {
  const { onChange } = props || {};
  const { selected, store } = useEditor((state, query) => {
    const currentNodeId = query.getEvent('selected').last();
    let selected: ISelectedNode | null = null;
    if (currentNodeId) {
      selected = {
        id: currentNodeId,
        name: state.nodes[currentNodeId].data.name,
        settings:
          state.nodes[currentNodeId].related &&
          state.nodes[currentNodeId].related.settings,
        isDeletable: query.node(currentNodeId).isDeletable(),
      };
    }
    return {
      selected,
      isEnabled: state.options.enabled,
    };
  });

  useEffect(() => {
    const unSubscribe = store.subscribe(
      (s) => {
        return s.events.selected;
      },
      (selected) => {
        const selectedId = selected.values().next().value;
        onChange?.(selectedId);
      }
    );
    return () => {
      unSubscribe();
    };
  }, [onChange, store]);

  return {
    selected,
    settings: selected?.settings,
  };
};
