import httpService from './httpService';
import config from '../config';
import { customResponsePagination } from '@/helpers';
import UserService from './UserService';
import axios from 'axios';

const baseUrl = `${config.gatewayUrl}/v1/assets`;

export const getAssets = async (params) => {
  const { data } = await httpService.get(`${baseUrl}`, { params: params } );

  return ({
    data: customResponsePagination({
      content: data.data?.files || [],
      totalElementsCount: Number(data.data?.total || 0),
      currentPage: params?.page || 1,
      pageSize: params?.pageSize || 10,
    }),
    success: data.success,
  });
};

export const startUploadFile = async (body) => {
  const { data } = await httpService.post(`${baseUrl}/start-upload`, body);
  return data;
};

export const completeUploadFile = async (body) => {
  const { data } = await httpService.post(`${baseUrl}/complete-upload`, body);
  return data;
};

export const getDetailsAsset = async (assetId) => {
  const { data } = await httpService.get(`${baseUrl}/${assetId}`);
  return data;
};

export const startProfilePictureUploadFile = async (body) => {
  const { data } = await httpService.post(`${baseUrl}/profile-picture/start-upload`, body);
  return data;
};

export const completeProfilePictureUploadFile = async (body) => {
  const { data } = await httpService.post(`${baseUrl}/profile-picture/complete-upload`, body);
  return data;
};

export const getProfilePictureDetailsAsset = async (assetId) => {
  const { data } = await httpService.get(`${baseUrl}/profile-picture/${assetId}`);
  return data;
};

export const connectFileUploadAWS = async (url, fileSize, file) => {
  const token = UserService.getToken();
  const headers = {
    'Content-Length': fileSize,
  };
  const response = await fetch(url, {
    method: 'PUT',
    body: file,
    headers: headers
  });

  if (!response.ok) {
    throw new Error(`Failed to upload file: ${response.statusText}`);
  }

  return response;
};

const assetsManagementService = {
  startUploadFile,
  completeUploadFile,
  getAssets,
  getDetailsAsset,
  startProfilePictureUploadFile,
  completeProfilePictureUploadFile,
  getProfilePictureDetailsAsset,
  connectFileUploadAWS
};

export default assetsManagementService;
