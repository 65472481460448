import { CalendarOutlined, MoreOutlined } from '@ant-design/icons';
import { Flex, Space } from 'antd';

import React, { forwardRef } from 'react';

const FormSettingItem = forwardRef<
  HTMLDivElement,
  { icon?: React.ReactNode; children: React.ReactNode }
>(({ icon = <CalendarOutlined />, children }, ref) => {
  return (
    <div ref={ref} className="py-1 px-2 bg-[#F6F8FA] rounded-md cursor-pointer">
      <Flex gap={4} align="center">
        <MoreOutlined />
        <Space size={8}>
          {icon} {children}
        </Space>
      </Flex>
    </div>
  );
});

export default FormSettingItem;
