import {
  AntdButtonProps,
  GTGSButton,
  GTGSCard,
  GTGSInput,
  GTGSTypography
} from '@/components/GTGS';
import { GTGSInputPhoneNumber } from '@/components/GTGS/GTGSInputPhoneNumber/GTGSInputPhoneNumber';
import UploadAvatarArea from '@/components/UploadAvatarArea';
import { Col, Flex, Form, Row } from 'antd';
import { ReactNode, useEffect, useState } from 'react';
import BackForm from '../BackForm';
import { IWorkspace } from '../../types';

interface UserDisplayDetailsFormProps {
  onClickNext?: (values: any) => void;
  workspace?: IWorkspace;
  header?: {
    title: string;
    subTitle: string;
  };
  onBack?: () => void;
  buttonText?: string;
  onCancel?: (e:any) => void;
}

const UserDisplayDetailsForm = ({
  onClickNext,
  workspace,
  header,
  onBack,
  buttonText,
  onCancel
}: UserDisplayDetailsFormProps) => {
  const [form] = Form.useForm();
  const displayName = Form.useWatch('displayName', form);
  const avatarBase64 = Form.useWatch('avatarBase64', form);
  const position = Form.useWatch('position', form);
  useEffect(() => {
    if (workspace) {
      form.setFieldsValue({
        displayName: workspace?.ownerInfo?.displayName || '',
        position: workspace?.ownerInfo?.position || null,
        phone: workspace?.ownerInfo?.phone || '',
        avatarBase64: workspace?.ownerInfo?.avatarBase64 || null,
      });
    }
  }, [form, workspace]);

  const handleClickNext = (values: any) => {
    console.log('handleClickNext :>> ');
    onClickNext && onClickNext(values);
  };

  const onChangeImage = (url: string | undefined) => {
    form.setFieldValue("avatarBase64",  url );
  };

  const onValuesChange = (changedValues) => {
    if(changedValues.avatarBase64) {
      onChangeImage(changedValues.avatarBase64);
    }
  };

  return (
    <Flex align="center" justify="center" className="w-full" vertical gap={24}>
      <GTGSCard
        className="w-[540px] h-auto !rounded-[16px]"
        headStyle={{ padding: '20px' }}
        title={
          <Flex
            align="flex-start"
            justify="center"
            vertical
            gap={20}
            className="w-full"
          >
            {onBack && <BackForm onBack={onBack} />}
            <Flex
              align="flex-start"
              justify="center"
              vertical
              gap={4}
              className="w-full"
            >
              <GTGSTypography.Text
                strong
                className="!text-[#111827] font-bold !text-[18px]"
              >
                {header?.title || 'User display details'}
              </GTGSTypography.Text>
              <GTGSTypography.Text
                className="!text-[#4B5563] !text-[14px]"
                style={{ whiteSpace: 'pre-line' }}
              >
                {header?.subTitle ||
                  'This information is exclusively visible within the workspace, ensuring privacy and security for all internal users.'}
              </GTGSTypography.Text>
            </Flex>
          </Flex>
        }
      >
        <Flex
          align="flex-start"
          justify="center"
          className="w-full"
          gap={20}
          vertical
        >
          <Form
            form={form}
            layout="vertical"
            className="w-full"
            onFinish={handleClickNext}
            requiredMark="optional"
            onValuesChange={onValuesChange}
            initialValues={{
              displayName: workspace?.ownerInfo?.displayName || '',
              position: workspace?.ownerInfo?.position || null,
              phone: workspace?.ownerInfo?.phone || '',
              avatarBase64: workspace?.ownerInfo?.avatarBase64 || null,
            }}
          >
            <Row gutter={[16, 0]}>
              <Col span={24}>
                <Form.Item name="avatarBase64" rules={[
                  {
                    required: true,
                    message: '',
                  },
                ]}>
                  <UploadAvatarArea onChange={onChangeImage} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label={
                    <GTGSTypography.Text
                      strong
                      className="!text-[#000000] font-bold !text-[16px] !text-center"
                    >
                      Display Name
                    </GTGSTypography.Text>
                  }
                  rules={[
                    {
                      required: true,
                      message: 'Please input your display name!',
                    },
                  ]}
                  name="displayName"
                >
                  <GTGSInput className="w-full" size="large" />
                </Form.Item>
              </Col>
              <Col span={24} md={12}>
                <Form.Item
                  label={
                    <GTGSTypography.Text
                      strong
                      className="!text-[#000000] font-bold !text-[16px] !text-center"
                    >
                      Position
                    </GTGSTypography.Text>
                  }
                  rules={[
                    { required: true, message: 'Please input your position!' },
                  ]}
                  className="mb-0"
                  name="position"
                >
                  <GTGSInput className="w-full" size="large" />
                </Form.Item>
              </Col>
              <Col span={24} md={12}>
                <Form.Item
                  label={
                    <GTGSTypography.Text
                      strong
                      className="!text-[#000000] font-bold !text-[16px] !text-center"
                    >
                      Phone
                    </GTGSTypography.Text>
                  }
                  className="mb-0"
                  name="phone"
                >
                  <GTGSInputPhoneNumber className="w-full" size="large" />
                </Form.Item>
              </Col>
              <Col span={24} md={24}>
                <Flex align="center" justify="center" className={`w-full ${onCancel ? " mt-[40px]" : " mt-5" }`} gap={8}>
                  {onCancel && 
                  <GTGSButton
                    className="!uppercase w-[45%]"
                    htmlType="submit"
                    onClick={onCancel}
                  >
                    {"Cancel"}
                  </GTGSButton>}
                  <GTGSButton
                    type="primary"
                    className={`!uppercase ${onCancel ? "w-[45%]": " w-[372px]" }`}
                    htmlType="submit"
                    disabled={!displayName || !avatarBase64 || !position}
                  >
                    {buttonText  || "Next"}
                  </GTGSButton>
                </Flex>
              </Col>
            </Row>
          </Form>
        </Flex>
      </GTGSCard>
    </Flex>
  );
};

export default UserDisplayDetailsForm;
