import { useNode } from '@craftjs/core';
import {
  Html,
  Row,
  Container as EmailContainer,
  Section,
} from '@react-email/components';
import { CSSProperties, Fragment, PropsWithChildren } from 'react';
import { Layout } from '../Layout';
type IContainerProps = {
  colNumber?: number;
  topSpacing?: string;
  bottomSpacing?: string;
  width?: 'full-width' | 'content-width';
  background?: string;
  backgroundPattern?: string;
  borderCollapse?: CSSProperties['borderCollapse'];
};

export const Container = (props: PropsWithChildren<IContainerProps>) => {
  const { children, width, background, borderCollapse } = props;

  const {
    connectors: { connect, drag },
  } = useNode();

  const Wrap = width === 'content-width' ? EmailContainer : Fragment;

  return (
    <Wrap>
      <Section
        ref={(ref) => ref && connect(ref!)}
        style={{
          background,
          borderCollapse,
        }}
      >
        <Row
          style={{
            borderCollapse,
          }}
        >
          {children}
        </Row>
      </Section>
    </Wrap>
  );
};
export const ContainerSettings = () => {};

export const ContainerDefaultProps: IContainerProps = {
  borderCollapse: 'collapse',
};

Container.craft = {
  props: ContainerDefaultProps,
  related: {
    settings: ContainerSettings,
  },
};
