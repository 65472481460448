import { useEditor, useNode } from '@craftjs/core';
import { Column as EmailCol } from '@react-email/components';
import { PropsWithChildren } from 'react';
import { DropContent } from '../DropContent';
import { Container } from '../Container';
import { CSSProperties } from 'styled-components';

type IColProps = {
  defaultBorderStyle?: string;
  borderWidth?: number;
  valign?: string;
  align?: string;
  paddingTop?: string;
  paddingBottom?: string;
  paddingLeft?: string;
  paddingRight?: string;
  borderCollapse?: CSSProperties['borderCollapse'];
};

export const Col = (props: PropsWithChildren<IColProps>) => {
  const {
    children,
    defaultBorderStyle,
    borderWidth,
    paddingLeft,
    paddingRight,
    paddingTop,
    paddingBottom,
    align,
    valign,
    borderCollapse,
  } = props;
  const node = useNode((node) => {
    return {
      hovered: node.events.dragged,
      parent: node.data.parent,
    };
  });
  const {
    connectors: { select, hover },
  } = useEditor((state) => {
    return {
      indicator: state.indicator,
    };
  });
  const {
    parent,
    connectors: { connect },
  } = node;

  return (
    <EmailCol
      ref={(ref) => {
        if (ref) {
          connect(ref);
          if (parent) {
            select(ref, parent);
            hover(ref, parent);
          }
        }
      }}
      style={{
        borderStyle: children ? 'none' : defaultBorderStyle,
        borderWidth,
        paddingTop,
        paddingBottom,
        paddingLeft,
        paddingRight,
        borderCollapse,
      }}
      valign={valign as any}
      align={align as any}
    >
      {children ? children : <DropContent />}
    </EmailCol>
  );
};

export const ColDefaultProps: IColProps = {
  defaultBorderStyle: 'dashed',
  borderWidth: 1,
  valign: 'middle',
  align: 'center',
  borderCollapse: 'collapse',
};

Col.craft = {
  props: ColDefaultProps,
  rules: {
    canDrag: () => false,
  },
};
