import styled from '@emotion/styled';
import { Input as AntdInput } from 'antd';
import { useTranslation } from 'react-i18next';
import { memo } from 'react';
import { GTGSTypography } from '../GTGSTypography/GTGSTypography';
import { TextAreaProps } from 'antd/lib/input';

interface GTGSTextAreaProps extends TextAreaProps {}

const TextAreaStyled = styled(AntdInput.TextArea)<GTGSTextAreaProps>`
  .ant-input-data-count {
    top: -26px;
  }
`;

const _GTGSTextArea = ({ showCount = true, maxLength = 200,  ...rest }) => {
  const { t } = useTranslation();
  const _showCount = showCount
    ? {
        formatter: (_formatter) => (
          <GTGSTypography.Text size="sm" status="secondary">
            {t('common.maxCharacters', {
              number: `${_formatter.count}/${_formatter.maxLength}`,
            })}
          </GTGSTypography.Text>
        ),
      }
    : false;

  return <TextAreaStyled showCount={_showCount} maxLength={maxLength}  {...rest} />;
};

const GTGSTextArea = memo(_GTGSTextArea);

export { GTGSTextArea, type GTGSTextAreaProps };
