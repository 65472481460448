import { GTGSButton, GTGSIcon, GTGSTypography } from '@/components/GTGS';
import { Dropdown, DropDownProps, Flex } from 'antd';
import { memo } from 'react';
import styles from './TableTitle.module.scss';
import classNames from 'classnames';

export interface TableTitleProps  {
  url?: string;
  title?: string;
  className?: string;
  action?: DropDownProps;
  isExtra?: boolean;
  extra?: React.ReactNode;
}

const _TableTitle = ({ className, title, action, isExtra,extra }: TableTitleProps) => {
   
  return (
    <Flex align="center" justify="space-between" gap={4} className={classNames(styles.container, className) }>
        <GTGSTypography.Title level={5} >{title}</GTGSTypography.Title>
        {extra}
        {isExtra && !extra && <Dropdown
            {...action}
        >
            <GTGSButton shape="circle" size="small" className={styles.button} icon={<GTGSIcon name="dot-three-vertical" size={18} />} />
        </Dropdown>}
    </Flex>
  );
};


const TableTitle = memo(_TableTitle);

export { TableTitle };

