import { useEditor } from '@craftjs/core';
import React from 'react';
const ROOT = 'ROOT'; // Define the ROOT constant

const useEditorState = () => {
  const { selected, query, nodes, actions, activeTopBannerNode } = useEditor(
    (state, query) => {
      const currentNodeId = query.getEvent('selected').last();

      let selected;

      if (currentNodeId) {
        selected = {
          id: currentNodeId,
          name: state.nodes[currentNodeId].data.name,
          settings:
            state.nodes[currentNodeId].related &&
            state.nodes[currentNodeId].related.settings,
          isDeletable: query.node(currentNodeId).isDeletable(),
          data: state.nodes[currentNodeId].data,
        };
      }

      const nodeList = state.nodes[ROOT]?.data?.nodes;

      const nodes = Object.keys(state.nodes)
        .filter((nodeId) => nodeList.includes(nodeId))
        .map((nodeId) => ({
          id: nodeId,
          name: state.nodes[nodeId].data.name,
          displayName: state.nodes[nodeId].data.displayName,
          data: state.nodes[nodeId].data,
          settings:
            state.nodes[nodeId].related && state.nodes[nodeId].related.settings,
        }))
        .sort((a, b) => nodeList.indexOf(a.id) - nodeList.indexOf(b.id));

      const TopBannerNode = nodes.find((node) => node.name === 'TopBanner');
      const activeTopBannerNode = () => {
        if (TopBannerNode) {
          actions.selectNode(TopBannerNode.id);
        }
      };

      return {
        selected,
        nodes,
        isEnabled: state.options.enabled,
        activeTopBannerNode,
      };
    }
  );

  return { selected, query, nodes, actions, activeTopBannerNode };
};

export default useEditorState;
