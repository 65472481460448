import { Button, ButtonProps } from 'antd';
import { CSSProperties, memo } from 'react';
import { Form, FormItem } from '../../Form';

type IActiveButton = ButtonProps & {
  position?: 'start' | 'center' | 'end';
};

export const ActiveButton = memo((props: IActiveButton) => {
  const { position = 'center', onChange, value, ...rest } = props;
  console.log('🚀 ~ ActiveButton ~ value:', value);

  let borderStyle: CSSProperties = {};
  if (position === 'start') {
    borderStyle = {
      borderRight: 0,
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
    };
  }
  if (position === 'end') {
    borderStyle = {
      borderLeft: 0,
      borderTopRightRadius: 8,
      borderBottomRightRadius: 8,
    };
  }

  return (
    <Button
      style={{
        borderRadius: 0,
        borderColor: '#DCDEE4',
        background: value ? '#929BB2' : 'white',
        ...borderStyle,
      }}
      onClick={() => {
        (onChange as any)?.(!value);
      }}
      {...rest}
    ></Button>
  );
});
