import styled from '@emotion/styled';
import { Avatar as AntdAvatar, AvatarProps } from 'antd';
import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import styles from './GTGSAvatar.module.scss';
import { generateColorFromText } from '@/helpers/generateColorFromText';
import { isValidUrl } from '@/utils/checkUrl';

interface GTGSAvatarProps extends Omit<AvatarProps, 'size'> {
  type?: 'online' | 'offline' | 'default' | 'withLine';
  textName?: string;
  variant?:
    | 'image'
    | 'initial-subtle'
    | 'initial-filled'
    | 'user-subtle'
    | 'user-filled';
  size?:
    | 16
    | 24
    | 32
    | 40
    | 48
    | 64
    | 96
    | number
    ;
}

const GTGSAvatarStyle = styled(AntdAvatar)<GTGSAvatarProps>``;
const START_WITH_ABC123 = /^[a-zA-Z0-9]/;

const GTGSAvatar = ({
  gap = 4,
  type = 'default',
  variant = 'initial-subtle',
  size = 40,
  textName,
  src,
  ...rest
}: GTGSAvatarProps) => {

  const variantAvatar = src && isValidUrl(src as string || '') ? 'image' : variant;

  const nameInitials = useMemo(() => {
    if (!textName) return undefined;
    return textName // "Long Do 123,"
      .split(" ") // ["Long", "Do", "123", ","]
      .filter((t) => t.match(START_WITH_ABC123)) // ["Long", "Do", "123"]
      .map((t) => t[0].toUpperCase()) // ["l", "L", "1"]
      .join("") // "LD1"
      .substring(0, 2); // "LD"
  }, [textName]);

  const fallbackBackgroundColor = useMemo(() => {
    if (!nameInitials) return undefined;
    return generateColorFromText(nameInitials);
  }, [nameInitials]);

  return (
    <div className={styles.container}>
      <GTGSAvatarStyle
        gap={gap}
        size={size}
        className={classNames(
          {
            [styles.avatar]: true,
            [styles.withLine]: type === 'withLine',
          },
          variantAvatar !== 'image' && {
            [styles.initialSubtle]: variant === 'initial-subtle',
            [styles.initialFilled]: variant === 'initial-filled',
            [styles.userSubtle]: variant === 'user-subtle',
            [styles.userFilled]: variant === 'user-filled',
          }
        )}
        src={src}
        style={{
          ...(src && isValidUrl(src as string || '') ? {} : { backgroundColor: fallbackBackgroundColor}),
          ...(size <= 32 ? {fontSize: 14} : {})
        }}
        {...rest}
      >
        {nameInitials}
      </GTGSAvatarStyle>
      {(type === 'online' || type === 'offline') && (
        <span
          style={{ width: (size / 5).toFixed(0), height:  (size / 5).toFixed(0) }}
          className={classNames(styles.status, {
            [styles.online]: type === 'online',
            [styles.offline]: type === 'offline',
          })}
        />
      )}
    </div>
  );
};

GTGSAvatar.Group = AntdAvatar.Group;

export { GTGSAvatar, type GTGSAvatarProps };
