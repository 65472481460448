import React, { useState } from 'react';
import ChatWindow from './ChatWindow';

const ChatButton: React.FC = () => {
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [isChatWindowOpen, setIsChatWindowOpen] = useState<boolean>(false);
  const [isInitialChatButtonClicked, setIsInitialChatButtonClicked] =
    useState<boolean>(false);

  const togglePopup = (): void => {
    setIsPopupOpen(!isPopupOpen);
  };

  const openChatWindow = (): void => {
    setIsChatWindowOpen(true);
    setIsPopupOpen(false);
    setIsInitialChatButtonClicked(true);
  };

  const closeChatWindow = (): void => {
    setIsChatWindowOpen(false);
  };

  return (
    <div className="fixed bottom-5 right-20 z-[100]">
      {isChatWindowOpen && <ChatWindow onClose={closeChatWindow} />}

      {isInitialChatButtonClicked ? (
        <button
          onClick={isChatWindowOpen ? closeChatWindow : openChatWindow}
          className="bg-blue-600 rounded-full p-3 w-14 h-14 flex items-center justify-center shadow-lg fixed bottom-5 right-20"
        >
          <img src="/chat-icon.svg" alt="Chat Icon" className="w-8 h-8" />
        </button>
      ) : (
        <>
          {isPopupOpen && (
            <div className="flex flex-col items-center bg-transparent rounded-lg space-y-4 w-full max-w-xl mb-[20%]">
              <div className="flex items-center w-full">
                <img
                  src="/chat-avatar.png"
                  alt="Avatar"
                  className="w-16 h-16 rounded-full bg-[#E1EDFB] object-cover mr-4"
                />
                <div className="w-[300px] flex-col">
                  <div className="bg-white text-gray-800 whitespace-pre-line break-words p-8 shadow-md w-full h-[156px] rounded-tl-[45px] rounded-tr-[45px] rounded-br-[45px]">
                    <h3 className="text-lg font-semibold text-[#170F49] mb-2">
                      Welcome to our website!
                    </h3>
                    <p className="text-sm text-[#6F6C90]">
                      Nice to meet you! If you have any questions about our
                      services, feel free to contact us.
                    </p>
                  </div>
                  <button
                    onClick={openChatWindow}
                    className="bg-blue-600 w-full h-11 mt-3 text-white font-semibold py-2 px-6 rounded-full hover:bg-blue-700"
                  >
                    Chat with us
                  </button>
                </div>
              </div>
            </div>
          )}

          <button
            onClick={togglePopup}
            className="bg-blue-600 rounded-full p-3 w-14 h-14 flex items-center justify-center shadow-lg fixed bottom-5 right-20"
          >
            <img src="/chat-icon.svg" alt="Chat Icon" className="w-8 h-8" />
          </button>
        </>
      )}
    </div>
  );
};

export default ChatButton;
