import { GTGSIcon } from '@/components/GTGS/GTGSIcon/GTGSIcon';
import { GTGSTypography } from '@/components/GTGS/GTGSTypography/GTGSTypography';
import { Flex } from 'antd';
import { FC, useMemo } from 'react';

interface IContentHeaderProps {
  onClose?: () => void;
  title?: string;
  subTitle?: string;
  isVertical?: boolean;
  align?: "center" | "flex-start";
  status?: 'success' | 'error';
  hasIcon?: boolean;
}

const ContentHeader: FC<IContentHeaderProps> = ({
  title = 'Payment successful!',
  subTitle,
  isVertical = true,
  align = "flex-start",
  status,
  hasIcon = true,
}: IContentHeaderProps) => {
  const renderTopTitle = useMemo(
    () => (
      <Flex
        gap={16}
        vertical={isVertical}
        align={align}
        justify={align}
      >
        {hasIcon && status === "success" && <GTGSIcon name="success-circle" size={40} />}
        {hasIcon && status === "error" && <GTGSIcon name="info-circle-red" size={40} />}
        <Flex
          gap={8}
          vertical
          align={align}
          justify={align}
          style={{ textAlign: align === "center" ? 'center' : 'left', width: "calc(100% - 32px)" }}
        >
          <GTGSTypography.Title
            level={4}
          >
            {title}
          </GTGSTypography.Title>
          <GTGSTypography.Text size="sm" status="secondary">
            {subTitle || ""}
          </GTGSTypography.Text>
        </Flex>
      </Flex>
    ),
    [align, hasIcon, isVertical, status, subTitle, title]
  );

  return (
    <Flex vertical gap={24}>
      {renderTopTitle}
    </Flex>
  );
};
export { ContentHeader, type IContentHeaderProps };

