import { memo } from 'react';

import { ColorPicker as AntdColorPicker, ColorPickerProps } from 'antd';

import styled from '@emotion/styled';

type IColorPickerProps = ColorPickerProps;

const ColorPickerStyled = styled(AntdColorPicker)`
  border-radius: 50% !important;
  padding: 0;
  .ant-color-picker-color-block {
    border-radius: 50% !important;
    height: 40px !important;
    width: 100% !important;
  }
  .ant-color-picker-clear {
    border-radius: 50% !important;
    height: 40px !important;
    width: 100% !important;
  }
  .ant-color-picker-color-block-inner {
    border-radius: 50% !important;
    width: 100%;
    height: 100%;
  }
`;

export const ColorPicker = memo((props: IColorPickerProps) => {
  return <ColorPickerStyled size="large" showText={false} {...props} />;
});
