import { Editor, Element, Frame, Resolver } from '@craftjs/core';
import React from 'react';

import { GTGSButton, GTGSTypography } from '@/components/GTGS';
import { saveState, updateLandingPage } from '@/services/pageBuilderService';
import {
  DesktopOutlined,
  MobileOutlined,
  TabletOutlined,
} from '@ant-design/icons';
import { ConfigProvider, Flex, message, Segmented } from 'antd';
import lz from 'lzutf8';
import { useMutation } from 'react-query';
import { useShallow } from 'zustand/react/shallow';

import Iframe from './components/Iframe';
import InitPageContent from './components/InitPageContent';
import InitPreviewTemplate from './components/InitPreviewTemplate';
import { usePageBuilder } from './store/usePageBuilderStore';
import CTA from './themes/theme-1/components/CTA';
import FAQ from './themes/theme-1/components/FAQ';
import Pricing from './themes/theme-1/components/Pricing';
import FeatureSection from './themes/theme-1/components/FeatureSection';
import Footer from './themes/theme-1/components/Footer';
import Header from './themes/theme-1/components/Header';
import HeroSection from './themes/theme-1/components/HeroSection';
import Testimonial from './themes/theme-1/components/Testimonial';
import TopBanner from './themes/theme-1/components/TopBanner';
import FactSection from './themes/theme-2/components/FactSection';
import GTGCosmeticsHeader from './themes/theme-2/components/Header';
import StorySection from './themes/theme-2/components/StorySection';
import CosmeticsStatisticsSection from '@/pages/PageBuilder/themes/theme-2/components/CosmeticsStatisticsSection';
import ProductShowcase from './themes/theme-2/components/ProductShowcase';
import FAQCosmetic from './themes/theme-2/components/FAQCosmetic';
import { useNavigate, useParams } from 'react-router-dom';
import { relative } from 'path';
import gtg_cosmetics from './data/gtg_cosmetics.json';
import gtg_crm from './data/gtg_crm_template.json';
import FormInput from './components/form-builder/FormInput';
import FormButton from './components/form-builder/FormButton';
import FormTextArea from './components/form-builder/FormTextArea';
import FormRadio from './components/form-builder/FormRadio';
import FormCheckbox from './components/form-builder/FormCheckbox';
import FormDatePicker from './components/form-builder/FormDatePicker';
import FormSelect from './components/form-builder/FormSelect';
import RecommendationSection from './themes/theme-2/components/RecommendationSection';
import TestimonialCosmetic from './themes/theme-2/components/TestimonialCosmetic';
import FooterCosmetic from './themes/theme-2/components/Footer';
import { FormBox } from '.';
const Container = ({ children }: any) => {
  return <div className="h-full">{children}</div>;
};

const PreviewTemplate: React.FC = () => {
  const { id, templateCode } = useParams();
  const navigate = useNavigate();
  const { device, setDevice } = usePageBuilder(
    useShallow((state) => ({
      device: state.device,
      setDevice: state.setDevice,
    }))
  );

  const { mutate: saveLatestState } = useMutation(
    'saveState',
    ({
      landingPageId,
      changedData,
    }: {
      landingPageId: string;
      changedData: string;
    }) => saveState(landingPageId, changedData),
    {
      onSuccess: () => {
        navigate('../../editor', { relative: 'path' });
      },
      onError: () => {
        message.error({
          content: 'Some thing went wrong',
        });
      },
    }
  );

  const { mutate: selectTemplate } = useMutation(
    'updateLandingPage',
    updateLandingPage,
    {
      onSuccess: () => {
        let json;

        if (templateCode === 'gtg_cosmetics') {
          json = JSON.stringify(gtg_cosmetics);
        } else {
          json = JSON.stringify(gtg_crm);
        }

        const landingPageId = id || '';
        const compressedData = lz.encodeBase64(lz.compress(json));

        saveLatestState({ landingPageId, changedData: compressedData });
      },
      onError: () => {
        message.error({
          content: 'Something went wrong',
        });
      },
    }
  );

  const deviceWidth = {
    desktop: '100%',
    mobile: '430px',
    tablet: '768px',
  };
  const getResolver = (): Resolver => {
    if (templateCode === 'gtg_cosmetics') {
      return {
        GTGCosmeticsHeader,
        FactSection,
        CosmeticsStatisticsSection,
        ProductShowcase,
        StorySection,
        FAQCosmetic,
        RecommendationSection,
        TestimonialCosmetic,
        FooterCosmetic,
        Container,
        InitPreviewTemplate,
        InitPageContent,
        Iframe,
      };
    }

    return {
      HeroSection,
      Header,
      CTA,
      FeatureSection,
      Testimonial,
      FAQ,
      Footer,
      TopBanner,
      Pricing,
      FormInput,
      FormButton,
      FormSelect,
      FormBox,
      // DemoForm,
      FormTextArea,
      FormRadio,
      FormCheckbox,
      FormDatePicker,
      Container,
      InitPreviewTemplate,
      InitPageContent,
      Iframe,
    };
  };
  return (
    <div>
      <Editor resolver={getResolver()}>
        <Flex vertical gap={24} className="p-6">
          <Flex justify="space-between">
            <GTGSButton onClick={() => navigate('..', { relative: 'path' })}>
              Back
            </GTGSButton>
            <GTGSButton
              type="primary"
              onClick={() =>
                selectTemplate({
                  id: id || '',
                  templateId: '',
                  templateCode: templateCode || '',
                })
              }
            >
              USE THIS TEMPLATE
            </GTGSButton>
          </Flex>
          <GTGSTypography.Title level={3}>
            Preview Template
          </GTGSTypography.Title>
          <Flex justify="center">
            <ConfigProvider
              theme={{
                components: {
                  Segmented: {
                    trackPadding: 0,
                    itemSelectedBg: '#005CE6',
                    trackBg: '#E5F0FF',
                    itemColor: '#000',
                    itemSelectedColor: '#fff',
                    itemHoverColor: '#000',
                  },
                },
              }}
            >
              <Segmented
                value={device}
                onChange={(value) => setDevice(value as 'desktop' | 'mobile')}
                options={[
                  { value: 'mobile', icon: <MobileOutlined /> },
                  { value: 'tablet', icon: <TabletOutlined /> },
                  { value: 'desktop', icon: <DesktopOutlined /> },
                ]}
              />
            </ConfigProvider>
          </Flex>
          <div
            style={{
              width: deviceWidth[device],
              height: 'calc(100vh - 70px)',
              margin: 'auto',
              transition: 'width 0.3s ease-in-out',
            }}
          >
            <Iframe>
              <Frame>
                <Element canvas id="root" is={Container}>
                  <InitPreviewTemplate />
                </Element>
              </Frame>
            </Iframe>
          </div>
        </Flex>
      </Editor>
    </div>
  );
};

export default PreviewTemplate;
