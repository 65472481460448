import React, { useRef, useEffect, useState, ReactNode } from 'react';
import { createPortal } from 'react-dom';

interface IFrameProps extends React.IframeHTMLAttributes<HTMLIFrameElement> {
  children: ReactNode;
}

const IFrame: React.FC<IFrameProps> = ({ children, style, ...props }) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [mountNode, setMountNode] = useState<HTMLElement | null>(null);

  useEffect(() => {
    const iframe = iframeRef.current;
    if (!iframe) return;

    const iframeDocument =
      iframe.contentDocument || iframe.contentWindow?.document;
    if (!iframeDocument) return;

    iframeDocument.open();
    iframeDocument.close();

    const styleElement = iframeDocument.createElement('style');
    styleElement.textContent = `
      body {
        margin: 0;
      }
    `;
    iframeDocument.head.appendChild(styleElement);

    setMountNode(iframeDocument.body);
  }, []);

  return (
    <iframe
      ref={iframeRef}
      style={{ border: 'none', width: '100%', height: '100%', ...style }}
      {...props}
    >
      {mountNode && createPortal(children, mountNode)}
    </iframe>
  );
};

export default IFrame;
