import React, { useState } from 'react';
import {
  Form,
  Select,
  Radio,
  InputNumber,
  Typography,
  Card,
  Tooltip,
  Button,
  Avatar,
} from 'antd';
import {
  InfoCircleOutlined,
  PictureOutlined,
  UserOutlined,
} from '@ant-design/icons';
import PreviewSection from '../PreviewSection';
const { Option } = Select;
const { Title, Text } = Typography;

interface TargetingProps {
  previewData: {
    bodyCopy: string;
    headline: string;
    selectedFacebookPage: string;
  };
}

const Targeting: React.FC<TargetingProps> = ({ previewData }) => {
  const [estimatedAudienceSize] = useState(0);

  return (
    <div className="flex flex-col lg:flex-row h-screen">
      <div className="w-full lg:w-1/2 bg-white p-8">
        <Form layout="vertical">
          <Form.Item label="Special ad category *" name="specialAdCategory">
            <Select style={{ height: 40 }} placeholder="None">
              <Option value="none">None</Option>
              <Option value="housing">Housing</Option>
              <Option value="employment">Employment</Option>
              <Option value="credit">Credit</Option>
            </Select>
          </Form.Item>
          <div className="flex flex-col gap-2">
            <Title level={4}>Audience</Title>
            <Radio.Group defaultValue="new" value="audience" className="mb-4">
              <Radio value="new">New audience</Radio>
              <Radio value="saved">Select a saved audience</Radio>
            </Radio.Group>
          </div>

          <Form.Item label="Condition" className="mb-4">
            <div className="flex items-center gap-4">
              <Select defaultValue="and" style={{ width: 80, height: 40 }}>
                <Option value="and">And</Option>
                <Option value="or">Or</Option>
              </Select>
              <Form.Item label="Location *" name="location" noStyle>
                <Select
                  mode="multiple"
                  placeholder="Select location"
                  style={{ height: 40, width: '100%' }}
                >
                  <Option value="vn">Vietnam</Option>
                  <Option value="us">United States</Option>
                  <Option value="uk">United Kingdom</Option>
                </Select>
              </Form.Item>
            </div>
          </Form.Item>

          <Form.Item label="Condition" className="mb-4">
            <div className="flex items-center gap-4 w-full">
              <Select defaultValue="and" style={{ width: 80, height: 40 }}>
                <Option value="and">And</Option>
                <Option value="or">Or</Option>
              </Select>
              <Form.Item label="Age range *" name="ageRange" noStyle>
                <div className="flex items-center gap-2">
                  <InputNumber
                    min={18}
                    max={100}
                    defaultValue={18}
                    style={{ width: '100%', height: 40 }}
                  />
                  <span>to</span>
                  <InputNumber
                    min={18}
                    max={100}
                    defaultValue={30}
                    style={{ width: '100%', height: 40 }}
                  />
                </div>
              </Form.Item>
            </div>
          </Form.Item>
        </Form>
        <Text className="text-blue-500 cursor-pointer">+ Add more filter</Text>
      </div>

      <PreviewSection
        status="both"
        estimatedAudienceSize={estimatedAudienceSize}
        previewData={previewData}
      />
    </div>
  );
};

export default Targeting;
