import React from 'react';
import { useNode } from '@craftjs/core';

type ProductShowcaseProps = {
  title?: string;
  description?: string;
  buttonText?: string;
  additionalDescription?: string;
  products?: {
    title: string;
    description: string;
    imgSrc: string;
  }[];
};

const ProductShowcase = ({
  title = 'Our Latest Products',
  description = 'Wide range of organic products and all are handpicked for you to see.',
  buttonText = 'View All',
  additionalDescription = 'In here, we show some of our new products for you. For more details, you can click the link below to see all our latest products.',
  products = [
    {
      title: 'Jo Malone Cologne',
      description: 'Body Cologne',
      imgSrc: '/cosmetic/jo-malone.png',
    },
    {
      title: 'Eau de Parfume',
      description: 'Rose Perfume',
      imgSrc: '/cosmetic/eau-de-parfume.png',
    },
    {
      title: 'Coco Oil Organic',
      description: 'Body Oil Cleanser',
      imgSrc: '/cosmetic/coco-oil.png',
    },
  ],
}: ProductShowcaseProps) => {
  const {
    connectors: { connect },
  } = useNode();

  return (
    <div
      className="w-full py-14 px-4 md:px-0"
      ref={connect as React.LegacyRef<HTMLDivElement>}
    >
      <div className="w-full flex flex-col md:flex-row items-center">
        <div className="max-w-xs p-2 ml-[10%]">
          <h2 className="text-[48px] font-extrabold text-[#183B56] mb-4">
            {title}
          </h2>
          <p className="text-[#183B56] text-[20px] font-semibold leading-relaxed">
            {description}
          </p>
          <p className="text-[#183B56] text-[16px] font-light leading-relaxed ">
            {additionalDescription}
          </p>
          <button className="bg-[#183B56] font-bold text-[16px] md:text-[18px] text-[#FFFFFF] py-2 px-6 md:py-3 md:px-10 rounded-lg transition md:mt-8 mt-4">
            {buttonText}
          </button>
        </div>
        <div className="md:w-full flex justify-end ml-6">
          {products.map((product, index) => (
            <div key={index} className="text-center pl-10">
              <img
                src={product.imgSrc}
                alt={product.title}
                className="w-24 h-24 md:w-32 md:h-32 lg:w-full lg:h-auto mb-4 rounded-lg object-cover"
              />
              <h3 className="text-lg font-semibold text-[#183B56]">
                {product.title}
              </h3>
              <p className="text-gray-500">{product.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const ProductShowcaseSettings = () => {
  const {
    actions: { setProp },
    title,
    description,
    buttonText,
    additionalDescription,
    products,
  } = useNode((node) => ({
    title: node.data.props.title,
    description: node.data.props.description,
    buttonText: node.data.props.buttonText,
    additionalDescription: node.data.props.additionalDescription,
    products: node.data.props.products,
  }));

  return (
    <div>
      <label className="block mb-2 font-medium text-gray-700">Title</label>
      <input
        className="w-full px-3 py-2 border rounded-md focus:ring-blue-500"
        defaultValue={title}
        onChange={(e) =>
          setProp(
            (props: ProductShowcaseProps) => (props.title = e.target.value)
          )
        }
      />
      <label className="block mt-4 mb-2 font-medium text-gray-700">
        Description
      </label>
      <textarea
        rows={4}
        className="w-full px-3 py-2 border rounded-md focus:ring-blue-500"
        defaultValue={description}
        onChange={(e) =>
          setProp(
            (props: ProductShowcaseProps) =>
              (props.description = e.target.value)
          )
        }
      />
      <label className="block mt-4 mb-2 font-medium text-gray-700">
        Additional Description
      </label>
      <textarea
        rows={4}
        className="w-full px-3 py-2 border rounded-md focus:ring-blue-500"
        defaultValue={additionalDescription}
        onChange={(e) =>
          setProp(
            (props: ProductShowcaseProps) =>
              (props.additionalDescription = e.target.value)
          )
        }
      />
      <label className="block mt-4 mb-2 font-medium text-gray-700">
        Button Text
      </label>
      <input
        className="w-full px-3 py-2 border rounded-md focus:ring-blue-500"
        defaultValue={buttonText}
        onChange={(e) =>
          setProp(
            (props: ProductShowcaseProps) => (props.buttonText = e.target.value)
          )
        }
      />
    </div>
  );
};

ProductShowcase.defaultProps = {
  title: 'Our Latest Products',
  description:
    'Wide range of organic products and all are handpicked for you to see.',
  buttonText: 'View All',
  additionalDescription:
    'In here, we show some of our new products for you. For more details, you can click the link below to see all our latest products.',
  products: [
    {
      title: 'Jo Malone Cologne',
      description: 'Body Cologne',
      imgSrc: '/cosmetic/jo-malone.png',
    },
    {
      title: 'Eau de Parfume',
      description: 'Rose Perfume',
      imgSrc: '/cosmetic/eau-de-parfume.png',
    },
    {
      title: 'Coco Oil Organic',
      description: 'Body Oil Cleanser',
      imgSrc: '/cosmetic/coco-oil.png',
    },
  ],
};

ProductShowcase.craft = {
  props: ProductShowcase.defaultProps,
  related: {
    settings: ProductShowcaseSettings,
  },
  displayName: 'Product Showcase',
};

export default ProductShowcase;
