// See: https://dev.to/admitkard/auto-generate-avatar-colors-randomly-138j

type Range = [min: number, max: number];
type HSL = [h: number, s: number, l: number];


export const generateColorFromText = (
  text: string,
  {
    saturationRange = getRange(50, 10),
    lightnessRange = getRange(50, 10),
  } = {},
) => (
  HSLtoString(generateHSL(text, saturationRange, lightnessRange))
);


const getHashOfString = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    // eslint-disable-next-line no-bitwise
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  hash = Math.abs(hash);
  return hash;
};


const normalizeHash = (hash: number, min: number, max: number) => Math.floor((hash % (max - min)) + min);


const generateHSL = (name: string, saturationRange: Range, lightnessRange: Range): HSL => {
  const hash = getHashOfString(name);
  const h = normalizeHash(hash, 0, 360);
  const s = normalizeHash(hash, saturationRange[0], saturationRange[1]);
  const l = normalizeHash(hash, lightnessRange[0], lightnessRange[1]);
  return [h, s, l];
};


const HSLtoString = (hsl: HSL) => `hsl(${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%)`;


const getRange = (value: number, range: number): Range => [Math.max(0, value - range), Math.min(value + range, 100)];
