import { SearchOutlined } from '@ant-design/icons';
import { Checkbox, Input, List } from 'antd';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { DropdownFilterOption } from './GTGSDropdownFilter';

interface SearchBoxProps {
  singleValue?: boolean;
  onSelect?: (value: string[], keyword: string) => void;
  options: DropdownFilterOption[];
  renderLabel?: (option: DropdownFilterOption) => React.ReactNode;
}

const _SearchBox = ({ singleValue, onSelect, options, renderLabel }: SearchBoxProps) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredOptions, setFilteredOptions] = useState(options);
  const debounceTimerRef = useRef<NodeJS.Timeout | null>(null);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  const debouncedSearch = useCallback((value: string) => {
    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
    }
    debounceTimerRef.current = setTimeout(() => {
      setSearchTerm(value);
    }, 400);
  }, []);

  useEffect(() => {
    return () => {
      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current);
      }
    };
  }, []);

  const handleSearch = useCallback(
    (value: string) => {
      debouncedSearch(value);
      const filtered = options.filter(
        (option) =>
          option.label
            .trim()
            .toLowerCase()
            .includes(value.trim().toLowerCase()) ||
          option.value.trim().toLowerCase().includes(value.trim().toLowerCase())
      );
      setFilteredOptions(filtered);
    },
    [debouncedSearch, options]
  );

  const handleSelect = useCallback(
    (value: string) => {
      setSelectedItems((prev) => {
        let newSelected: string[];
        if (singleValue) {
          newSelected = prev.includes(value) ? [] : [value];
        } else {
          newSelected = prev.includes(value)
            ? prev.filter((item) => item !== value)
            : [...prev, value];
        }
        onSelect?.(newSelected, searchTerm);
        return newSelected;
      });
    },
    [onSelect, searchTerm, singleValue]
  );

  return (
    <div className="border bg-white rounded-lg">
      <div className="bg-[#F5F6F9] rounded-tr-lg rounded-tl-lg p-2">
        <Input
          placeholder="Search"
          suffix={<SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />}
          onChange={(e) => handleSearch(e.target.value)}
          size="small"
          style={{ height: 34 }}
        />
      </div>
      <List
        style={{
          maxHeight: '200px',
          overflowY: 'auto',
          backgroundColor: 'white',
          borderRadius: '0 0 4px 4px',
        }}
        dataSource={filteredOptions}
        renderItem={(item) => (
          <List.Item
            style={{ cursor: 'pointer', padding: '8px 12px' }}
          >
            {singleValue ? (
              <div
                className={`flex items-center ${selectedItems.includes(item.value) ? 'text-blue-500' : ''}`}
                onClick={() => handleSelect(item.value)}
              >
                {renderLabel ? renderLabel(item) : (
                  <strong>{item.label}</strong>
                )}
                {!!item.subLabel && (
                  <div
                    style={{
                      color: '#888',
                      fontSize: '12px',
                      marginLeft: '8px',
                    }}
                  >
                    {item.subLabel}
                  </div>
                )}
              </div>
            ) : (
              <Checkbox
                checked={selectedItems.includes(item.value)}
                onChange={() => handleSelect(item.value)}
              >
                <strong>{item.label}</strong>
                {!!item.subLabel && (
                  <div style={{ color: '#888', fontSize: '12px' }}>
                    {item.subLabel}
                  </div>
                )}
              </Checkbox>
            )}
          </List.Item>
        )}
      />
    </div>
  );
};

const SearchBox = memo(_SearchBox);

export { SearchBox, type SearchBoxProps };
