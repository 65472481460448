export const INIT_CRM = {
  currentTab: 0,
};

export enum E_DEFAULT_CRM_CONTACT_PROPERTIES {
  EMAIL = 'email',
  FIRST_NAME = 'first_name',
  LAST_NAME = 'last_name',
}

export enum E_AUTO_GENERATED_FIELDS {
  CREATE_DATE = 'create_date',
  LAST_ACTIVITY_DATE = 'last_activity_date',
}

export enum E_DEFAULT_CRM_COMPANY_PROPERTIES {
  NAME = 'name',
  DOMAIN_NAME = 'domain_name',
}

export enum E_DEFAULT_CRM_DEAL_PROPERTIES {
  NAME = 'name',
  PIPELINE = 'pipeline',
  STAGE = 'stage',
}

export enum E_DEFAULT_CRM_TASK_PROPERTIES {
  TITLE = 'title',
  TYPE = 'type',
  PRIORITY = 'priority',
}

export enum E_CRM_OBJECT_TYPE {
  CONTACTS = 'contacts',
  COMPANIES = 'companies',
  DEALS = 'deals',
  TASKS = 'tasks',
  TICKETS = 'tickets',
}
export enum E_MARKETING_OBJECT_TYPE {
  EMAIL = 'email',
}
export const DEFAULT_CRM_PROPERTIES_LIST = {
  [E_CRM_OBJECT_TYPE.CONTACTS]: [
    E_DEFAULT_CRM_CONTACT_PROPERTIES.EMAIL,
    E_DEFAULT_CRM_CONTACT_PROPERTIES.FIRST_NAME,
    E_DEFAULT_CRM_CONTACT_PROPERTIES.LAST_NAME,
  ],
  [E_CRM_OBJECT_TYPE.COMPANIES]: [
    E_DEFAULT_CRM_COMPANY_PROPERTIES.NAME,
    E_DEFAULT_CRM_COMPANY_PROPERTIES.DOMAIN_NAME,
  ],
  [E_CRM_OBJECT_TYPE.DEALS]: [
    E_DEFAULT_CRM_DEAL_PROPERTIES.NAME,
    E_DEFAULT_CRM_DEAL_PROPERTIES.PIPELINE,
    E_DEFAULT_CRM_DEAL_PROPERTIES.STAGE,
  ],
  [E_CRM_OBJECT_TYPE.TASKS]: [
    E_DEFAULT_CRM_TASK_PROPERTIES.TITLE,
    E_DEFAULT_CRM_TASK_PROPERTIES.TYPE,
    E_DEFAULT_CRM_TASK_PROPERTIES.PRIORITY,
  ],
};

export const OBJECT_TYPE_NAMES = {
  [E_CRM_OBJECT_TYPE.CONTACTS]: 'Contact activity',
  [E_CRM_OBJECT_TYPE.COMPANIES]: 'Companies',
  [E_CRM_OBJECT_TYPE.DEALS]: 'Deals',
  [E_CRM_OBJECT_TYPE.TASKS]: 'Tasks',
  [E_CRM_OBJECT_TYPE.TICKETS]: 'Tickets',
};

export const LIFECYCLE_STAGE_OPTIONS = [
  { value: 'none', label: 'None' },
  { value: 'subscriber', label: 'Subscriber' },
  { value: 'lead', label: 'Lead' },
  { value: 'marketing_qualified_lead', label: 'Marketing Qualified Lead' },
  { value: 'sales_qualified_lead', label: 'Sales Qualified Lead' },
  { value: 'opportunity', label: 'Opportunity' },
  { value: 'customer', label: 'Customer' },
  { value: 'evangelist', label: 'Evangelist' },
  { value: 'other', label: 'Other' },
];

export const LEAD_STATUS_OPTIONS = [
  { value: 'none', label: 'None' },
  { value: 'new', label: 'New' },
  { value: 'open', label: 'Open' },
  { value: 'in_progress', label: 'In Progress' },
  { value: 'open_deal', label: 'Open Deal' },
  { value: 'unqualified', label: 'Unqualified' },
  { value: 'attempted', label: 'Attempted' },
  { value: 'connected', label: 'Connected' },
  { value: 'bad_timing', label: 'Bad Timing' },
];

// KEYs
export const CRM_KEYS = {
  CREATE_CONTACT: 'create_contact',
  CREATE_COMPANY: 'create_company',
  CREATE_DEAL: 'create_deal',
  CREATE_TASK: 'create_task',
  ADVANCED_FILTER: 'afilter',
  FILTER_GROUP: 'fgroup',
  FILTER_ITEM: 'fitem',
  SEARCH: 'search',
  PREVIEW: 'preview',
  BULK_ACTION: 'bulk_action',
  ASSIGN: 'assign',
  EDIT: 'edit',
  DELETE: 'delete',
  MESSAGES: 'messages',
  ACTION: 'action',
  COLUMN_HEADERS_CONTACTS: 'column_headers_contacts',
  COLUMN_HEADERS_COMPANIES: 'column_headers_companies',
  COLUMN_HEADERS_DEALS: 'column_headers_deals',
  COLUMN_HEADERS_TASKS: 'column_headers_tasks',
}

export enum E_ADVANCED_FILTER_OPERATORS {
  IN = 'IN',
  NOT_IN = 'NOT IN',
  LIKE = 'LIKE',
  NOT_LIKE = 'NOT LIKE',
}

export const ADVANCED_FILTER_OPERATORS = [
  { value: E_ADVANCED_FILTER_OPERATORS.IN, label: 'is equal to' },
  { value: E_ADVANCED_FILTER_OPERATORS.NOT_IN, label: 'is not equal to' },
  { value: E_ADVANCED_FILTER_OPERATORS.LIKE, label: 'contains' },
  { value: E_ADVANCED_FILTER_OPERATORS.NOT_LIKE, label: 'does not contain' },
];

export const COMPANY_TYPE_OPTIONS = [
  { value: 'none', label: 'None' },
  { value: 'prospect', label: 'Prospect' },
  { value: 'partner', label: 'Partner' },
  { value: 'reseller', label: 'Reseller' },
  { value: 'vendor', label: 'Vendor' },
  { value: 'other', label: 'Other' },
];

export const COMPANY_INDUSTRY_OPTIONS = [
  { value: 'none', label: 'None' },
  { value: 'accounting', label: 'Accounting' },
  { value: 'airlines_or_aviation', label: 'Airlines/Aviation' },
  {
    value: 'alternative_dispute_resolution',
    label: 'Alternative Dispute Resolution',
  },
  { value: 'alternative_medicine', label: 'Alternative Medicine' },
  { value: 'animation', label: 'Animation' },
  { value: 'apparel_and_fashion', label: 'Apparel & Fashion' },
  { value: 'arts_and_crafts', label: 'Arts & Crafts' },
  { value: 'automotive', label: 'Automotive' },
  { value: 'other', label: 'Other' },
];

export const COMPANY_TIMEZONE_OPTIONS = [
  { label: '(UTC-12:00) International Date Line West', value: '-12:00' },
  { label: '(UTC-11:00) Coordinated Universal Time-11', value: '-11:00' },
  { label: '(UTC-10:00) Hawaii', value: '-10:00' },
  { label: '(UTC-09:00) Alaska', value: '-09:00' },
  { label: '(UTC-08:00) Pacific Time (US & Canada)', value: '-08:00' },
  { label: '(UTC-07:00) Mountain Time (US & Canada)', value: '-07:00' },
  { label: '(UTC-06:00) Central Time (US & Canada)', value: '-06:00' },
  { label: '(UTC-05:00) Eastern Time (US & Canada)', value: '-05:00' },
  { label: '(UTC-04:00) Atlantic Time (Canada)', value: '-04:00' },
  { label: '(UTC-03:00) Brasilia', value: '-03:00' },
  { label: '(UTC-02:00) Coordinated Universal Time-02', value: '-02:00' },
  { label: '(UTC-01:00) Cape Verde Is.', value: '-01:00' },
  { label: '(UTC+00:00) UTC', value: '+00:00' },
  { label: '(UTC+01:00) Central European Time', value: '+01:00' },
  { label: '(UTC+02:00) Eastern European Time', value: '+02:00' },
  { label: '(UTC+03:00) Moscow, St. Petersburg', value: '+03:00' },
  { label: '(UTC+04:00) Abu Dhabi, Muscat', value: '+04:00' },
  { label: '(UTC+05:00) Islamabad, Karachi', value: '+05:00' },
  { label: '(UTC+06:00) Astana', value: '+06:00' },
  { label: '(UTC+07:00) Bangkok, Hanoi', value: '+07:00' },
  { label: '(UTC+08:00) Beijing, Hong Kong', value: '+08:00' },
  { label: '(UTC+09:00) Tokyo', value: '+09:00' },
  { label: '(UTC+10:00) Sydney', value: '+10:00' },
  { label: '(UTC+11:00) Solomon Is.', value: '+11:00' },
  { label: '(UTC+12:00) Auckland', value: '+12:00' }
]
