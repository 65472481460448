import { messaging } from '@/helpers/firebase';
import { notification } from 'antd';
import { onMessage } from 'firebase/messaging';
import { useEffect, useLayoutEffect, useMemo } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { GTGSIcon, GTGSTypography } from './components/GTGS';
import MainLayout from './components/Layout';
import AppProvider from './components/Layout/AppProvider';
import UserService from './services/UserService';
import { initDateTimeSetup } from './utils/dateUtils';

initDateTimeSetup();

function App() {
  const navigate = useNavigate();

  const [api, contextHolder] = notification.useNotification({ top: 96 });

  const onMessageListener = (async () => {
    const icon = {
      PENDING: <GTGSIcon name="pending" size="small" />,
      REJECTED: <GTGSIcon name="cancel" size="small" />,
      APPROVED: <GTGSIcon name="success" size="small" />,
    };
    const messagingResolve = await messaging;
    if (messagingResolve) {
      onMessage(messagingResolve, (payload) => {
        console.log({ payload });
        api.info({
          message: (
            <GTGSTypography.Text strong>
              {payload?.data?.message}
            </GTGSTypography.Text>
          ),
          // description: (
          //   <GTGSTypography.Paragraph
          //     ellipsis={{ rows: 2, expandable: false }}
          //     style={{
          //       marginBottom: 0,
          //       fontSize: 14,
          //       color: '#fff',
          //       paddingRight: 48,
          //     }}
          //   >
          //     {payload?.notification?.body}
          //   </GTGSTypography.Paragraph>
          // ),
          icon: icon[payload?.data?.notificationType ?? 'WARNING'],
          // closeIcon: <GTGSIcon name="close" fill="#fff" />,
          placement: 'topRight',
          // style: {
          //   backgroundColor: '#213051',
          //   borderRadius: 4,
          //   color: '#fff',
          //   cursor: 'pointer',
          // },
          duration: 10,

          // onClick: () => onClickNotification(payload?.data),
        });

        // queryClient.invalidateQueries(['infiniteNotifications']);
        // queryClient.invalidateQueries(['unreadNotifications']);

        // Sync data
        // if (payload?.data?.entityType === 'ApprovalRequest') {
        //   if (
        //     pathname === '/' &&
        //     payload?.data?.notificationType === 'PENDING'
        //   ) {
        //     return queryClient.invalidateQueries(['fetchAllRequests']);
        //   }

        //   if (
        //     pathname === '/requests' &&
        //     search === `?tab=${CTAB.pending}` &&
        //     payload?.data?.notificationType === 'PENDING'
        //   ) {
        //     return queryClient.invalidateQueries([
        //       `fetchRequest${CTAB.pending}`,
        //     ]);
        //   }
        //   if (
        //     pathname === '/requests' &&
        //     search === `?tab=${CTAB.allRequest}`
        //   ) {
        //     return queryClient.invalidateQueries([
        //       `fetchRequest${CTAB.allRequest}`,
        //     ]);
        //   }
        // } else {
        //   //Force reload account list when receive notification balance change
        //   queryClient.invalidateQueries(['getListAccountByMe']);
        //   queryClient.invalidateQueries(['fetchBankAccounts']);
        //   return;
        // }
        // if (payload?.data?.entityType === 'BALANCE_ALARM') {
        //   return queryClient.invalidateQueries(['getListAccountByMe']);
        // }
      });
    }
  })();

  // const handleGetFirebaseToken = () => {
  //   getFirebaseToken().then((firebaseToken: string | undefined) => {
  //     if (firebaseToken) {
  //       // console.log(firebaseToken);
  //     }
  //   });
  // };

  // useEffect(() => {
  //   if (
  //     'Notification' in window &&
  //     window.Notification?.permission === 'granted'
  //   ) {
  //     handleGetFirebaseToken();
  //   }
  // }, [api, onClickNotification, pathname, queryClient, search]);

  useLayoutEffect(() => {
    // httpService.configure();
    const dataUser: any = UserService.getTokenParsed();
  }, []);

  useEffect(() => {
    const workspaceId = localStorage.getItem('workspaceId');
    if (!workspaceId) {
      navigate('/workspaces');
    }
  }, [navigate]);

  const renderContent = useMemo(() => {
    if (!UserService.isLoggedIn() || !localStorage.getItem('workspaceId')) {
      return null;
    }

    // if (NO_LAYOUT_PAGES.some((path) => pathname.startsWith(path, 0))) {
    //   return <Outlet />;
    // }

    return (
      <MainLayout>
        {contextHolder}
        <Outlet />
      </MainLayout>
    );
  }, [contextHolder]);

  return <AppProvider>{renderContent}</AppProvider>;
}

export default App;
