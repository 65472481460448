import { CollapseProps } from 'antd';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { Collapse as AntdCollapse } from 'antd';
import { memo, useCallback } from 'react';
import { GTGSIcon } from '../GTGSIcon/GTGSIcon';

interface GTGSCollapseProps extends CollapseProps {
  sizeIcon?: number;
  onClickIcon?: (isActive: boolean) => void;
}
export const _GTGSCollapse = (props: GTGSCollapseProps) => {
  const { className, sizeIcon = 16, onClickIcon, ...rest } = props;

  const onClick = useCallback(
    (isActive: boolean | undefined) => {
      onClickIcon?.(isActive || false);
    },
    [onClickIcon]
  );
  return (
    <AntdCollapse
      accordion
      expandIconPosition="end"
      expandIcon={({ isActive }) =>
        isActive ? (
          <GTGSIcon
            name="chevron-up"
            size={sizeIcon}
            onClick={() => onClick(isActive)}
          />
        ) : (
          <GTGSIcon
            name="chevron-down"
            size={sizeIcon}
            onClick={() => onClick(isActive)}
          />
        )
      }
      className={classNames(styles.collapse, className)}
      {...rest}
    />
  );
};

const GTGSCollapse = memo(_GTGSCollapse);

export { GTGSCollapse, type GTGSCollapseProps };
