import { Map } from '@/components/GTGS/Map';
import { Element, useEditor } from '@craftjs/core';
import { memo, ReactElement, useMemo } from 'react';
import { Button } from '../EmailControls/Button';
import ButtonIcon from '../../../assets/icons/button.svg';
import TextIcon from '../../../assets/icons/text.svg';
import ImageIcon from '../../../assets/icons/image.svg';
import DividerIcon from '../../../assets/icons/divider.svg';
import SocialIcon from '../../../assets/icons/social.svg';
import OneColIcon from '../../../assets/icons/one_col.svg';
import TwoColIcon from '../../../assets/icons/two_col.svg';
import ThreeColIcon from '../../../assets/icons/three_col.svg';
import OneTwoColIcon from '../../../assets/icons/one_two_col.svg';
import TwoOneColIcon from '../../../assets/icons/two_one_col.svg';
import FourColIcon from '../../../assets/icons/four_col.svg';

import { GTGSTypography } from '@/components/GTGS';
import { Container } from '../EmailControls/Container';
import { Col } from '../EmailControls/Col';
import { Image } from '../EmailControls/Image';
import { Text } from '../EmailControls/Text';

import Logo from '../../../assets/images/Logo.png';
import { Divider } from '../EmailControls/Divider';
import { Social } from '../EmailControls/Social';

type IControlItemProps = {
  title: string;
  icon: string;
  node: ReactElement;
};

const ControlItem = memo((props: IControlItemProps) => {
  const { connectors } = useEditor();
  return (
    <div
      ref={(ref) => {
        connectors.create(ref!, props.node);
      }}
      className="bg-[#F6F8FA] px-2 py-6 w-[107px] h-[102px] flex justify-center items-center gap-4 flex-col rounded-lg cursor-pointer"
    >
      <div>
        <img src={props.icon} />
      </div>
      <div>{props.title}</div>
    </div>
  );
});

export const ControlList = memo(() => {
  const _controlsItems: IControlItemProps[] = useMemo(() => {
    return [
      {
        title: 'Image',
        icon: ImageIcon,
        node: <Element is={Image} link={Logo} />,
      },
      {
        title: 'Text',
        icon: TextIcon,
        node: <Text>New Text</Text>,
      },
      {
        title: 'Button',
        icon: ButtonIcon,
        node: <Button>Button</Button>,
      },
      {
        title: 'Divider',
        icon: DividerIcon,
        node: <Divider />,
      },

      {
        title: 'Social',
        icon: SocialIcon,
        node: (
          <Social
            data={[
              { type: 'tiktok', link: '' },
              { type: 'facebook', link: '' },
              { type: 'instagram', link: '' },
            ]}
          />
        ),
      },
    ];
  }, []);

  const _layoutItems: IControlItemProps[] = useMemo(() => {
    return [
      {
        title: '1',
        icon: OneColIcon,
        node: (
          <Element is={Container} background="white" width="content-width">
            <Element is={Col} canvas></Element>
          </Element>
        ),
      },
      {
        title: '2',
        icon: TwoColIcon,
        node: (
          <Element is={Container} background="white" width="content-width">
            <Element is={Col} canvas></Element>
            <Element is={Col} canvas></Element>
          </Element>
        ),
      },
      {
        title: '3',
        icon: ThreeColIcon,
        node: (
          <Element is={Container} background="white" width="content-width">
            <Element is={Col} canvas></Element>
            <Element is={Col} canvas></Element>
            <Element is={Col} canvas></Element>
          </Element>
        ),
      },
      {
        title: '1/3 : 2/3',
        icon: OneTwoColIcon,
        node: <Button />,
      },

      {
        title: '2/3 : 1/3',
        icon: TwoOneColIcon,
        node: <Button />,
      },
      {
        title: '4',
        icon: FourColIcon,
        node: <Button />,
      },
    ];
  }, []);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row flex-wrap gap-4">
        <Map
          list={_controlsItems}
          renderItem={(item) => {
            return <ControlItem {...item} />;
          }}
        />
      </div>
      <div>
        <GTGSTypography.Title level={3}>Layout</GTGSTypography.Title>
      </div>
      <div className="flex flex-row flex-wrap gap-4">
        <Map
          list={_layoutItems}
          renderItem={(item) => {
            return <ControlItem {...item} />;
          }}
        />
      </div>
    </div>
  );
});
