import { Flex, Modal, ModalProps, Space } from 'antd';
import classNames from 'classnames';
import { memo, useCallback } from 'react';
import { GTGSTypography } from '../GTGSTypography/GTGSTypography';
import { GTGSIcon } from '../GTGSIcon/GTGSIcon';

interface AntdModalProps extends ModalProps {
  title?: string;
}

const _GTGSModalCustom = ({
  children,
  className,
  title,
  width = 600,
  ...rest
}: AntdModalProps) => {
  return (
    <Modal
      width={width}
      centered
      maskClosable={false}
      closable
      destroyOnClose
      closeIcon={<GTGSIcon name="close" fill="#fff" />}
      footer={null}
      {...rest}
      className={classNames('relative', className)}
    >
      <Flex
        className="z-[1001] absolute top-0 left-0 w-full h-[70px] bg-[#005CE6] p-8 !pr-[64px] rounded-t-md"
        align="center"
      >
        <GTGSTypography.Title level={4} className="!text-[#fff]">
          {title}
        </GTGSTypography.Title>
      </Flex>
      <Flex className="w-full mt-[70px] block">{children}</Flex>
    </Modal>
  );
};

const GTGSModalCustom = memo(_GTGSModalCustom);

export { GTGSModalCustom };
