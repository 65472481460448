import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { useNode } from '@craftjs/core';

type FeatureSectionProps = {
  feature1: string;
  feature2: string;
  feature3: string;
  feature4: string;
  feature5: string;
  feature6: string;
};

function FeatureSection({
  feature1,
  feature2,
  feature3,
  feature4,
  feature5,
  feature6,
}: FeatureSectionProps) {
  const {
    connectors: { connect },
    id,
  } = useNode();

  return (
    <div
      className="max-w-screen flex justify-center items-center py-20"
      ref={connect as React.LegacyRef<HTMLDivElement>}
      id={id}
    >
      <div className="relative w-[500px] h-[500px] flex justify-center items-center xl:flex">
        <div className="absolute inset-0 rounded-full border-dashed border-2 border-blue-200"></div>
        <div className="w-[240px] h-[240px] bg-gradient-to-b from-[#e1f0ff] to-[#d0ebff] rounded-full flex justify-center items-center relative">
          <img
            src="/avatar.png"
            alt="Person"
            className="absolute w-[300px] h-[300px] object-cover -top-10"
          />
        </div>
        <div className="absolute top-[4%] left-1/2 transform -translate-x-1/2 -translate-y-[30px] text-center">
          <FaCheckCircle className="text-green-500 text-[20px] mx-auto" />
          <p className="text-[14px] mt-2 w-[140px]">{feature1}</p>
        </div>
        <div className="absolute top-[25%] right-[6%] transform translate-x-[50%] -translate-y-[10%] text-center">
          <FaCheckCircle className="text-green-500 text-[20px] mx-auto" />
          <p className="text-[14px] mt-2 w-[140px]">{feature2}</p>
        </div>
        <div className="absolute bottom-[25%] right-[2%] transform translate-x-[50%] translate-y-[10%] text-center">
          <FaCheckCircle className="text-green-500 text-[20px] mx-auto" />
          <p className="text-[14px] mt-2 w-[140px]">{feature3}</p>
        </div>
        <div className="absolute bottom-[-6%] left-1/2 transform -translate-x-1/2 translate-y-[30px] text-center">
          <FaCheckCircle className="text-green-500 text-[20px] mx-auto" />
          <p className="text-[14px] mt-2 w-[140px]">{feature4}</p>
        </div>
        <div className="absolute bottom-[25%] left-[2%] transform -translate-x-[50%] translate-y-[10%] text-center">
          <FaCheckCircle className="text-green-500 text-[20px] mx-auto" />
          <p className="text-[14px] mt-2 w-[140px]">{feature5}</p>
        </div>
        <div className="absolute top-[25%] left-[6%] transform -translate-x-[50%] -translate-y-[10%] text-center">
          <FaCheckCircle className="text-green-500 text-[20px] mx-auto" />
          <p className="text-[14px] mt-2 w-[140px]">{feature6}</p>
        </div>
      </div>
    </div>
  );
}

FeatureSection.defaultProps = {
  feature1: 'Live chat connect with your social account',
  feature2: 'Free landing page template',
  feature3: 'Free 1-month trial of premium features',
  feature4: 'Activate and manage your data',
  feature5: 'Generate and track lead easily',
  feature6: 'Collect payment and automate billing',
};

const FeatureSectionSettings = () => {
  const {
    actions: { setProp },
    feature1,
    feature2,
    feature3,
    feature4,
    feature5,
    feature6,
  } = useNode((node) => ({
    feature1: node.data.props.feature1,
    feature2: node.data.props.feature2,
    feature3: node.data.props.feature3,
    feature4: node.data.props.feature4,
    feature5: node.data.props.feature5,
    feature6: node.data.props.feature6,
  }));

  return (
    <div>
      {[feature1, feature2, feature3, feature4, feature5, feature6].map(
        (feature, index) => (
          <div className="mb-5" key={index}>
            <label className="block mb-2 font-medium text-gray-700">
              Feature {index + 1}
            </label>
            <input
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              defaultValue={feature}
              onChange={(e) => {
                setProp(
                  (props: { [key: string]: string }) =>
                    (props[`feature${index + 1}`] = e.target.value),
                  1000
                );
              }}
            />
          </div>
        )
      )}
    </div>
  );
};

FeatureSection.craft = {
  props: FeatureSection.defaultProps,
  related: {
    settings: FeatureSectionSettings,
  },
  displayName: 'Feature Section',
};

export default FeatureSection;
