import { createRoute } from '@/helpers';
import React from 'react';
import { routesPath } from './constants';

const CommercePage = React.lazy(() => import('@/pages/Cases'));

// --------------------------------------------------------------------------- ROUTER

const RouterCommerce = {
    path: routesPath.COMMERCE,
    children: [
      createRoute(routesPath.COMMERCE, <CommercePage />),
    ],
  };
export default RouterCommerce;
