import { Select, SelectProps } from 'antd';

export const AlignmentSelect = (props: SelectProps) => {
  return (
    <Select
      options={[
        {
          label: 'Center',
          value: 'center',
        },
      ]}
      size='large'
      {...props}
    />
  );
};
