import { GTGSTypography } from '@/components/GTGS';
import { Form as AntdForm, FormItemProps, FormProps } from 'antd';
import { memo, PropsWithChildren } from 'react';

const FormItem = memo((props: FormItemProps) => {
  const { label } = props;
  let labelProps = {};
  if (typeof label === 'string') {
    labelProps = {
      ...labelProps,
      label: (
        <GTGSTypography.Text size="sm" strong>
          {label}
        </GTGSTypography.Text>
      ),
    };
  }
  return <AntdForm.Item {...props} {...labelProps} />;
});

const Form = memo((props: PropsWithChildren<FormProps>) => {
  return  <AntdForm layout="vertical" {...props} />;
});

const useForm = AntdForm.useForm;
const useWatch = AntdForm.useWatch;
const useFormInstance = AntdForm.useFormInstance;
export { Form, FormItem, useForm, useWatch, useFormInstance };
