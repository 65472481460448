import { createRoute } from '@/helpers';
import React from 'react';
import { routesPath } from './constants';


const ChannelsPage = React.lazy(() => import('@/pages/Channels'));


// --------------------------------------------------------------------------- ROUTER
const RouterChannels = {
  path: routesPath.CHANNELS_INTEGRATION,
  children: [
    createRoute(routesPath.CHANNELS_INTEGRATION, <ChannelsPage />),
  ],
};

export default RouterChannels;
