import { createRoute } from '@/helpers';
import React from 'react';
import { routesPath } from './constants';

const Metric = React.lazy(() => import('@/pages/Metric'));

// --------------------------------------------------------------------------- ROUTER
const RouterMetric = {
  path: routesPath.METRIC,
  children: [
    createRoute(routesPath.METRIC, <Metric siteId={3} />),
  ],
};

export default RouterMetric;
