import React from 'react';
import { Row, Col } from 'antd';
import { GTGSIcon } from '@/components/GTGS';
import { useNode } from '@craftjs/core';

type FooterCosmeticProps = {
  description?: string;
};

const FooterCosmetic = ({
  description = 'Build a modern and creative website with crealand',
}: FooterCosmeticProps) => {
  const {
    connectors: { connect, drag },
  } = useNode();

  return (
    <footer
      ref={(ref) => ref && connect(drag(ref))}
      className="bg-white py-20 px-5 md:px-20"
    >
      <div className="max-w-7xl mx-auto">
        <Row gutter={[20, 20]} className="text-center md:text-left">
          <Col xs={24} md={6}>
            <div className="flex flex-col items-center md:items-start space-y-6">
              <div className="text-3xl font-extrabold text-[#183B56] flex items-center">
                <img src="/images/logo/logo-template.svg" alt="logo" />
              </div>
              <p className="text-gray-600 text-sm">{description}</p>
              <div className="flex space-x-3 mt-4">
                <div className="p-3 bg-gray-200 rounded-full">
                  <GTGSIcon name="google" size={16} />
                </div>
                <div className="p-3 bg-gray-200 rounded-full">
                  <GTGSIcon name="instagram" size={16} />
                </div>
                <div className="p-3 bg-gray-200 rounded-full">
                  <GTGSIcon name="linkedin" size={16} />
                </div>
              </div>
            </div>
          </Col>

          <Col xs={24} md={18}>
            <Row gutter={[20, 20]}>
              <Col xs={12} md={6}>
                <h3 className="font-bold text-gray-800 text-lg">Product</h3>
                <ul className="space-y-2 mt-4 text-gray-600 text-sm">
                  <li>Landingpage</li>
                  <li>Features</li>
                  <li>Documentation</li>
                  <li>Referral Program</li>
                  <li>Pricing</li>
                </ul>
              </Col>
              <Col xs={12} md={6}>
                <h3 className="font-bold text-gray-800 text-lg">Services</h3>
                <ul className="space-y-2 mt-4 text-gray-600 text-sm">
                  <li>Documentation</li>
                  <li>Design</li>
                  <li>Themes</li>
                  <li>Illustrations</li>
                  <li>UI Kit</li>
                </ul>
              </Col>
              <Col xs={12} md={6}>
                <h3 className="font-bold text-gray-800 text-lg">Company</h3>
                <ul className="space-y-2 mt-4 text-gray-600 text-sm">
                  <li>About</li>
                  <li>Terms</li>
                  <li>Privacy Policy</li>
                  <li>Careers</li>
                </ul>
              </Col>
              <Col xs={12} md={6}>
                <h3 className="font-bold text-gray-800 text-lg">More</h3>
                <ul className="space-y-2 mt-4 text-gray-600 text-sm">
                  <li>Documentation</li>
                  <li>License</li>
                  <li>Changelog</li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>

        <div className="flex flex-col items-center mt-10 text-gray-600">
          <button className="bg-blue-500 p-3 rounded-full  mb-4">
            <GTGSIcon name="heart-2" size={24.5} />
          </button>
          <p className="text-sm">Copyright © 2024. Crafted by GTG.</p>
        </div>
      </div>
    </footer>
  );
};

const FooterCosmeticSettings = () => {
  const {
    actions: { setProp },
    description,
  } = useNode((node) => ({
    description: node.data.props.description,
  }));

  return (
    <div>
      <div className="mb-5">
        <label className="block mb-2 font-medium text-gray-700">
          Description
        </label>
        <input
          type="text"
          className="w-full px-3 py-2 border border-gray-300 rounded-md"
          value={description}
          onChange={(e) =>
            setProp((props) => (props.description = e.target.value))
          }
        />
      </div>
    </div>
  );
};
FooterCosmetic.defaultProps = {
  description: 'Build a modern and creative website with crealand',
};

FooterCosmetic.craft = {
  props: {
    description: 'Build a modern and creative website with crealand',
  },
  related: {
    settings: FooterCosmeticSettings,
  },
  displayName: 'FooterCosmetic',
};

export default FooterCosmetic;
