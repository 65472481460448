import React, { useEffect, useMemo, useState } from 'react';
import { Button, Steps, Modal, Switch, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { GTGSIcon } from '@/components/GTGS/GTGSIcon/GTGSIcon';
import {
  GTGSButton,
  GTGSDynamicTable,
  GTGSTypography,
} from '@/components/GTGS';
import styles from './style.module.scss';
import UserService from '@/services/UserService';
import config from '@/config';
import { connectAdAccounts, getAdAccounts } from '@/services/adsService';
import { useMutation } from 'react-query';

const { Text } = GTGSTypography;
const baseUrl = `${config.gatewayUrl}/v1`;

const AdAccountSetup = () => {
  const navigate = useNavigate();
  const token = useMemo(() => UserService.getToken(), []);
  const { Step } = Steps;

  const [selectedNetwork, setSelectedNetwork] = useState('facebook');
  const [currentStep, setCurrentStep] = useState(0);
  const [externalPopup, setExternalPopup] = useState<Window | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAdAccounts, setSelectedAdAccounts] = useState<any[]>([]);

  const descriptions = {
    facebook: {
      text: 'All Meta ads covered, including Facebook, Instagram, Messenger, and the Audience Network.',
      buttonText: 'SIGN INTO FACEBOOK ADS',
    },
    google: {
      text: 'Includes Google Search, Display, YouTube, and Google Shopping ads. Includes performance reports for Google Smart and Performance Max Campaigns. Contact tracking not included.',
      buttonText: 'SIGN INTO GOOGLE ADS',
    },
    linkedin: {
      text: 'Includes LinkedIn ads for professional networking.',
      buttonText: 'SIGN INTO LINKEDIN ADS',
    },
  };

  const columns = [
    {
      title: 'Ad Account',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Ad Account ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Auto Tracking',
      dataIndex: 'autoTracking',
      key: 'autoTracking',
      // TODO: consider remove any and specify type?
      render: (autoTracking: any, row: any) => (
        <Switch
          defaultChecked={autoTracking}
          onChange={(checked: boolean) =>
            handleAutoTrackingToggle(checked, row.id)
          }
        />
      ),
    },
  ];

  const handleAutoTrackingToggle = (checked, id) => {
    setSelectedAdAccounts((prevSelected = []) => {
      // Check if the account with the given ID already exists in selectedAdAccounts
      const exists = prevSelected.some((account) => account.id === id);

      // Update the existing entry's autoTracking or add a new entry if it doesn't exist
      if (exists) {
        return prevSelected.map((account) =>
          account.id === id ? { ...account, autoTracking: checked } : account
        );
      } else {
        // If the account doesn't exist, add it to the array
        return [...prevSelected, { id, autoTracking: checked }];
      }
    });
  };

  const handleNetworkClick = (network) => {
    setSelectedNetwork(network);
  };

  const handleSignInClick = () => {
    const oauthUrls = {
      facebook: `${baseUrl}/ads/facebook/oauth?token=${token}&workspaceId=${localStorage.getItem('workspaceId')}`,
      google: '', //TODO: Add Google OAuth URL
      linkedin: '', //TODO: Add LinkedIn OAuth URL
    };

    const selectedOauthUrl = oauthUrls[selectedNetwork];

    if (selectedOauthUrl) {
      const popup = window.open(
        selectedOauthUrl,
        '_blank',
        'width=800,height=800'
      );
      setExternalPopup(popup);
    } else {
      console.log(`${selectedNetwork} Ads sign-in selected`);
    }
  };

  useEffect(() => {
    if (!externalPopup) return;

    const timer = setInterval(() => {
      if (!externalPopup || externalPopup.closed) {
        clearInterval(timer);
        setExternalPopup(null);
        return;
      }

      // TODO: there's an issue where the redirect url on local machine will be pointed to prod env
      // which causes rendering page instead of close the external popup
      // so there's a workaround code here for already auth-ed account
      // will work with FE engineer to discuss and fix it later :bow

      // externalPopup.close();
      // setCurrentStep(1); // Example: activate the "Sync Leads" step
      // setExternalPopup(null);
      // clearInterval(timer);
      // setIsModalOpen(true);

      try {
        const currentUrl = externalPopup.location.href;
        if (!currentUrl) return;

        const searchParams = new URL(currentUrl).searchParams;
        const status = searchParams.get('status');

        if (status) {
          externalPopup.close();
          setCurrentStep(1); // Example: activate the "Sync Leads" step
          setExternalPopup(null);
          clearInterval(timer);
          setIsModalOpen(true);
        }
      } catch (error) {
        // Ignore cross-origin errors until we get to a URL under our control
      }
    }, 500);

    return () => clearInterval(timer);
  }, [externalPopup]);

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const { mutate: connectAccount } = useMutation(
    'connectAdAccounts',
    connectAdAccounts,
    {
      onSuccess: (data) => {
        message.success({
          content: 'connect account(s) successfully',
        });
        setIsModalOpen(false);
        setSelectedAdAccounts([]);
      },
    }
  );

  return (
    <div className="flex flex-col items-center w-full p-8 md:mt-10">
      <Button
        type="link"
        icon={<GTGSIcon name="arrow-left" />}
        className="self-start mb-4"
        onClick={() => navigate('/marketing/ads')}
      >
        Back
      </Button>
      <div className="flex justify-center w-full md:w-1/3 mt-10 ">
        <Steps
          labelPlacement="vertical"
          size="small"
          className={styles.customSteps}
          current={currentStep}
        >
          <Step title="Connect Account" />
          <Step title="Sync Leads" />
          <Step title="Add Pixels" />
        </Steps>
      </div>
      <h2 className="text-center mt-6 mb-2 font-bold text-2xl">
        Connect ad account
      </h2>
      <Text className="text-center text-gray-500 mt-1 mb-6">
        Choose an ad network
      </Text>

      <div className="flex justify-center gap-8 p-4 mb-4 mt-2">
        <button
          className={`w-[80px] h-[80px] rounded-full flex items-center justify-center transition-colors hover:bg-[#E6F4FF] ${
            selectedNetwork === 'facebook'
              ? 'bg-[#E6F4FF] border border-[#005CE6]'
              : 'bg-[#EFF1F3]'
          }`}
          onClick={() => handleNetworkClick('facebook')}
        >
          <GTGSIcon name="facebook-ads" size={40} />
        </button>
        <button
          className={`w-[80px] h-[80px] rounded-full flex items-center justify-center transition-colors hover:bg-[#E6F4FF] ${
            selectedNetwork === 'google'
              ? 'bg-[#E6F4FF] border border-[#005CE6]'
              : 'bg-[#EFF1F3]'
          }`}
          onClick={() => handleNetworkClick('google')}
        >
          <GTGSIcon name="google-ads" size={70} />
        </button>
        <button
          className={`w-[80px] h-[80px] rounded-full flex items-center justify-center transition-colors hover:bg-[#E6F4FF] ${
            selectedNetwork === 'linkedin'
              ? 'bg-[#E6F4FF] border border-[#005CE6]'
              : 'bg-[#EFF1F3]'
          }`}
          onClick={() => handleNetworkClick('linkedin')}
        >
          <GTGSIcon name="linkedin-ads" size={40} />
        </button>
      </div>

      <div className="bg-[#E6F4FF] p-4 rounded-md text-center max-w-lg mb-6">
        <Text className="text-gray-600 text-sm">
          {descriptions[selectedNetwork].text}
        </Text>
      </div>

      <GTGSButton
        type="primary"
        className="bg-blue-600 hover:bg-blue-700 px-6 mt-4"
        onClick={handleSignInClick}
      >
        {descriptions[selectedNetwork].buttonText}
      </GTGSButton>

      <Modal
        title="Select Facebook Ad Accounts"
        open={isModalOpen}
        onCancel={handleModalClose}
        width={900}
        footer={[
          <Button key="cancel" onClick={handleModalClose}>
            Cancel
          </Button>,
          <Button
            key="connect"
            type="primary"
            onClick={() => {
              const payload = selectedAdAccounts.map((account) => ({
                platform: 'FACEBOOK',
                adAccountId: account.id,
                isAutoTracking: account.autoTracking,
              }));
              connectAccount({ connectAdAccounts: payload });
            }}
          >
            Connect Account
          </Button>,
        ]}
      >
        <p>Select the below account(s) you want to connect to GTGCRM.</p>
        <p>
          You can connect up to <b>3</b> ad accounts.
        </p>

        <GTGSDynamicTable
          filters={{}}
          rowKey="id"
          columns={columns}
          fnKey={'getAdAccounts'}
          fnQuery={getAdAccounts}
          pagination={false}
          rowSelection={{
            type: 'checkbox',
            onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
              const updatedSelectedAdAccounts = selectedRows.map((row) => {
                const existing = selectedAdAccounts.find(
                  (account) => account.id === row.id
                );

                return {
                  id: row.id,
                  name: row.name,
                  autoTracking: existing
                    ? existing.autoTracking
                    : row.autoTracking,
                };
              });

              setSelectedAdAccounts(updatedSelectedAdAccounts);
            },
          }}
        />

        <a
          href="#"
          style={{ display: 'block', marginTop: '10px', color: '#005CE6' }}
        >
          Create new ad account
        </a>
      </Modal>
    </div>
  );
};

export default AdAccountSetup;
