import { createRoute } from '@/helpers';
import { lazy } from 'react';
import { routesPath } from './constants';

const EmailBuilder = lazy(() => import('@/pages/EmailBuilder'));
const EmailAutomatedTemplate = lazy(() => import('@/pages/EmailBuilder/container/AutomatedTemplates'));

// --------------------------------------------------------------------------- ROUTER
const RouterEmailBuilder = {
  path: routesPath.EMAIL_BUILDER,
  children: [
    createRoute(routesPath.EMAIL_BUILDER, <EmailBuilder />),
    createRoute(routesPath.EMAIL_BUILDER_AUTOMATED_TEMPLATES, <EmailAutomatedTemplate />),
  ],
};

export default RouterEmailBuilder;
