import { TabsProps as AntdTabsProps, Badge, Flex } from 'antd';
import { CSSProperties, ReactNode, memo } from 'react';
import isNil from 'lodash/isNil';
import { TabsStyle } from './styles';
import { IconName } from '../GTGSIcon/GTGSIcon.types';
import { GTGSIcon } from '../GTGSIcon/GTGSIcon';

interface GTGSTabsProps extends AntdTabsProps {
  buttonType?: boolean;
}

interface CustomLabelProps {
  label: ReactNode;
  number?: number;
  style?: CSSProperties;
  iconName?: IconName;
}

const CustomLabel = ({ iconName, label, number, style }: CustomLabelProps) => {
  return (
    <Flex align="center" gap={4} justify="center" style={{ ...style }}>
      {iconName && <GTGSIcon name={iconName} size="small" />}
      {label}
      {!isNil(number) && <Badge count={number} size="small" />}
    </Flex>
  );
};

//==================  MAIN =================
function _GTGSTabs(props: GTGSTabsProps) {
  const { buttonType = false } = props;
  return <TabsStyle buttonType={buttonType} {...props} />;
}

const GTGSTabs = memo(_GTGSTabs);

export { GTGSTabs, type GTGSTabsProps, CustomLabel, type CustomLabelProps };
