import common from './lang/common.json';
import pageNotFound from './lang/pageNotFound.json';
import pageGuideline from './lang/pageGuideline.json';
import validator from './lang/validator.json';
import pageDashboard from './lang/pageDashboard.json';
import header from './lang/header.json';
import companyProfile from './lang/companyProfile.json';
import notificationSetting from './lang/notificationSetting.json';
import pageCRM from './lang/pageCRM.json';

export default {
  ...common,
  ...pageNotFound,
  ...pageGuideline,
  ...validator,
  ...pageDashboard,
  ...header,
  ...companyProfile,
  ...notificationSetting,
  ...pageCRM,
};
