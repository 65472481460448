import { create } from 'zustand';

interface PageBuilderState {
  device: 'desktop' | 'tablet' | 'mobile';
  setDevice: (device: 'desktop' | 'mobile') => void;
  formSettingVisible: boolean;
  setFormSettingVisible: (visible: boolean) => void;
}

export const usePageBuilder = create<PageBuilderState>((set, get) => ({
  device: 'desktop',
  setDevice: (device) => set({ device }),
  formSettingVisible: false,
  setFormSettingVisible: (visible) => set({ formSettingVisible: visible }),
}));
