import { memo } from 'react';
import { GTGSIcon } from '../GTGSIcon/GTGSIcon';

const iconStyle: React.CSSProperties = {
  fontSize: 80,
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  color: '#fff',
};

const icon = {
  transaction: (props: any) => <GTGSIcon name="files" {...props} />,
  card: (props: any) => <GTGSIcon name="credit-card-new" {...props} />,
  search: (props: any) => <GTGSIcon name="magnifying-glass" {...props} />,
  account: (props: any) => <GTGSIcon name="wallet" {...props} />,
};
type GTGSEmptyBoxProps = {
  size?: number;
  type: keyof typeof icon;
};
// TODO
const _GTGSEmptyBox = (props: GTGSEmptyBoxProps) => {
  const { type, size = 160 } = props;
  const Icon = icon[type];
  return (
    <div style={{ position: 'relative' }}>
      <GTGSIcon name="empty-pattern"  size={size}/>
      <Icon style={{ ...iconStyle, fontSize: size / 2 }} />
    </div>
  );
};

const GTGSEmptyBox = memo(_GTGSEmptyBox);

export { GTGSEmptyBox, type GTGSEmptyBoxProps };
