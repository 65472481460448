import { Tooltip } from 'antd';
import { TooltipProps } from 'antd/lib';
import React, { useMemo } from 'react';

interface GTGSTooltipProps extends Omit<TooltipProps, "color"> {
  color?: "success" | "gray" | "error" | "black" | "default" | string;
  children: React.ReactNode;
}

const GTGSTooltip: React.FC<GTGSTooltipProps> = ({
  title,
  color = "default",
  placement,
  children,
}) => {

  const bgColor = useMemo(()=> {
    switch (color) {
      case "success":
        return "#15803D";
      case "gray":
        return "#6B7280";
      case "error":
        return "#B91C1C";
      case "black":
        return "#111827";
      case "default":
        return "#ffffff";
      default:
        return color;
    }
  }, [color]); 
  

  return (
    <Tooltip
      title={typeof title === "string" ? <span style={{ color: color === "default" ? "#000000": "#ffffff" }}>{title}</span>: title}
      placement={placement}
      color={bgColor}
    >
      {children}
    </Tooltip>
  );
};

export default GTGSTooltip;
