import { getLandingPageState } from '@/services/pageBuilderService';
import { Resolver, useEditor } from '@craftjs/core';
import lz from 'lzutf8';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import cosmetics from '@/pages/PageBuilder/data/gtg_cosmetics.json';
import crm from '@/pages/PageBuilder/data/gtg_crm_template.json';

const LoadPreviewPage = () => {
  const { id } = useParams();
  const { data } = useQuery(['getLatestState'], () =>
    getLandingPageState(id || '', 'latest')
  );
  // const { data } = useQuery(
  //   ['getLatestState'],
  //   () => getLatestState('2115795f-f1f2-4c3d-b412-cf8ccd581696'),
  //   { cacheTime: 0, staleTime: 0, refetchOnWindowFocus: true }
  // );

  const { actions } = useEditor();

  useEffect(() => {
    if (data) {
      if (data?.dataChanged) {
        const json = lz.decompress(lz.decodeBase64(data.dataChanged));
        console.log({ json });
        actions.deserialize(json);
      } else {
        if (data?.templateCode === 'gtg_cosmetics') {
          actions.deserialize(JSON.stringify(cosmetics));
        }
        if (data?.templateCode === 'gtg_crm_template') {
          actions.deserialize(JSON.stringify(crm));
        }
        // navigate('templates');
      }
    }
  }, [data, actions]);

  return null;
};

export default LoadPreviewPage;
