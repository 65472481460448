import { useNode } from '@craftjs/core';
import { Text as EmailText, Heading } from '@react-email/components';

import { PropsWithChildren } from 'react';

type ITextProps = {
  headingLevel?: string;
};

export const Text = (props: PropsWithChildren<ITextProps>) => {
  const { children, headingLevel } = props;
  const {
    connectors: { connect, drag },
  } = useNode();

  if (headingLevel) {
    return (
      <Heading
        as={headingLevel as any}
        ref={(ref) => ref && connect(ref!)}
      >
        {children}
      </Heading>
    );
  }

  return (
    <EmailText ref={(ref) => ref && connect(ref!)}>{children}</EmailText>
  );
};
export const TextSettings = () => {};

export const TextDefaultProps = {
  background: '#ffffff',
};

Text.craft = {
  props: TextDefaultProps,
  related: {
    settings: TextSettings,
  },
};
