import { SHORT_DATE_FORMAT } from '@/constants/date-time';
import dayjs from '@/helpers/dayjsHelpers';
import type { DatePickerProps } from 'antd';
import { DatePicker } from 'antd';
import classNames from 'classnames';
import type { Dayjs } from 'dayjs';
import { memo, useCallback } from 'react';
import styles from './GTGSCalendar.module.scss';
import { GTGSIcon } from '../GTGSIcon/GTGSIcon';

type GTGSCalendarProps = DatePickerProps;

function _GTGSCalendar(props: GTGSCalendarProps) {
  const { onChange, format = SHORT_DATE_FORMAT, disabledDate,className, ...rest } = props;

  const minDate = dayjs('01/01/1900').add(0, 'day');

  const onBlur = useCallback(
    (elem: React.FocusEvent<HTMLElement>) => {
      const valueNew = (elem?.target as HTMLInputElement)?.value;
      if (valueNew) {
        const value = dayjs(valueNew, format?.toString());
        if (value && value?.isValid() && !disabledDate?.(value, { type: 'date', from: value })) {
          onChange?.(value, valueNew);
        }
      }
    },
    [disabledDate, format, onChange]
  );

  const onChangeDisabledDate = useCallback(
    (date: Dayjs) => {
      if (date === null) {
        return false;
      }

      const check =
        minDate !== null &&
        date.isBefore(minDate) &&
        !date.isSame(minDate, 'day');
      if (check) return true;
      return disabledDate?.(date, { type: 'date', from: date }) ?? false;
    },
    [disabledDate, minDate]
  );

  return (
    <DatePicker
      size="large"
      className={classNames(styles.calendar, className)}
      suffixIcon={<GTGSIcon name="calendar" size={20}/> }
      onBlur={onBlur}
      onChange={onChange}
      disabledDate={onChangeDisabledDate}
      format={format}
      {...rest}
    />
  );
}

const GTGSCalendar = memo(_GTGSCalendar);

export { GTGSCalendar, type GTGSCalendarProps };

