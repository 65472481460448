import { GTGSButton } from '@/components/GTGS/GTGSButton/GTGSButton';
import { Flex } from 'antd';
import { FC, useMemo } from 'react';

interface IActionsFootersProps {
  onClose?: () => void;
  onConfirm?: () => void;
  title?: string;
  subTitle?: string;
  isVertical?: boolean;
  status?: 'success' | 'error';
  align?: 'center' | 'flex-end';
  order?: number[];
  titleButtonLeft?: string;
  titleButtonRight?: string;
  confirmLoading?: boolean;
}

const ActionsFooters: FC<IActionsFootersProps> = ({
  isVertical = false,
  onConfirm,
  onClose,
  status,
  align = 'center',
  titleButtonRight = 'Confirm',
  titleButtonLeft = 'Cancel',
  confirmLoading = false,
}: IActionsFootersProps) => {
  const renderActions = useMemo(() => {
    if (align === 'center' && typeof onClose === 'function') {
      return (
        <Flex
          gap={8}
          align={'center'}
          justify={'center'}
          style={{ width: '100%' }}
          vertical={isVertical}
        >
          <GTGSButton
            style={{ width: isVertical ? "100%" : '50%', textTransform: 'uppercase' }}
            onClick={onClose}
          >
            {titleButtonLeft}
          </GTGSButton>
          <GTGSButton
            loading={confirmLoading}
            style={{ width: isVertical ? "100%" : '50%', textTransform: 'uppercase' }}
            onClick={onConfirm}
            type="primary"
            danger={status === 'error'}
          >
            {titleButtonRight}
          </GTGSButton>
        </Flex>
      );
    }

    if (align === 'center' && typeof onClose !== 'function') {
      return (
        <Flex
          gap={8}
          align={'center'}
          justify={'center'}
          style={{ width: '100%' }}
          vertical={isVertical}
        >
          <GTGSButton
            style={{ width: '100%', textTransform: 'uppercase' }}
            onClick={onConfirm}
            type="primary"
            danger={status === 'error'}
          >
            {titleButtonRight}
          </GTGSButton>
        </Flex>
      );
    }

    return (
      <Flex
        gap={8}
        align={'center'}
        justify={'flex-end'}
        style={{ width: '100%' }}
      >
        <GTGSButton
          style={{ minWidth: '144px', textTransform: 'uppercase' }}
          onClick={onClose}
        >
          {titleButtonLeft}
        </GTGSButton>
        <GTGSButton
          style={{ minWidth: '144px', textTransform: 'uppercase' }}
          onClick={onConfirm}
          type="primary"
          danger={status === 'error'}
        >
          {titleButtonRight}
        </GTGSButton>
      </Flex>
    );
  }, [align, confirmLoading, isVertical, onClose, onConfirm, status, titleButtonLeft, titleButtonRight]);

  return <Flex style={{ padding: "32px 0 8px" }}>{renderActions}</Flex>;
};
export { ActionsFooters, type IActionsFootersProps };
