import { formatFieldName } from '@/pages/CRM/helpers/stringHelper';
import useJsonSchema from '@/pages/PageBuilder/store/useJsonSchema';
import { useEditor, useNode } from '@craftjs/core';
import { Select } from 'antd';

const SchemaProperties = ({ ...props }) => {
  const {
    requiredProperties,
    inputProperties,
    dateProperties,
    arrayTypeProperties,
    optionProperties,
    properties,
  } = useJsonSchema();

  const { node } = useNode((node) => ({
    node: node,
  }));

  console.log({ node });

  const { nodes } = useEditor((state, query) => {
    return {
      nodes: state.nodes,
    };
  });

  // Query all form nodes from Editor
  const formNodes = Object.values(nodes).filter((n) =>
    n.data.displayName.includes('GTGFORM')
  );
  const existingFields = formNodes.map((node) => node.data.props.name);

  const optionsByName = {
    FormCheckbox: arrayTypeProperties,
    FormInput: inputProperties,
    FormTextArea: inputProperties,
    FormDatePicker: dateProperties,
    FormSelect: optionProperties,
    FormRadio: optionProperties,
  };

  const options = optionsByName[node?.data?.name] || properties;

  return (
    <Select
      {...props}
      className="w-full h-10"
      showSearch
      options={options?.map(({ key }) => {
        return {
          value: key,
          label: formatFieldName(key),
          disabled:
            node?.data?.props?.name !== key &&
            (requiredProperties?.includes(key) || existingFields.includes(key)),
        };
      })}
    />
  );
};

export default SchemaProperties;
