import React from 'react';
import { Card, Tooltip, Typography, Avatar } from 'antd';
import {
  InfoCircleOutlined,
  PictureOutlined,
  UserOutlined,
} from '@ant-design/icons';

const { Title } = Typography;

interface PreviewSectionProps {
  status: 'audience' | 'preview' | 'both';
  estimatedAudienceSize?: number;
  previewData?: {
    bodyCopy: string;
    headline: string;
    selectedFacebookPage: string;
  };
  learnMoreLink?: string; // New prop for the Learn More link
}

const PreviewSection: React.FC<PreviewSectionProps> = ({
  status,
  estimatedAudienceSize,
  previewData,
  learnMoreLink,
}) => {
  return (
    <div className="w-full lg:w-1/2 bg-gray-100 flex flex-col items-center justify-start gap-6 p-8">
      {(status === 'audience' || status === 'both') && (
        <Card className="w-[90%] md:w-2/3 shadow-md rounded-md">
          <div className="text-center">
            <Title level={5} className="flex items-center justify-center gap-2">
              Estimated audience size
              <Tooltip title="Estimated audience size based on selected criteria">
                <InfoCircleOutlined />
              </Tooltip>
            </Title>
            <Title level={2}>
              {estimatedAudienceSize?.toLocaleString()} people
            </Title>
          </div>
        </Card>
      )}

      {(status === 'preview' || status === 'both') && (
        <Card className="w-[90%] md:w-2/3 shadow-md rounded-md">
          <div className="bg-white rounded-md p-4">
            <div className="flex items-center mb-2">
              <Avatar size={40} icon={<UserOutlined />} className="mr-2" />
              <div>
                <div className="text-gray-500">
                  {previewData?.selectedFacebookPage || 'Select a page'}
                </div>
                <div className="text-sm text-gray-500">Sponsored</div>
              </div>
            </div>
            <div className="text-lg font-semibold my-2">
              {previewData?.bodyCopy ||
                "Write a message that clearly tells people about what you're promoting"}
            </div>
            <div className="w-full h-48 bg-gray-200 flex items-center justify-around rounded-md mb-4">
              <PictureOutlined style={{ fontSize: '2rem', color: '#aaa' }} />
            </div>
            <div className="flex items-center justify-between">
              <div className="text-base font-medium w-3/4 mr-2 md:mr-4">
                {previewData?.headline ||
                  'Write a clear and concise headline to capture viewers attention'}
              </div>
              <div>
                {learnMoreLink && (
                  <a
                    href={learnMoreLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button className="ml-auto bg-blue-500 text-white px-4 py-2 rounded">
                      Learn More
                    </button>
                  </a>
                )}
              </div>
            </div>
          </div>
        </Card>
      )}
    </div>
  );
};

export default PreviewSection;
