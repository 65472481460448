import { Tailwind } from '@react-email/components';
import { forwardRef, PropsWithChildren } from 'react';

type IDropContentProps = {
  selected?: boolean;
};

export const DropContent = forwardRef<
  HTMLDivElement,
  PropsWithChildren<IDropContentProps>
>((props, ref) => {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <div
        ref={ref}
        style={{
          display: 'flex',
          padding: '10px',
          borderStyle: 'dashed',
          borderWidth: '1px',
          height: '42px',
          borderRadius: '0.5rem',
          borderColor: 'rgb(220, 222, 228)',
          alignItems: 'center',
          backgroundColor: 'rgb(246, 248, 250)',
        }}
      >
        Drop content here
      </div>
    </div>
  );
});
