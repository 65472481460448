import { useNode } from '@craftjs/core';
import { Form } from 'antd';
import InputSettings from './settings/InputSetting';
import { GTGSPhoneNumber } from '@/components/GTGS';

type FormInputProps = {
  name?: string;
  label: string;
  placeholder: string;
  minLength: number;
  maxLength: number;
  isRequired?: boolean;
  pattern?: RegExp;
  format?: string;
};

const formatMapping = {
  email: 'email',
  'date-time': 'date',
};

const FormInput = ({
  name,
  label,
  placeholder,
  minLength,
  maxLength,
  isRequired,
  pattern,
  format,
  ...props
}: FormInputProps) => {
  const {
    connectors: { connect, drag },
    id,
  } = useNode();

  return (
    <div
      ref={connect as React.LegacyRef<HTMLDivElement>}
      className="w-full"
      data-form="true"
    >
      <Form.Item
        name={name}
        label={label}
        rules={[
          {
            type: formatMapping[format as any],
            message: `The input is not valid ${formatMapping[format as any]}`,
          },
          {
            required: isRequired,
            message: 'This field is required!',
          },
          {
            min: minLength,
            message: `Minimum length is ${minLength} characters!`,
          },
          {
            max: maxLength,
            message: `Maximum length is ${maxLength} characters!`,
          },
          {
            pattern,
            message: 'Invalid input!',
          },
        ]}
      >
        {name === 'phone_numbers' ? (
          <GTGSPhoneNumber
            inputProps={{ id: name, ...props }}
            placeholder={placeholder}
            country={'vn'}
          />
        ) : (
          <input
            {...props}
            placeholder={placeholder}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          />
        )}
      </Form.Item>
    </div>
  );
};

FormInput.defaultProps = {
  label: 'Label',
  placeholder: 'Placeholder',
  minLength: 0,
  maxLength: 255,
  isRequired: false,
  pattern: undefined,
  format: undefined,
};

FormInput.craft = {
  related: {
    settings: InputSettings,
  },
  displayName: 'GTGFORMInput',
};

export default FormInput;
