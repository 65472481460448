import { IMessage } from "@/pages/Messaging/types";

export const customMessages = (messages: IMessage[]) => {
    return (messages??[]).reduce((acc:IMessage[], curr:IMessage) => {
      const lastItem = acc[acc?.length - 1] as IMessage;
      if (lastItem && lastItem?.sendFrom === curr.sendFrom) {
        lastItem?.groupMessage?.push({
          content: curr.messageContent,
          type: curr.messageType,
          time: curr.createdAt,
          id: curr.messageId,
        });
      } else {
        acc.push({ ...curr });
      }
      return acc;
    }, []);
  };
  
  export const handleScrollMessage = (element: any, isBlock?: boolean, isTop?: boolean ) => {
    let elementScroll;
    if(!element) return;
    if (typeof element === 'string') {
    const ID_ELEMENT = element;
     elementScroll = document.getElementById(ID_ELEMENT);
    } else {
      elementScroll = element;
    }
    if (elementScroll) {
      if (!isBlock) {
        elementScroll.scrollTop = isTop ? 0 : elementScroll.scrollHeight;
        elementScroll.scrollIntoView({ block: 'end', behavior: 'smooth' });
      } else {
      elementScroll.scrollTo({ top: isTop ? 0 :  elementScroll.scrollHeight, behavior: 'smooth' });
      }
    }
  };
  