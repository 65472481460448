import { ENotifyErrorType } from '@/types/logError';
import config from '../config';
import httpService from './httpService';
import UserService from './UserService';

type TDevice = {
  userId: string;
  vendor: string;
  model: string;
  friendlyName: string;
  platform: string;
  imei: string;
  deviceToken: string;
  registrationId: string;
  endpointArn: string;
  publicKey: string;
};

type TEnrollDevice = {
  userId: string;
  token?: string;
};

const baseUrl = `${config.gatewayUrl}/v1`;

export const fetchDevices = async () => {
  const { data } = await httpService.get(`${baseUrl}/api/users/me/devices`, {
    params: {
      _notifyErrorType: ENotifyErrorType.NONE,
    },
  });
  return data;
};

export const removeDeviceById = async (deviceId) => {
  const { data } = await httpService.delete(
    `${baseUrl}/api/users/me/devices/${deviceId}/confirmation`
  );
  return data;
};

export const enrollDevice = async (device: TEnrollDevice) => {
  const { data } = await httpService.post(`${baseUrl}/device-token`, {
    ...device,
  });
  return data;
};

export const sendMockNotification = async () => {
  const { data } = await httpService.post(`${baseUrl}/notification`, {
    userId: UserService.getUserInfo().id,
    message:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
  });
  return data;
};

const deviceService = {
  fetchDevices,
  removeDeviceById,
  enrollDevice,
  sendMockNotification,
};

export default deviceService;
