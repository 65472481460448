import { useEffect, useMemo } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'quill-mention';
import 'quill-mention/dist/quill.mention.css';
import  styles from './GTGSEditor.module.scss';
import classNames from 'classnames';

export interface GTGSEditorProps extends React.ComponentProps<typeof ReactQuill> {
  value: string;
  onChange: (value: string) => void;
  isToolbar?: boolean;
  users?: { id: number; value: string }[];
  tags?: { id: number; value: string }[];
  className?: string;
  hasMinHeight?: boolean;
}

function GTGSEditor({ value, onChange, isToolbar = true, users, tags, className,hasMinHeight, ...rest }: GTGSEditorProps) {
  const modules = useMemo(
    () => ({
      toolbar: isToolbar
        ? [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            ['link', 'image'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ color: [] }],
            ['clean'],
          ]
        : false,
      mention: {
        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
        mentionDenotationChars: ['@', '#'],
        source: function (searchTerm, renderList, mentionChar) {
          let values;

          if (mentionChar === '@') {
            values = [...users ?? []];
          } 
          else {
            values = [
              ...tags ?? []
            ];
          }

          if (searchTerm?.length === 0) {
            renderList(values, searchTerm);
          } else {
            const matches = values.filter((item) =>
              item.value.toLowerCase()?.includes(searchTerm?.toLowerCase())
            );
            renderList(matches, searchTerm);
          }
        },
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isToolbar, JSON.stringify(tags), JSON.stringify(users)]
  );

  return (
    <ReactQuill
      {...rest}
      id='editor'
      key={isToolbar ? 'toolbar' : 'no-toolbar'}
      theme="snow"
      className={classNames(styles.editor, {[styles.minHeight]: !!hasMinHeight}, className)}
      value={value}
      onChange={onChange}
      modules={modules}
    />
  );
}

export default GTGSEditor;
