import Userinfo from '@/components/UserInfo';
import { Button, Flex, theme } from 'antd';
import { ReactNode, memo, useEffect, useMemo } from 'react';

import GlobalSearch from '@/components/GlobalSearch';
import { SIDER_COLLAPSIBLE_WIDTH, SIDER_WIDTH } from '@/components/Layout/LeftSider';
import { useResponsive } from '@/hooks';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Header } from 'antd/es/layout/layout';
import { GTGSIcon } from '../GTGSIcon/GTGSIcon';
import { useQuery } from 'react-query';
import { getWorkspaces } from '@/services/workspacesService';
import { RequestStatus } from '@/pages/WorkSpace/types';
import useGetWorkspacesQuery from '@/hooks/useGetWorkspacesQuery';

interface GTGSPageHeaderProps {
  hasRight?: boolean;
  className?: string;
  children?: ReactNode;
  setCollapsed: (collapsed: boolean) => void;
  collapsed: boolean
}
// ===========================================================================
// MAIN
// ===========================================================================
function _GTGSPageHeader(props: GTGSPageHeaderProps) {
  const { setCollapsed, collapsed  } = props;
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const { md } = useResponsive();

  const { data: workspaces, isLoading, isFetching } = useGetWorkspacesQuery({ page: 1, size: 25, sortType: 'ASC', reqStatuses: ['OWNER','MEMBER'] });
  
  // --------------------------------------------------------------------------- render
  return (
    <>
      <Header
        style={{
          padding: '0 24px 0 0',
          background: "#0F172A",
          display: 'flex',
          alignItems: 'center',
          borderBottom: '1px solid #E5E7EB33',
          position: 'fixed',
          zIndex:1000,
          height: 80,
          width: window?.innerWidth <= 766 ?  "100%" : `calc(100% - ${collapsed ? SIDER_COLLAPSIBLE_WIDTH : SIDER_WIDTH}px )`
        }}
      >
        <Flex flex={1} align="center" >
          <Flex align="center">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                height:"100%"
              }}
              id="btnTriggerMenu"
            >
              <Button
                size="large"
                type="text"
                onClick={() => setCollapsed(!collapsed)}
                icon={!collapsed ? <GTGSIcon name="align-left" size={24} fill='#fff'/> : <GTGSIcon name="arrow-right-circle" size={24} fill='#fff'  /> }
              />
            </div>
          </Flex>
          <Flex
            justify="flex-end"
            flex={1}
            align="center"
            style={{ height: '100%' }}
            gap={16}
          >
            {/* <GlobalSearch/> */}
            <Userinfo status="workspace" workspaces={workspaces || []} isLoading={isLoading || isFetching} />
          </Flex>
        </Flex>
      </Header>
    </>
  );
}

export const GTGSPageHeader = memo(_GTGSPageHeader);
