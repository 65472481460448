import { createRoute } from '@/helpers';
import React from 'react';
import { routesPath } from './constants';

const CasesPage = React.lazy(() => import('@/pages/Cases'));
const MessagingTemplatesPage = React.lazy(() => import('@/pages/Library/pages/MessagingTemplates'));
const StaffPage = React.lazy(() => import('@/pages/Library/pages/Staff'));
const EditStaffPage = React.lazy(() => import('@/pages/Library/pages/Staff/EditStaff'));


// --------------------------------------------------------------------------- ROUTER
const RouterLibrary = {
  path: routesPath.LIBRARY,
  children: [
    createRoute(routesPath.MESSAGING_TEMPLATES, <MessagingTemplatesPage />),
    createRoute(routesPath.TAG_AND_WORKSPACE, <CasesPage />),
    createRoute(routesPath.STAFF, <StaffPage />),
    createRoute(routesPath.STAFF + '/edit/:staffId', <EditStaffPage />),
  ],
};

export default RouterLibrary;
