import { Editor, Element, Frame, useNode, Resolver } from '@craftjs/core';
import React from 'react';
import { HeaderSetting } from './components/HeaderSetting';
import Iframe from './components/Iframe'; // Import the IFrame component
import { SettingsPanel } from './components/SettingsPanel';
import CTA from './themes/theme-1/components/CTA';
import FAQ from './themes/theme-1/components/FAQ';
import FeatureSection from './themes/theme-1/components/FeatureSection';
import Footer from './themes/theme-1/components/Footer';
import Header from './themes/theme-1/components/Header';
import FactSection from './themes/theme-2/components/FactSection';
import StorySection from './themes/theme-2/components/StorySection';
import HeroSection from './themes/theme-1/components/HeroSection';
import Pricing from './themes/theme-1/components/Pricing';
import Testimonial from './themes/theme-1/components/Testimonial';

import { getLandingPageState } from '@/services/pageBuilderService';
import GTGCosmeticsHeader from './themes/theme-2/components/Header';
import { Layout } from 'antd';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';
import FormButton from './components/form-builder/FormButton';
import FormInput from './components/form-builder/FormInput';
import FormSelect from './components/form-builder/FormSelect';
import FormTextArea from './components/form-builder/FormTextArea';
import InitPageContent from './components/InitPageContent';
import LoadPage from './components/LoadPage';
import { RenderNode } from './components/RenderNode';
import { usePageBuilder } from './store/usePageBuilderStore';
import TopBanner from './themes/theme-1/components/TopBanner';
import FormRadio from './components/form-builder/FormRadio';
import FormCheckbox from './components/form-builder/FormCheckbox';
import FormDatePicker from './components/form-builder/FormDatePicker';
import CosmeticsStatisticsSection from '@/pages/PageBuilder/themes/theme-2/components/CosmeticsStatisticsSection';
import FAQCosmetic from './themes/theme-2/components/FAQCosmetic';
import ProductShowcase from './themes/theme-2/components/ProductShowcase';
import RecommendationSection from './themes/theme-2/components/RecommendationSection';
import TestimonialCosmetic from './themes/theme-2/components/TestimonialCosmetic';
import FooterCosmetic from './themes/theme-2/components/Footer';
const { Header: AntdHeader, Sider, Content } = Layout;

const siderStyle: React.CSSProperties = {
  overflow: 'auto',
  height: '100%',
  position: 'fixed',
  insetInlineStart: 0,
  top: 80,
  bottom: 0,
  scrollbarWidth: 'thin',
  scrollbarColor: 'unset',
  width: '100%',
  padding: 16,
  paddingTop: 0,
};

export const Container = ({ children }) => {
  return <div className="h-full">{children}</div>;
};

Container.craft = {
  displayName: 'Container',
  rules: {
    canMoveIn: (incomingNodes) =>
      incomingNodes.every(
        (incomingNode) => !incomingNode.data.displayName.includes('GTGFORM')
      ),
  },
};

export const FormBox = ({ children }) => {
  const {
    connectors: { connect, drag },
  } = useNode();
  return (
    <div
      className="grid gap-x-6 mb-6 sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-2"
      ref={(ref) => ref && connect(ref)}
    >
      {children}
    </div>
  );
};

FormBox.craft = {
  displayName: 'FormBox Container',
  rules: {
    canMoveIn: (incomingNodes) =>
      incomingNodes.every((incomingNode) =>
        incomingNode.data.displayName.includes('GTGFORM')
      ),
  },
};

export const gtgCosmeticsResolver = {
  GTGCosmeticsHeader,
  CosmeticsStatisticsSection,
  FactSection,
  StorySection,
  FAQCosmetic,
  ProductShowcase,
  FooterCosmetic,
  RecommendationSection,
  TestimonialCosmetic,
  LoadPage,
  Container,
  InitPageContent,
  Iframe,
};

export const gtgCrmResolver = {
  HeroSection,
  Header,
  CTA,
  FeatureSection,
  Testimonial,
  FAQ,
  Pricing,
  Footer,
  TopBanner,
  Container,
  LoadPage,
  InitPageContent,
  Iframe,
  FormInput,
  FormButton,
  FormSelect,
  FormBox,
  // DemoForm,
  FormTextArea,
  FormRadio,
  FormCheckbox,
  FormDatePicker,
};

const PageBuilder: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data } = useQuery(['getLatestState'], () =>
    getLandingPageState(id || '', 'latest')
  );

  if (data && !data?.dataChanged) {
    navigate('..' + '/select-template', { relative: 'path' });
  }

  const { device } = usePageBuilder(
    useShallow((state) => ({
      device: state.device,
    }))
  );

  const getResolver = (): Resolver => {
    if (data?.templateCode === 'gtg_cosmetics') {
      return gtgCosmeticsResolver;
    }

    return gtgCrmResolver;
  };

  return !data?.dataChanged ? null : (
    <div className="min-h-screen relative">
      <Editor resolver={getResolver()} onRender={RenderNode}>
        <Layout hasSider>
          <AntdHeader
            style={{
              position: 'fixed',
              width: '100%',
              height: '70px',
              top: 0,
              zIndex: 100,
              left: 0,
            }}
          >
            <HeaderSetting />
          </AntdHeader>
          <Layout hasSider style={{ height: '100%' }}>
            <Sider style={siderStyle} width={320}>
              <SettingsPanel />
            </Sider>
            {/* <AppTest /> */}
            <Layout
              style={{
                marginInlineStart: 320,
                height: '100%',
                padding: '70px 24px 0px 0px',
              }}
              className="craftjs-renderer"
            >
              <Content
                style={{
                  width: device === 'desktop' ? '100%' : '430px',
                  height: 'calc(100vh - 70px)',
                  margin: 'auto',
                  transition: 'width 0.3s ease-in-out',
                  position: 'relative',
                }}
              >
                <Iframe>
                  <Frame>
                    <Element canvas id="root" is={Container}>
                      <InitPageContent />
                      {/* <TopBanner />
                      <CTA />
                      <FeatureSection />
                      <Testimonial />
                      <FAQ />
                      <Footer /> */}
                    </Element>
                  </Frame>
                </Iframe>
              </Content>
            </Layout>
          </Layout>
        </Layout>
      </Editor>
    </div>
  );
};

export default PageBuilder;
