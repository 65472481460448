import { createRoute } from '@/helpers';
import React from 'react';
import { routesPath } from './constants';

const WorkSpace = React.lazy(() => import('@/pages/WorkSpace'));
const InvoiceDetail = React.lazy(() => import('@/pages/WorkSpace/components/Billing/InvoiceDetail'));

const RouterWorkSpace = {
  path: routesPath.WORKSPACE,
  children: [
    createRoute(routesPath.WORKSPACE, <WorkSpace type='workspace' />),
    createRoute(routesPath.WORKSPACE + "/workspace", <WorkSpace type='workspace' />),
    createRoute(routesPath.WORKSPACE + "/workspace/set-up/:workspaceId", <WorkSpace type='workspace' />),
    createRoute(routesPath.WORKSPACE + "/accounts", <WorkSpace type='accounts' />),
    createRoute(routesPath.WORKSPACE + "/accounts/:menuTab?", <WorkSpace type='accounts' />),
    createRoute(routesPath.WORKSPACE + "/bill-payment", <WorkSpace type='bill-payment' />),
    createRoute(routesPath.WORKSPACE + "/bill-payment/workspace/:workspaceId", <WorkSpace type='bill-payment' />),
    createRoute(routesPath.WORKSPACE + "/bill-payment/invoice-detail/:invoiceId", <InvoiceDetail />),
  ],
};

export default RouterWorkSpace;
