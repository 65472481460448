import { memo } from 'react';
import { FormItem, useWatch } from '../../Form';
import { Checkbox, Flex, Switch } from 'antd';
import { GTGSTypography, GTGSInput } from '@/components/GTGS';
import { AlignmentSelect } from '../../SettingControls/Alignment';
import { Padding } from '../../SettingControls/Padding';

const PaddingStyle = () => {
  const hasPadding = useWatch('hasPadding');
  if (hasPadding) {
    return (
      <>
        <FormItem name="showMaximumWidth">
          <Checkbox>Apply to all sides</Checkbox>
        </FormItem>
        <Padding name="image" />
      </>
    );
  }

  return null;
};

export const StyleSettings = memo(() => {
  return (
    <>
      <Flex className="mb-4">
        <GTGSTypography.Title level={4}>Edit image</GTGSTypography.Title>
      </Flex>
      <FormItem className="flex-1" name="height" label="Alignment">
        <AlignmentSelect />
      </FormItem>

      <FormItem className="flex-1" name="radius" label="Corner radius">
        <GTGSInput />
      </FormItem>

      <FormItem name="hasPadding">
        <Switch />
      </FormItem>
      <PaddingStyle />
    </>
  );
});
