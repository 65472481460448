import React, { useState, useEffect } from 'react';
import {
  Form,
  InputNumber,
  DatePicker,
  TimePicker,
  Select,
  Typography,
} from 'antd';
import { ArrowDownOutlined } from '@ant-design/icons';
import PreviewSection from '../PreviewSection';
import dayjs, { Dayjs } from 'dayjs';

const { Title, Text } = Typography;
const { Option } = Select;

interface BudgetAndScheduleProps {
  previewData: {
    bodyCopy: string;
    headline: string;
    selectedFacebookPage: string;
  };
}

const BudgetAndSchedule: React.FC<BudgetAndScheduleProps> = ({
  previewData,
}) => {
  const [budget, setBudget] = useState<number>(0);
  const [currency, setCurrency] = useState<string>('USD');
  const [startDate, setStartDate] = useState<Dayjs | null>(dayjs());
  const [startTime, setStartTime] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [estimatedAudienceSize] = useState(0);

  const handleBudgetChange = (value: number | null) => {
    setBudget(value ?? 0);
  };

  const handleCurrencyChange = (value: string) => {
    setCurrency(value);
  };

  const handleStartDateChange = (date: Dayjs | null) => {
    setStartDate(date);
  };

  const handleStartTimeChange = (time: Dayjs | null) => {
    setStartTime(time);
  };

  const handleEndDateChange = (date: Dayjs | null) => {
    setEndDate(date);
  };

  const disabledEndDate = (current: Dayjs) => {
    return startDate ? current && current.isBefore(startDate, 'day') : false;
  };

  useEffect(() => {
    if (startDate && startTime && endDate) {
      const combinedStartDateTime = startDate
        .hour(startTime.hour())
        .minute(startTime.minute())
        .format('MM/DD/YYYY h:mm A');

      const formattedEndDate = endDate.format('MM/DD/YYYY');

      console.log(`${combinedStartDateTime} to ${formattedEndDate}`);
    }
  }, [startDate, startTime, endDate]);

  return (
    <div className="flex flex-col lg:flex-row h-screen">
      <div className="w-full lg:w-1/2 bg-white p-8">
        <Form layout="vertical">
          <Form.Item
            label="Budget *"
            name="budget"
            tooltip="Select your budget amount"
          >
            <div className="flex items-center gap-2">
              <Select
                defaultValue="Daily VND"
                value={currency}
                onChange={handleCurrencyChange}
                style={{ width: '20%', height: 40 }}
              >
                <Option value="USD">Daily USD</Option>
                <Option value="EUR">Daily VND</Option>
              </Select>
              <InputNumber
                min={0}
                value={budget}
                onChange={handleBudgetChange}
                placeholder="0.00"
                style={{ width: '80%', height: 40 }}
              />
            </div>
          </Form.Item>

          <Form.Item
            label="Schedule *"
            name="schedule"
            tooltip="Set the start and end dates for your campaign"
          >
            <div className="flex w-full gap-4">
              <div className="flex flex-col w-1/2 items-start gap-6">
                <DatePicker
                  placeholder="MM/DD/YYYY"
                  style={{ width: '80%', height: 40 }}
                  format="MM/DD/YYYY"
                  value={startDate}
                  onChange={handleStartDateChange}
                />
                <ArrowDownOutlined
                  className="self-center"
                  style={{ fontSize: '1.5rem', color: '#aaa' }}
                />
                <DatePicker
                  placeholder="MM/DD/YYYY"
                  style={{ width: '80%', height: 40 }}
                  format="MM/DD/YYYY"
                  value={endDate}
                  onChange={handleEndDateChange}
                  disabledDate={disabledEndDate}
                />
              </div>
              <div className="w-1/2 flex items-start justify-end">
                <TimePicker
                  placeholder="Select Time"
                  style={{ width: '90%', height: 40 }}
                  format="h:mm A"
                  value={startTime}
                  onChange={handleStartTimeChange}
                />
              </div>
            </div>
          </Form.Item>

          <Text>
            Your ad will run continuously and will cost you no more than{' '}
            <strong>${(budget * 7).toFixed(2)}</strong> per week.
          </Text>
        </Form>
      </div>

      <PreviewSection
        status="both"
        estimatedAudienceSize={estimatedAudienceSize}
        previewData={previewData}
      />
    </div>
  );
};

export default BudgetAndSchedule;
