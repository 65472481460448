import React, { useState } from 'react';
import { Card, Radio, Button, Row, Col, Layout } from 'antd';
import { GTGSIcon } from '@/components/GTGS';

const { Header, Content } = Layout;

const AdTypeSelector = () => {
  const [selectedAdType, setSelectedAdType] = useState('');
  const [selectedNetwork, setSelectedNetwork] = useState('facebook');

  const adTypes = [
    {
      id: 'ad_sequence',
      name: 'Ad sequence',
      description:
        "Drive traffic, build trust, and increase sales by showing a series of three ads, optimized for every stage of the buyer's journey.",
      icon: 'facebook',
    },
    {
      id: 'engagement_ad',
      name: 'Engagement ad',
      description: 'Get more people to engage with you',
      icon: 'facebook',
    },
    {
      id: 'website_visit_ad',
      name: 'Website visit ad',
      description: 'Get more people to visit your website',
      icon: ['facebook', 'linkedin'],
    },
    {
      id: 'lead_generation_ad',
      name: 'Lead generation ad',
      description: 'Collect information from people',
      icon: ['facebook', 'linkedin'],
    },
    {
      id: 'search_ad',
      name: 'Search ad',
      description: 'Reach people interested in your products or service',
      icon: 'google',
    },
  ];

  return (
    <Layout className="min-h-screen">
      <Header className="bg-dark flex justify-between items-center px-6 py-4">
        <Button type="default" className="text-black">EXIT</Button>
        <Button type="primary">NEXT</Button>
      </Header>
      <Content className="w-full max-w-5xl mx-auto p-4">
        <Row gutter={[16, 16]}>
          <Col xs={24} md={12}>
            <h2 className="text-lg font-semibold mb-4">Choose your ad type</h2>
            <div className="space-y-4">
              {adTypes.map((ad) => (
                <Card
                  key={ad.id}
                  onClick={() => setSelectedAdType(ad.id)}
                  className={`cursor-pointer ${selectedAdType === ad.id ? 'ant-card-bordered ant-card-hoverable ant-card-selected' : 'ant-card-bordered'}`}
                  hoverable
                  bordered={selectedAdType !== ad.id}
                >
                  <Card.Meta
                    title={
                      <div className="flex items-center justify-between">
                        <span>{ad.name}</span>
                        <div className="flex space-x-2">
                          {Array.isArray(ad.icon)
                            ? ad.icon.map((platform, index) => (
                              <GTGSIcon key={index} name={`${platform}-ads`} size={'small'} />
                            ))
                            : <GTGSIcon name={`${ad.icon}-ads`} size={'small'} />}
                        </div>
                      </div>
                    }
                    description={ad.description}
                  />
                </Card>
              ))}
            </div>
          </Col>

          <Col xs={24} md={12}>
            <Card className="w-full p-4 bg-gray-50 rounded-md">
              <h3 className="text-lg font-semibold mb-4">Select an ad network</h3>
              <Radio.Group onChange={(e) => setSelectedNetwork(e.target.value)} value={selectedNetwork} className="mb-4">
                <Radio value="facebook">Facebook</Radio>
                <Radio value="linkedin">LinkedIn</Radio>
              </Radio.Group>

              <Card bordered className="p-4 border rounded-md bg-white">
                <h4 className="text-base font-medium mb-2">Select a page</h4>
                <p className="text-sm text-gray-500 mb-4">Sponsored</p>
                <p className="text-sm text-gray-700 mb-4">
                  Write a message that clearly tells people about what you're promoting
                </p>
                <div className="w-full h-40 bg-gray-200 flex items-center justify-center mb-4">
                  <img
                    src="https://www.svgrepo.com/show/354101/image-placeholder.svg"
                    alt="Placeholder"
                    className="w-12 h-12 opacity-50"
                  />
                </div>
                <p className="text-sm font-semibold mb-2">
                  Write a clear and concise headline to capture viewers' attention
                </p>
                <Button type="primary">Learn More</Button>
              </Card>
            </Card>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default AdTypeSelector;
