import { createRoute } from '@/helpers';
import React from 'react';
import { routesPath } from './constants';

const PaymentPlanPage = React.lazy(() => import('@/pages/PaymentPlan'));


// --------------------------------------------------------------------------- ROUTER
const RouterPaymentPlan = {
  path: routesPath.PAYMENT_PLAN_REVIEW,
  children: [
    createRoute(routesPath.PAYMENT_PLAN_REVIEW, <PaymentPlanPage />),

  ],
};

export default RouterPaymentPlan;
