import { memo } from 'react';
import { Form, FormItem } from '../../Form';
import { ColorPicker } from '../../SettingControls/ColorPicker';
import { Select } from 'antd';
import { LayoutPatternSelect } from '../../SettingControls/LayoutPatternSelect';

export const TemplateSettings = memo(() => {
  return (
    <>
      <FormItem name="backgroundColor" label="Background color">
        <ColorPicker />
      </FormItem>
      <FormItem name="backgroundPattern" label="Background pattern">
        <LayoutPatternSelect />
      </FormItem>
      <FormItem name="bodyColor" label="Body color">
        <ColorPicker />
      </FormItem>
      <FormItem name="hasBorder" label="Border">
        <ColorPicker />
      </FormItem>
    </>
  );
});
