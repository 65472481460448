import httpService from '@/services/httpService';
import config from '@/config';
import { customResponsePagination } from '@/helpers';

const baseUrl = `${config.gatewayUrl}/v1`;

interface AdAccountsParam {
  platform?: 'FACEBOOK' | 'GOOGLE' | 'LINKEDIN';
  page?: number;
  pageSize?: number;
}

export interface AdAccount {
  id: string;
  name: string;
}

export interface FacebookPage {
  id: string;
  name: string;
}

export const getAdAccounts = async (params: AdAccountsParam) => {
  const payload = {
    ...params,
  };
  const { data } = await httpService.get(`${baseUrl}/ads/accounts`, {
    params: payload,
  });

  return {
    data: customResponsePagination({
      content: data?.adAccounts || [],
      totalElementsCount: Number(data.total || 0),
      currentPage: params?.page || 1,
      pageSize: params?.pageSize || 10,
    }),
    success: data.success,
  };
};

export const getConnectedAdAccounts = async (params: AdAccountsParam) => {
  const payload = {
    ...params,
  };
  const { data } = await httpService.get(`${baseUrl}/ads/accounts/connected`, {
    params: payload,
  });

  return {
    data: customResponsePagination({
      content: data?.adAccounts || [],
      totalElementsCount: Number(data.total || 0),
      currentPage: params?.page || 1,
      pageSize: params?.pageSize || 10,
    }),
    success: data.success,
  };
};

export const getAdAccountsStats = async (params: any) => {
  const payload = {
    ...params,
  };
  const { data } = await httpService.get(`${baseUrl}/ads/accounts/stats`, {
    params: payload,
  });

  return {
    data: customResponsePagination({
      content: data?.adCampaignStats || [],
      totalElementsCount: Number(data.total || 0),
      currentPage: params?.page || 1,
      pageSize: params?.pageSize || 10,
    }),
    summary: data?.summary,
    success: data.success,
  };
};

export const connectAdAccounts = async (payload: any) => {
  const { data } = await httpService.post(`${baseUrl}/ads/accounts`, payload);

  return data;
};

export const getAdAccountFacebookPages = async (params: any) => {
  const payload = {
    ...params,
  };
  const { data } = await httpService.get(`${baseUrl}/ads/facebook/pages`, {
    params: payload,
  });

  return {
    data: customResponsePagination({
      content: data?.pages || [],
      totalElementsCount: Number(data.total || 0),
      currentPage: params?.page || 1,
      pageSize: params?.pageSize || 10,
    }),
    summary: data?.summary,
    success: data.success,
  };
};
