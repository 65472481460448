import { GTGSInput } from '@/components/GTGS';
import { useNode } from '@craftjs/core';
import { Form, Input } from 'antd';
import InputSettings from './settings/InputSetting';
import { GTGSTextArea } from '@/components/GTGS/GTGSInput/GTGSInputArea';

type FormTextAreaProps = {
  name?: string;
  label: string;
  placeholder: string;
  minLength: number;
  maxLength: number;
  isRequired?: boolean;
};

const FormTextArea = ({
  name,
  label,
  placeholder,
  minLength,
  maxLength,
  isRequired,
}: FormTextAreaProps) => {
  const {
    connectors: { connect, drag },
    id,
  } = useNode();

  return (
    <div
      ref={(ref) => ref && connect(drag(ref))}
      className="w-full"
      data-form="true"
    >
      <Form.Item
        name={name}
        label={label}
        rules={[
          {
            required: isRequired,
            message: 'This field is required!',
          },
          {
            min: minLength,
            message: `Minimum length is ${minLength} characters!`,
          },
          {
            max: maxLength,
            message: `Maximum length is ${maxLength} characters!`,
          },
        ]}
      >
        <Input.TextArea
          rows={4}
          autoSize={{ minRows: 4, maxRows: 4 }}
          placeholder={placeholder}
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
        />
      </Form.Item>
    </div>
  );
};

FormTextArea.defaultProps = {
  label: 'Label',
  placeholder: 'Placeholder',
  minLength: 0,
  maxLength: 255,
  isRequired: false,
};

FormTextArea.craft = {
  related: {
    settings: InputSettings,
  },
  displayName: 'GTGFORMTextArea',
};

export default FormTextArea;
