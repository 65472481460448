import React from 'react';
import { useNode } from '@craftjs/core';

type ProductCardProps = {
  imgSrc?: string;
  title?: string;
  price?: string;
  bgColor?: string;
};

const ProductCard = ({
  imgSrc = '/cosmetic/face-facial.png',
  title = 'Face Facial',
  price = '75,00 USD',
  bgColor = 'bg-[#fff8ec]',
}: ProductCardProps) => {
  const {
    connectors: { connect, drag },
  } = useNode();

  return (
    <div
      ref={(ref) => ref && connect(drag(ref))}
      className={`text-center p-6 w-full md:w-auto ${bgColor}`}
    >
      <img
        src={imgSrc}
        alt={title}
        className="w-[375px] h-auto p-8 -mt-28 rounded-lg"
      />
      <h3 className="text-lg text-[20px] md:text-[30px] font-extrabold text-[#183B56]">
        {title}
      </h3>
      <p className="text-[#36B37E] font-extrabold text-[18px] md:text-[24px]">
        {price}
      </p>
      <button className="bg-[#183B56] w-[190px] h-[48px] text-white text-[18px] font-semibold rounded-3xl mt-4 hover:bg-[#0F2E49] transition">
        Buy It →
      </button>
    </div>
  );
};

const ProductCardSettings = () => {
  const {
    actions: { setProp },
    imgSrc,
    title,
    price,
    bgColor,
  } = useNode((node) => ({
    imgSrc: node.data.props.imgSrc,
    title: node.data.props.title,
    price: node.data.props.price,
    bgColor: node.data.props.bgColor,
  }));

  return (
    <div>
      <div className="mb-5">
        <label className="block mb-2 font-medium text-gray-700">
          Image URL
        </label>
        <input
          className="w-full px-3 py-2 border border-gray-300 rounded-md"
          value={imgSrc}
          onChange={(e) =>
            setProp(
              (props: ProductCardProps) => (props.imgSrc = e.target.value)
            )
          }
        />
      </div>
      <div className="mb-5">
        <label className="block mb-2 font-medium text-gray-700">Title</label>
        <input
          className="w-full px-3 py-2 border border-gray-300 rounded-md"
          value={title}
          onChange={(e) =>
            setProp((props: ProductCardProps) => (props.title = e.target.value))
          }
        />
      </div>
      <div className="mb-5">
        <label className="block mb-2 font-medium text-gray-700">Price</label>
        <input
          className="w-full px-3 py-2 border border-gray-300 rounded-md"
          value={price}
          onChange={(e) =>
            setProp((props: ProductCardProps) => (props.price = e.target.value))
          }
        />
      </div>
      <div className="mb-5">
        <label className="block mb-2 font-medium text-gray-700">
          Background Color
        </label>
        <input
          className="w-full px-3 py-2 border border-gray-300 rounded-md"
          value={bgColor}
          onChange={(e) =>
            setProp(
              (props: ProductCardProps) => (props.bgColor = e.target.value)
            )
          }
        />
      </div>
    </div>
  );
};

ProductCard.craft = {
  props: {
    imgSrc: '/cosmetic/face-facial.png',
    title: 'Face Facial',
    price: '75,00 USD',
    bgColor: 'bg-[#fff8ec]',
  },
  related: {
    settings: ProductCardSettings,
  },
  displayName: 'Product Card',
};

const RecommendationSection = () => {
  return (
    <div className="w-full py-14 px-4 md:px-0 text-center">
      <h2 className="text-[24px] md:text-[40px] font-extrabold text-[#183B56]">
        Products Suited to Your Needs
      </h2>
      <p className="text-[#5A7184] md:text-[18px] font-light leading-relaxed max-w-3xl mx-auto mb-8">
        Inspired by hydration and Japan's beauty rituals, Ehya Cosmetics focuses
        on the core elements of skincare to combine powerful ingredients backed
        by science and authentically.
      </p>
      <div className="flex flex-col md:flex-row justify-center items-center mt-20 gap-10 md:gap-0">
        <ProductCard
          imgSrc="/cosmetic/face-facial.png"
          title="Face Facial"
          price="75,00 USD"
          bgColor="bg-[#eff9f5]"
        />
        <ProductCard
          imgSrc="/cosmetic/body-facial.png"
          title="Body Facial"
          price="55,00 USD"
          bgColor="bg-[#fff8ec]"
        />
      </div>
      <div className="flex justify-center w-full mt-10 md:mt-0">
        <div className="bg-[#F9F9F9] py-8 shadow-md w-full md:w-[844px] md:px-[48px] text-center">
          <h3 className="text-[24px] md:text-[40px] font-extrabold text-[#183B56]">
            Double Pack Package
          </h3>
          <p className="text-[#36B37E] font-extrabold text-[18px] md:text-[24px] mb-4">
            100,00 USD
          </p>
          <button className="bg-[#183B56] w-[190px] h-[48px] text-white text-[18px] font-semibold rounded-3xl mt-4 hover:bg-[#0F2E49] transition">
            Buy It →
          </button>
        </div>
      </div>
    </div>
  );
};

export default RecommendationSection;
