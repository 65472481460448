import { customResponsePagination } from '@/helpers';
import config from '../config';
import httpService from './httpService';
import { E_CRM_OBJECT_TYPE } from '@/pages/CRM/store/constants';

const baseUrl = `${config.gatewayUrl}/v1`;

export const getObjects = async (
  params,
  object_type_name = E_CRM_OBJECT_TYPE.CONTACTS
) => {
  const payload = {
    object_type_name,
    ...params,
  };
  const { data } = await httpService.get(`${baseUrl}/crm/objects`, {
    params: payload,
  });

  return {
    data: customResponsePagination({
      content: data.data || [],
      totalElementsCount: Number(data.total || 0),
      currentPage: params?.page || 1,
      pageSize: params?.pageSize || 10,
    }),
    total: data.total,
    success: data.success,
  };
};

export const getContacts = async (params) => {
  return getObjects({
    ...params,
    object_type_name: E_CRM_OBJECT_TYPE.CONTACTS,
  });
};

export const getCompanies = async (params) => {
  return getObjects({
    ...params,
    object_type_name: E_CRM_OBJECT_TYPE.COMPANIES,
  });
};

export const getDeals = async (params) => {
  return getObjects({
    ...params,
    object_type_name: E_CRM_OBJECT_TYPE.DEALS,
  });
};

export const getTickets = async (params) => {
  return getObjects({
    ...params,
    object_type_name: E_CRM_OBJECT_TYPE.TICKETS,
  });
};

// Properties
export const getCrmProperties = async ({
  type = E_CRM_OBJECT_TYPE.CONTACTS,
}) => {
  const { data } = await httpService.get(`${baseUrl}/crm/object/properties`, {
    params: { object_type_name: type },
  });
  return data;
};

// Get json schema of a property
export const getCrmJsonSchema = async ({
  type = E_CRM_OBJECT_TYPE.CONTACTS,
}) => {
  const { data } = await httpService.get(`${baseUrl}/crm/object/json-schema`, {
    params: { object_type_name: type },
  });
  return data;
};

export const updateProperties = async (body) => {
  const { data } = await httpService.put(
    `${baseUrl}/crm/object/properties`,
    body
  );
  return data;
};

export const getDetailProperty = async ({
  propertyName,
  objectTypeName = E_CRM_OBJECT_TYPE.CONTACTS,
}) => {
  const { data } = await httpService.get(
    `${baseUrl}/crm/object/property/detail/${objectTypeName}/${propertyName}`
  );
  return data;
};

export const getCrmContactDetail = async (id: string, object_type_name = E_CRM_OBJECT_TYPE.CONTACTS) => {
  const { data } = await httpService.get(
    `${baseUrl}/crm/object/${id}?object_type_name=${object_type_name}`
  );
  return data;
};

export const updateCRMDetail = async ({ id, body, objectTypeName = E_CRM_OBJECT_TYPE.CONTACTS }) => {
  const payload = {
    objectTypeName,
    properties: { ...body },
  };
  const { data } = await httpService.put(
    `${baseUrl}/crm/object/${id}`,
    payload
  );
  return data;
};

export const createContact = async ({body, object_type_name = E_CRM_OBJECT_TYPE.CONTACTS}) => {
  const payload = {
    object_type_name,
    properties: { ...body },
  };
  const { data } = await httpService.post(`${baseUrl}/crm/object`, payload);

  return data;
};

export const submitContactInfo = async (body) => {
  const { data } = await httpService.post(`${baseUrl}/crm/cta`, body);
  return data;
};

export const getContactOwner = async (workspaceId: string) => {
  const { data } = await httpService.get(
    `${baseUrl}/workspaces/${workspaceId}/participants`
  );
  return data;
};

export const getColumnHeaders = async (
  object_type_name = E_CRM_OBJECT_TYPE.CONTACTS
) => {
  const payload = {
    object_type_name: object_type_name,
  };
  const { data } = await httpService.get(`${baseUrl}/crm/object/view`, {
    params: payload,
  });
  return data;
};

export const updateColumnHeaders = async (body) => {
  const { data } = await httpService.put(`${baseUrl}/crm/object/view`, body);
  return data;
};

export const getContactForm = async (
  object_type_name = E_CRM_OBJECT_TYPE.CONTACTS
) => {
  const payload = {
    object_type_name: object_type_name,
  };
  const { data } = await httpService.get(`${baseUrl}/crm/object/form`, {
    params: payload,
  });
  return data;
};

export const updateContactForm = async (body) => {
  const { data } = await httpService.put(`${baseUrl}/crm/object/form`, body);
  return data;
};

export const bulkAssignObject = async ({
  ids,
  owner,
  objectTypeName = E_CRM_OBJECT_TYPE.CONTACTS,
}: {
  ids: string[];
  owner: string;
  objectTypeName: E_CRM_OBJECT_TYPE;
}) => {
  const payload = {
    objectTypeName,
    ids,
    owner,
  };
  const { data } = await httpService.patch(
    `${baseUrl}/crm/objects/assign`,
    payload
  );
  return data;
};

export const bulkDeleteObject = async ({
  ids,
  objectTypeName = E_CRM_OBJECT_TYPE.CONTACTS,
}: {
  ids: string[];
  objectTypeName: E_CRM_OBJECT_TYPE;
}) => {
  const payload = {
    objectTypeName,
    ids,
  };
  const { data } = await httpService.patch(
    `${baseUrl}/crm/objects/delete`,
    payload
  );
  return data;
};

export const bulkEditObject = async ({
  ids,
  properties,
  objectTypeName = E_CRM_OBJECT_TYPE.CONTACTS,
}) => {
  const payload = {
    objectTypeName,
    ids,
    properties,
  };
  const { data } = await httpService.patch(`${baseUrl}/crm/objects`, payload);
  return data;
};

export const getNotes = async (params) => {
  const { data } = await httpService.get(
    `${baseUrl}/crm/contact/notes`,
    {
      params,
    }
  );
  return {
    data: customResponsePagination({
      content: data.data || [],
      totalElementsCount: Number(data.total || 0),
      currentPage: params?.page || 1,
      pageSize: params?.pageSize || 10,
    }),
    success: data.success,
  };
}

export const createNote = async (body) => {
  const { data } = await httpService.post(`${baseUrl}/crm/contact/note`, body);
  return data;
}

export const updateNote = async (id, body) => {
  const { data } = await httpService.put(`${baseUrl}/crm/contact/note/${id}`, body);
  return data;
}

export const deleteNote = async (id) => {
  const { data } = await httpService.delete(`${baseUrl}/crm/contact/note/${id}`);
  return data;
}
