import { useEditor } from '@craftjs/core';
import { Flex } from 'antd';
import IconNumber from '../../images/form-builder-123.svg';
import IconText from '../../images/form-builder-abc.svg';
import IconCheckbox from '../../images/form-builder-checkbox.svg';
import IconDateTime from '../../images/form-builder-datetime.svg';
import IconDropdown from '../../images/form-builder-dropdown.svg';
import IconMultiline from '../../images/form-builder-multiline.svg';
import IconRadio from '../../images/form-builder-radio.svg';
import FormCheckbox from './FormCheckbox';
import FormDatePicker from './FormDatePicker';
import FormInput from './FormInput';
import FormSelect from './FormSelect';
import FormSettingItem from './FormSettingItem';
import FormTextArea from './FormTextArea';
import FormRadio from './FormRadio';

const FormSetting = () => {
  const { connectors } = useEditor();
  return (
    <Flex vertical gap={8}>
      <FormSettingItem
        icon={<img src={IconText} alt="abc" />}
        ref={(ref) => ref && connectors.create(ref, <FormInput />)}
      >
        Single-line Text
      </FormSettingItem>
      {/* <FormSettingItem
        icon={<img src={IconNumber} alt="abc" />}
        ref={(ref) => ref && connectors.create(ref, <FormInput />)}
      >
        Number
      </FormSettingItem> */}
      <FormSettingItem
        icon={<img src={IconCheckbox} alt="abc" />}
        ref={(ref) => ref && connectors.create(ref, <FormCheckbox />)}
      >
        Checkbox
      </FormSettingItem>
      <FormSettingItem
        icon={<img src={IconDropdown} alt="dropdown" />}
        ref={(ref) => ref && connectors.create(ref, <FormSelect />)}
      >
        Dropbox
      </FormSettingItem>
      <FormSettingItem
        icon={<img src={IconMultiline} alt="multiline" />}
        ref={(ref) => ref && connectors.create(ref, <FormTextArea />)}
      >
        Multi-line Text
      </FormSettingItem>
      <FormSettingItem
        icon={<img src={IconRadio} alt="radio" />}
        ref={(ref) => ref && connectors.create(ref, <FormRadio />)}
      >
        Radio
      </FormSettingItem>
      <FormSettingItem
        icon={<img src={IconDateTime} alt="datetime" />}
        ref={(ref) => ref && connectors.create(ref, <FormDatePicker />)}
      >
        Date
      </FormSettingItem>
    </Flex>
  );
};

export default FormSetting;
