import classNames from 'classnames';
import React from 'react';
import styles from "./GTGSContainer.module.scss";
// ----------------------------------------
interface GTGSContainerProps {
  children: React.ReactNode;
  className?: string;
  gap?: number
}
//==================  MAIN =================

const GTGSContainer = ({ children, className, gap }: GTGSContainerProps) => (
  <div className={classNames(styles.container, className)} style={{ gap: gap || 24}}>{children}</div>
);

export { GTGSContainer, type GTGSContainerProps };

