import { Outlet } from 'react-router-dom';
import AppProvider from './AppProvider';

const LayoutBlank = () => {
  return (
    <AppProvider>
      <Outlet />
    </AppProvider>
  );
};

export default LayoutBlank;
