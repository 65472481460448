import React from 'react';
import { FaCircle, FaSquare } from 'react-icons/fa';
import { MdHexagon } from 'react-icons/md';
import { BiSolidUpArrow } from 'react-icons/bi';
import { useNode } from '@craftjs/core';

type FactSectionProps = {
  title: string;
  description: string;
};

const FactSection = ({ title, description }: FactSectionProps) => {
  const {
    connectors: { connect, drag },
  } = useNode();

  return (
    <div
      ref={(ref) => ref && connect(drag(ref))}
      className=" px-4 md:px-14 py-16 w-screen"
      id="fact-section"
    >
      <h2
        className="text-4xl font-bold text-center mb-4"
        contentEditable
        suppressContentEditableWarning
        ref={connect as unknown as React.RefObject<HTMLDivElement>}
      >
        {title}
      </h2>
      <p className="text-center text-[16px] font-light text-[#5A7184] mb-12  ">
        {description}
      </p>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 justify-center">
        <div className="flex flex-col items-center text-center">
          <div className="bg-gray-100 p-2 rounded-lg mb-4">
            <FaCircle className="text-green-500 text-2xl" />
          </div>
          <h3 className="text-[14px] font-light mb-2">Palm Oil1</h3>
          <p className="text-[#5A7184]">
            Cleansing is the most important step in your skincare regimen, so it
            shouldn’t strip out your moisture.
          </p>
        </div>
        <div className="flex flex-col items-center text-center">
          <div className="bg-gray-100 p-2 rounded-lg mb-4">
            <FaSquare className="text-green-500 text-2xl" />
          </div>
          <h3 className="text-[14px] font-light mb-2">Organic Glycerine</h3>
          <p className="text-[#5A7184]">
            Cleansing is the most important step in your skincare regimen, so it
            shouldn’t strip out your moisture.
          </p>
        </div>
        <div className="flex flex-col items-center text-center">
          <div className="bg-gray-100 p-2 rounded-lg mb-4">
            <MdHexagon className="text-green-500 text-2xl" />
          </div>
          <h3 className="text-[14px] font-light mb-2">Jojoba Oil</h3>
          <p className="text-[#5A7184]">
            Cleansing is the most important step in your skincare regimen, so it
            shouldn’t strip out your moisture.
          </p>
        </div>
        <div className="flex flex-col items-center text-center">
          <div className="bg-gray-100 p-2 rounded-lg mb-4">
            <BiSolidUpArrow className="text-green-500 text-2xl" />{' '}
          </div>
          <h3 className="text-md font-light mb-2">Avocado Extract</h3>
          <p className="text-[#5A7184]">
            Cleansing is the most important step in your skincare regimen, so it
            shouldn’t strip out your moisture.
          </p>
        </div>
      </div>
    </div>
  );
};

FactSection.defaultProps = {
  title: 'Our Cosmetics Facts',
  description:
    'Inspired by hydration and Japan’s beauty rituals, Ehya Cosmetics focuses on the core elements of skincare to combine powerful ingredients backed by science and authentically.',
};

const FactSectionSettings = () => {
  const {
    actions: { setProp },
    title,
    description,
  } = useNode((node) => ({
    title: node.data.props.title,
    subTitle: node.data.props.subTitle,
    description: node.data.props.description,
  }));
  return (
    <div>
      <div className="mb-5">
        <label className="block mb-2 font-medium text-gray-700">Title</label>
        <input
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          defaultValue={title}
          onChange={(e) => {
            setProp(
              (props: { title: string }) => (props.title = e.target.value),
              1000
            );
          }}
        />
      </div>
      <div className="mb-5">
        <label className="block mb-2 font-medium text-gray-700">
          Description
        </label>
        <input
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          defaultValue={description}
          onChange={(e) => {
            setProp(
              (props: { description: string }) =>
                (props.description = e.target.value),
              1000
            );
          }}
        />
      </div>
    </div>
  );
};

FactSection.craft = {
  props: FactSection.defaultProps,
  related: {
    settings: FactSectionSettings,
  },
  displayName: 'Fact Section',
};

export default FactSection;
