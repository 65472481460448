import { Row as EmailRow } from '@react-email/components';
import { CSSProperties, PropsWithChildren } from 'react';

type IRowProps = {
  background?: string;
  padding?: number;
  minHeight?: number;

  borderCollapse?: CSSProperties['borderCollapse'];
};

export const Row = (props: PropsWithChildren<IRowProps>) => {
  const { children, background, minHeight, borderCollapse } = props;

  return (
    <EmailRow
      style={{
        background,
        minHeight,
        borderCollapse,
      }}
    >
      {children}
    </EmailRow>
  );
};
export const RowSettings = () => {};

export const RowDefaultProps: IRowProps = {
  borderCollapse: 'collapse',
};

Row.craft = {
  props: RowDefaultProps,
  related: {
    settings: RowSettings,
  },
  rules: {
    canDrag: () => false,
  },
};
