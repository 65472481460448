import { Select, SelectProps } from 'antd';

export const FontSelect = (props: SelectProps) => {
  return (
    <Select
      placeholder={'Select '}
      size="large"
      options={[]}
      className="w-full"
      {...props}
    />
  );
};
