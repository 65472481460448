import {
  DATA_PAGE_SIZE,
  DATA_PROPERTIES,
  DEFAULT_PAGE_CURRENT,
  DEFAULT_PAGE_SIZE,
} from '@/constants/pagination';
import type { TablePaginationConfig } from 'antd/es/table';
import type { FilterValue, SorterResult } from 'antd/es/table/interface';
import classNames from 'classnames';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { TSortResult, TableParams } from './types';

import { replaceDataNull } from '@/helpers';
import styles from './GTGSDynamicTable.module.scss';
import { GTGSEmptyProps } from '@/components/GTGS/GTGSEmpty/GTGSEmpty';
import { TableProps } from 'antd/lib';
import { Skeleton, Space } from 'antd';
import { GTGSTable } from '../GTGSTable/GTGSTable';
import { TableTitleProps } from '../GTGSTable/components/TableTitle/TableTitle';

interface GTGSDynamicTableProps extends TableProps<any> {
  filters?: any;
  fnQuery: (params?: any) => Promise<any>;
  fnKey: string;
  mappingData?: any;
  onReady?: (totalRow: number) => void;
  emptyProps?: GTGSEmptyProps;
  onChangeData?: (data: any) => void;
  tableTitle?: TableTitleProps;
}
// ===========================================================================
// MAIN
// ===========================================================================
function _GTGSDynamicTable(props: GTGSDynamicTableProps) {
  const {
    filters: outFilters,
    fnQuery,
    fnKey,
    mappingData = {
      current: 'currentPage',
      items: 'content',
      pageSize: 'pageSize',
      total: 'totalElementsCount',
    },
    onReady,
    className,
    emptyProps,
    pagination,
    onChangeData,
    tableTitle,
    ...rest
  } = props;
  const initPaging = pick(pagination, ['current', 'pageSize']);
  const [params, setParams] = useState<TableParams>({
    current: initPaging?.current ?? DEFAULT_PAGE_CURRENT,
    pageSize: initPaging?.pageSize ?? DEFAULT_PAGE_SIZE,
    ...outFilters,
  });
  const mergeClassName = classNames(className, {
    [styles.onRow]: props.onRow !== undefined,
  });
  const {
    data: rawData,
    isLoading,
    isFetching,
  } = useQuery([fnKey, params], () =>
    fnQuery({
      ...omit(params, ['current']),
      pageSize: params.pageSize,
      page: params.current,
    })
  );
  const data = useMemo(() => rawData?.data, [rawData]);

  useEffect(() => {
    onChangeData?.(data);
  }, [data, onChangeData]);

  const dataFilter = useMemo(
    () =>
      outFilters &&
      Object.keys(outFilters || {})?.filter((item) => !outFilters?.[item]),
    [outFilters]
  );

  const filterCustom = useMemo(
    () => ({ ...omit({ ...outFilters }, dataFilter ?? []) }),
    [dataFilter, outFilters]
  );

  const isEmptyFilter = useMemo(() => {
    if (Object.keys(filterCustom || {})?.length === 0) return false;
    return Object.keys(filterCustom || {}).some(
      (key) => filterCustom?.[key] && key !== 'customerId'
    );
  }, [filterCustom]);

  const dataMapping = useMemo(() => {
    if (!data) return {};
    return DATA_PROPERTIES.reduce((cur, property) => {
      cur[property] = mappingData?.[property]
        ? data[`${mappingData?.[property]}`]
        : data[`${property}`];
      return cur;
    }, {});
  }, [data, mappingData]);

  const handleTableChange = (
    pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue | null>,
    sorter: SorterResult<TSortResult>
  ) => {
    const dataParams = {
      ...params,
      ...pick(pagination, ['current', 'pageSize']),
      ...(sorter.column
        ? { order: sorter?.field, desc: sorter?.order === 'descend' }
        : {}),
      ...replaceDataNull(outFilters),
    };

    const dataParamsFilter =
      dataParams &&
      Object.keys(dataParams || {})?.filter(
        (item) => !dataParams?.[item] || item === 'page'
      );

    const _filter = {
      ...omit({ ...dataParams }, [
        ...dataParamsFilter.filter((i) => i !== 'desc'),
      ]),
    };

    setParams(_filter);
  };

  const handleCurrentPage = useCallback(
    (current: number) => {
      setParams({ ...params, current });
    },
    [params]
  );

  useEffect(() => {
    const dataParams = {
      ...params,
      ...replaceDataNull(outFilters),
      current: 1,
    };

    const dataParamsFilter =
      dataParams &&
      Object.keys(dataParams || {})?.filter(
        (item) => !dataParams?.[item] || item === 'page'
      );

    setParams({ ...omit({ ...dataParams }, [...dataParamsFilter]) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outFilters]);

  useEffect(() => {
    if (data !== undefined) onReady?.(dataMapping['total']);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const customPagination = useMemo(() => {
    if (pagination === false) {
      return false;
    }
    return {
      current: params.current,
      position: ['bottomCenter'],
      pageSize: params.pageSize,
      showSizeChanger: true,
      showQuickJumper: false,
      locale: { items_per_page: 'rows/page' },
      pageSizeOptions: DATA_PAGE_SIZE,
      total: dataMapping['total'],
      ...pagination,
    };
  }, [dataMapping, pagination, params]) as
    | false
    | TablePaginationConfig
    | undefined;

  // --------------------------------------------------------------------------- render
  if (data === undefined && (isLoading || isFetching)) {
    return (
      <Space size={16} direction="vertical" style={{ width: '100%' }}>
        {new Array(4).fill(0).map((_, idx) => (
          <Skeleton.Button key={idx} shape="round" size="small" block />
        ))}
      </Space>
    );
  }

  return (
    <GTGSTable
      loading={isLoading || isFetching}
      dataSource={dataMapping['items'] || []}
      pagination={customPagination}
      tableTitle={tableTitle}
      setPageCurrentTable={handleCurrentPage}
      onChange={handleTableChange as any}
      emptyProps={{
        type: isEmptyFilter ? 'search' : 'transaction',
        ...emptyProps,
      }}
      className={mergeClassName}
      scroll={{ x: 800 }}
      {...rest}
    />
  );
}

const GTGSDynamicTable = memo(_GTGSDynamicTable);

export { GTGSDynamicTable, type GTGSDynamicTableProps };
