import { useEditor } from '@craftjs/core';

export const useHistory = () => {
  const { canUndo, canRedo, actions } = useEditor((state, query) => ({
    enabled: state.options.enabled,
    canUndo: query.history.canUndo(),
    canRedo: query.history.canRedo(),
  }));

  return {
    canUndo: canUndo,
    canRedo: canRedo,
    redo: actions.history.redo,
    undo: actions.history.undo,
  };
};
