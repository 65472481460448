import { createRoute } from '@/helpers';
import React from 'react';
import { routesPath } from './constants';

const PricingPage = React.lazy(() => import('@/pages/PricingPage'));

// --------------------------------------------------------------------------- ROUTER
const RouterPricing = {
  path: routesPath.PRICING,
  children: [
    createRoute(routesPath.PRICING, <PricingPage />),
  ],
};

export default RouterPricing;
