import { SearchOutlined, CloseOutlined } from '@ant-design/icons';
import { Checkbox, Input, Layout, Menu, Radio, Select } from 'antd';
import { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import styled from '@emotion/styled';
import { colorBasePrimary } from '../GTGSColor/GTGSColor';

const { Sider, Content } = Layout;
export interface Category {
  key: string;
  label: string;
}

export interface ListItem {
  id: string;
  name: string;
  selected?: boolean;
}

export interface GTGSBoxSelectProps {
  value?: Record<string, string[]>;
  onChange?: (value: Record<string, string[]>) => void;
  multiple?: boolean;
  categories: Category[];
  fetchListItems: (categoryKey: string, params: { page: number; pageSize: number }) => Promise<{
    content: ListItem[];
    total: number;
  }>;
  placeholder?: string;
  pageSize?: number;
}

const StyledSelect = styled(Select)`
 .ant-select-selector {
   min-height: 40px;
  }
`;

const StyledMenu = styled(Menu)`
  .ant-menu-item {
    padding: 12px;
    height: auto; 
    line-height: 1.2;
  }
`;

const GTGSBoxSelect: React.FC<GTGSBoxSelectProps> = ({
  value = {},
  onChange,
  multiple = true,
  categories,
  fetchListItems,
  placeholder = 'Select items...',
  pageSize = 10
}) => {
  const [open, setOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<string>(categories[0]?.key);
  const [listItems, setListItems] = useState<ListItem[]>([]);
  const [searchText, setSearchText] = useState('');
  const [selectedItems, setSelectedItems] = useState<ListItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const categoryCache = useRef<Record<string, ListItem[]>>({});
  const selectedItemsByCategoryRef = useRef<Record<string, string[]>>(value);

  const loadItems = useCallback(async (category: string, pageNum: number) => {
    try {
      setLoading(true);
      const response = await fetchListItems(category, { page: pageNum, pageSize });
      return response;
    } catch (error) {
      console.error('Error loading items:', error);
      return { content: [], total: 0 };
    } finally {
      setLoading(false);
    }
  }, [fetchListItems, pageSize]);

  useEffect(() => {
    if (!open || !selectedCategory) return;

    if (categoryCache.current[selectedCategory]) {
      setListItems(categoryCache.current[selectedCategory]);
      return;
    }

    loadItems(selectedCategory, 1).then(response => {
      const { content, total } = response;
      categoryCache.current[selectedCategory] = content;
      setListItems(content);
      setHasMore(content.length < total);
      setPage(1);
    });
  }, [selectedCategory, open, loadItems]);

  const handleScroll = useCallback(async (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.currentTarget;
    if (!target || loading || !hasMore) return;

    const scrollPosition = target.scrollTop + target.clientHeight;
    const scrollThreshold = target.scrollHeight - 20;

    if (scrollPosition >= scrollThreshold) {
      const nextPage = page + 1;
      const { content, total } = await loadItems(selectedCategory, nextPage);

      const newItems = [...listItems, ...content];
      setListItems(newItems);
      categoryCache.current[selectedCategory] = newItems;
      setPage(nextPage);
      setHasMore(content.length === pageSize);
    }
  }, [loading, hasMore, page, selectedCategory, listItems, pageSize, loadItems]);

  const handleCategoryClick = useCallback((key: string) => {
    setSelectedCategory(key);
  }, []);

  const handleItemSelect = useCallback((item: ListItem) => {
    let newSelectedItems: ListItem[];
    let newSelectedItemsByCategory: Record<string, string[]>;

    if (multiple) {
      const isSelected = selectedItems.some(i => i.id === item.id);
      newSelectedItems = isSelected
        ? selectedItems.filter(i => i.id !== item.id)
        : [...selectedItems, item];

      newSelectedItemsByCategory = { ...selectedItemsByCategoryRef.current };
      if (!newSelectedItemsByCategory[selectedCategory]) {
        newSelectedItemsByCategory[selectedCategory] = [];
      }

      if (isSelected) {
        newSelectedItemsByCategory[selectedCategory] = newSelectedItemsByCategory[selectedCategory]
          .filter(id => id !== item.id);
        if (newSelectedItemsByCategory[selectedCategory].length === 0) {
          delete newSelectedItemsByCategory[selectedCategory];
        }
      } else {
        newSelectedItemsByCategory[selectedCategory].push(item.id);
      }
    } else {
      newSelectedItems = [item];
      newSelectedItemsByCategory = { [selectedCategory]: [item.id] };
      setOpen(false);
    }

    setSelectedItems(newSelectedItems);
    selectedItemsByCategoryRef.current = newSelectedItemsByCategory;
    onChange?.(newSelectedItemsByCategory);
  }, [multiple, selectedItems, selectedCategory, onChange]);

  const handleDeselect = useCallback((itemId: string) => {
    const newSelectedItems = selectedItems.filter(item => item.id !== itemId);
    setSelectedItems(newSelectedItems);

    const newSelectedItemsByCategory = { ...selectedItemsByCategoryRef.current };
    for (const category in newSelectedItemsByCategory) {
      newSelectedItemsByCategory[category] = newSelectedItemsByCategory[category]
        .filter(id => id !== itemId);
      if (newSelectedItemsByCategory[category].length === 0) {
        delete newSelectedItemsByCategory[category];
      }
    }

    selectedItemsByCategoryRef.current = newSelectedItemsByCategory;
    onChange?.(newSelectedItemsByCategory);
  }, [selectedItems, onChange]);

  const filteredItems = useMemo(() =>
    listItems?.filter(item =>
      item?.name?.toLowerCase().includes(searchText?.toLowerCase())
    ),
    [listItems, searchText]
  );

  const menuItems = useMemo(() =>
    categories.map(cat => ({
      key: cat.key,
      label: (
        <div className="flex items-center justify-between">
          <span>{cat.label}</span>
        </div>
      ),
    })),
    [categories]
  );

  const selectOptions = useMemo(() =>
    selectedItems.map(item => ({
      label: item.name,
      value: item.id
    })),
    [selectedItems]
  );

  const dropdownRender = useCallback(() => (
    <div className="w-full">
      <Layout>
        <Sider width={140} className="bg-white border-r border-gray-200">
          <StyledMenu
            mode="vertical"
            selectedKeys={[selectedCategory]}
            items={menuItems}
            onClick={({ key }) => handleCategoryClick(key)}
          />
        </Sider>
        <Content className="bg-white p-2">
          <Input
            prefix={<SearchOutlined />}
            placeholder="Search..."
            value={searchText}
            onChange={e => setSearchText(e.target.value)}
            className="mb-4"
          />
          <div className="h-[200px] overflow-y-auto" onScroll={handleScroll}>
            <div>
              {filteredItems.map(item => (
                <div
                  key={item.id}
                  className="cursor-pointer hover:bg-gray-50 p-2 rounded"
                >
                  {multiple ? (
                    <Checkbox
                      checked={selectedItems.some(i => i.id === item.id)}
                      onChange={() => handleItemSelect(item)}
                    >
                      {item.name}
                    </Checkbox>
                  ) : (
                    <Radio
                      checked={selectedItems.some(i => i.id === item.id)}
                      onChange={() => handleItemSelect(item)}
                    >
                      {item.name}
                    </Radio>
                  )}
                </div>
              ))}
              {loading && (
                <div className="text-center py-2 text-gray-500">Loading...</div>
              )}
            </div>
          </div>
        </Content>
      </Layout>
    </div>
  ), [selectedCategory, menuItems, searchText, handleScroll, filteredItems,
    loading, handleCategoryClick, multiple, selectedItems, handleItemSelect]);

  return (
    <StyledSelect
      showSearch={false}
      mode={multiple ? "multiple" : undefined}
      open={open}
      onDropdownVisibleChange={(visible) => {
        if (!visible) {
          categoryCache.current = {};
        }
        setOpen(visible);
      }}
      placeholder={placeholder}
      value={Object.values(selectedItemsByCategoryRef.current).flat()}
      dropdownRender={dropdownRender}
      options={selectOptions}
      onDeselect={(value: any) => handleDeselect(value as string)}
    />
  );
};

export default GTGSBoxSelect;