import { Modal, ModalProps } from 'antd';
import classNames from 'classnames';
import { memo, useCallback } from 'react';
import {
  ContentHeader,
  IContentHeaderProps,
} from './components/Header/ContentHeader';
import {
  ActionsFooters,
  IActionsFootersProps,
} from './components/Actions/ActionsFooters';

interface AntdModalProps extends ModalProps {
  type?: 'left-align' | 'center-align';
  headerContent?: IContentHeaderProps;
  actionsFooter?: IActionsFootersProps;
  status?: 'success' | 'error';
  onCancel?: () => void;
  showHeader?: boolean;
  showFooter?: boolean;
}

const _GTGSModals = ({
  children,
  className,
  headerContent,
  actionsFooter,
  status = 'success',
  onCancel,
  showFooter = true,
  showHeader = true,
  width = 384,
  ...rest
}: AntdModalProps) => {
  const handleCancel = useCallback(() => {
    onCancel && onCancel();
    headerContent?.onClose && headerContent.onClose();
    actionsFooter?.onClose && actionsFooter.onClose();
  }, [actionsFooter, headerContent, onCancel]);

  return (
    <Modal
      {...rest}
      width={width}
      onCancel={handleCancel}
      centered
      maskClosable={false}
      destroyOnClose
      footer={null}
      className={classNames(className)}
    >
      {showHeader && (
        <ContentHeader
          {...headerContent}
          status={status}
          onClose={handleCancel}
        />
      )}
      {children}
      {showFooter && <ActionsFooters {...actionsFooter} status={status} />}
    </Modal>
  );
};

const GTGSModals = memo(_GTGSModals);

export { GTGSModals, type AntdModalProps };
