import styled from '@emotion/styled';
import { Alert as AntdAlert, AlertProps as AntdAlertProps } from 'antd';
import colors from '@/theme/colors.module.scss';
import { memo } from 'react';
import { GTGSIcon } from '../GTGSIcon/GTGSIcon';

interface GTGSAlertProps extends AntdAlertProps {}


const cssColorText = (type: string | undefined) => {
  const color = type === "info" ? colors.colorTextInfo : type === "success" ? colors.colorTextSuccess : type === "error" ? colors.colorTextError : type === "warning" ? colors.colorTextWarning : colors.colorTextDefault;
  
  return `
    .ant-alert-message {
      color: ${color};}
    }
    .ant-alert-description {
      color:${color} !important;}
    }
    .ant-alert-action {
      color:${color} !important;}
`;
}
const GTGSAlertStyle = styled(AntdAlert)<GTGSAlertProps>`
  .ant-alert-message {
    font-weight: bold;

  }
  .ant-alert-description {
    font-size: 14px;
  }
  .ant-alert-icon {
    /* margin-top: auto; */
    /* margin-bottom: auto; */
  }
  .ant-alert-action {
      position: absolute;
      bottom: 16px;
      z-index: 1;
      left: 44px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: calc(100% - 60px);
      gap: 24px;
  }
  ${(props: GTGSAlertProps) =>
    props.type === undefined &&
    `background: ${colors.colorSurfaceSecondaryDefault};
      .ant-alert {
        padding-bottom: 0;
      }
    `
  }
  ${(props: GTGSAlertProps) =>
    cssColorText(props.type)}
`;

const _GTGSAlert = ({
  type,
  closable,
  icon,
  showIcon = true,
  action,
  ...rest
}: GTGSAlertProps) => {
  const _icon = {
    success: <GTGSIcon name="check-circle" fill={"#16A34A"}/> ,
    info: <GTGSIcon name="check-circle"  fill={"#005CE6"}/>,
    warning: <GTGSIcon name="warning-alert" size={20} fill={"#FFBF1B"}/>,
    error: <GTGSIcon name="warning-alert" size={20} fill={"#FF0003"}/>,
    undefined: <GTGSIcon name="check-circle" fill={"#000000"}/>,
  }[type as string];

  return (
    <GTGSAlertStyle
      showIcon={showIcon}
      style={action ?{
        paddingBottom: "54px"
      }: undefined}
      icon={icon ? icon : _icon}
      type={type}
      action={action}
      closable={
        closable
          ? {
              'aria-label': 'close',
              closeIcon: <GTGSIcon name="close" size={16} />,
            }
          : false
      }
      {...rest}

    />
  );
};

const GTGSAlert = memo(_GTGSAlert);

export { GTGSAlert, type GTGSAlertProps };
