import { Select, SelectProps } from 'antd';

export const FontSizeSelect = (props: SelectProps) => {
  return (
    <Select
      className="!w-[90px]"
      placeholder={'Select '}
      size="large"
      options={[
        {
          label: '15px',
          value: 15,
        },
      ]}
      {...props}
    />
  );
};
