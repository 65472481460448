import { useResponsive } from '@/hooks';
import {
  AppstoreOutlined,
  BarChartOutlined,
  CloudOutlined,
  ShopOutlined,
  TeamOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Layout, theme } from 'antd';
import { BasicProps } from 'antd/es/layout/layout';
import React, { ReactNode, useEffect, useState } from 'react';
import { GTGSPageHeader } from '../GTGS/GTGSPageHeader/GTGSPageHeader';
import { LeftSlider, SIDER_COLLAPSIBLE_WIDTH, SIDER_WIDTH } from './LeftSider';
import { useLocation } from 'react-router-dom';
import { routesPath } from '@/router/constants';
interface MainLayoutProps extends BasicProps {
  children: ReactNode;
}
const {  Content} = Layout;

const items: MenuProps['items'] = [
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
  BarChartOutlined,
  CloudOutlined,
  AppstoreOutlined,
  TeamOutlined,
  ShopOutlined,
].map((icon, index) => ({
  key: String(index + 1),
  icon: React.createElement(icon),
  label: `nav ${index + 1}`,
}));

const MainLayout: React.FC<MainLayoutProps> = ({
  children,
  ...rest
}: MainLayoutProps) => {
  const {
    token: { borderRadiusLG },
  } = theme.useToken();
  const { md } = useResponsive();
  const { pathname } = useLocation();
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    if(pathname.includes(`${routesPath.MESSAGING}`)) {
      setCollapsed(true);
    }
  }, [pathname]);

  return (
    <Layout hasSider>
      <LeftSlider  collapsed={collapsed} setCollapsed={setCollapsed} />
      <Content
        style={{
          padding: 0,
          overflow: 'initial',
          background: '#ffffff',
        }}
      >
        <Layout style={{ marginLeft: (md) ? (!collapsed ? SIDER_WIDTH : SIDER_COLLAPSIBLE_WIDTH )  : 0 }}>
          <GTGSPageHeader setCollapsed={setCollapsed} collapsed={collapsed} />
          <Content
            style={{
              padding: '0px',
              overflow: 'initial',
              borderRadius: '0px',
              background: '#ffffff',
            }}
          >
            <div
              style={{
                borderRadius: borderRadiusLG,
                minHeight: 'calc(100vh - 48px)',
                paddingTop: "80px"
              }}
            >
              {children}
            </div>
          </Content>
        </Layout>
      </Content>
    </Layout>
  );
};

export default MainLayout;
