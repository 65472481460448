import { useResponsive } from '@/hooks';
import { RequestStatus } from '@/pages/WorkSpace/types';
import { getProfilePictureDetailsAsset } from '@/services/assetsManagementService';
import UserService from '@/services/UserService';
import { putWorkspacesProfile } from '@/services/workspacesService';
import { useProfile } from '@/store/profileStore';
import { IStep } from '@/store/types';
import { useWorkspaceStore } from '@/store/workspaceStore';
import type { MenuProps } from 'antd';
import { Dropdown, Flex, message, Spin } from 'antd';
import classNames from 'classnames';
import { KeycloakTokenParsed } from 'keycloak-js';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';
import { GTGSAvatar, GTGSIcon, GTGSTypography } from '../GTGS';
import { ModalSetUpUser } from '../ModalSetUpUser';
import styles from './styles.module.scss';
import { setCookie } from '@/utils/cookieUtils';

interface UserinfoProps {
  status?: 'normal' | 'workspace';
  workspaces?: any;
  isLoading?: boolean;
}

enum MenuType {
  PersonalProfile = 'personal-profile',
  ProfileDetails = 'profile-details',
  BillPayment = 'bill-payment',
  AccountSettings = 'account-settings',
  AddWorkspace = 'add-workspace',
  Logout = 'logout',
  ExitWorkspace = 'exit-workspace',
}

const Userinfo = ({ status, workspaces, isLoading }: UserinfoProps) => {
  const navigate = useNavigate();
  const responsive = useResponsive();
  const { xs } = responsive;
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { personalProfile } = useProfile();
  const [setStep] = useWorkspaceStore(
    useShallow((state) => [
      state.setStep,
    ])
  );
  const workspaceId = localStorage.getItem('workspaceId');

  const logout = () => {
    localStorage.setItem('workspaceId', '');
    setCookie(
      'liveChatCookie',
      JSON.stringify({
        contactId: "",
        workspaceId: "",
      })
    );
    localStorage.setItem('isLoggedIn', 'false');
    UserService.doLogout();
  };

  const handleClickWorkspace = (key: string) => {
    const workspace = workspaces?.find((item: any) => item.id === key);
    if (!workspace) return;
    if (workspace.requestStatus === RequestStatus.NEED_SETUP) {
      setOpen(true);
      return;
    }
    localStorage.setItem('workspaceId', key);
    window.open(window.location?.pathname || "/", '_self');
  };

  // const { data: profile  } = useQuery("getProfile", () => UserService.getTokenParsed(), { enabled: true, cacheTime: 0 });

  const profile: KeycloakTokenParsed = useMemo(() => {
    const data = UserService.getTokenParsed();
    return {
      ...data,
      assetId: personalProfile?.assetId || data?.assetId ||'',
      given_name: personalProfile?.firstName || data?.given_name ||  '',
      family_name : personalProfile?.lastName || data?.family_name || '',
      email: personalProfile?.email || data?.email || '',
    };
  }, [personalProfile?.assetId, personalProfile?.email, personalProfile?.firstName, personalProfile?.lastName]);


  const { data, isLoading: isLoadingAvatar } = useQuery({
    queryKey: ['getProfilePictureDetailsAsset', { assetId: profile?.assetId }],
    queryFn: () => getProfilePictureDetailsAsset(profile?.assetId),
    enabled: !!profile?.assetId,
  });

  const onClick: MenuProps['onClick'] = ({ key }) => {
    if(!key || key === workspaceId) return;
    switch (key) {
      case MenuType.Logout:
        logout();
        break;
      case MenuType.AccountSettings:
      case MenuType.BillPayment:
      case MenuType.PersonalProfile:
      case MenuType.ProfileDetails:
        navigate('/workspaces/accounts');
        break;
      case MenuType.ExitWorkspace:
        localStorage.setItem('workspaceId', '');
        setStep(IStep.initial)
        navigate('/workspaces/workspace');
      break;
      case MenuType.AddWorkspace:
        setStep(IStep.workspaceName)
        navigate('/workspaces/workspace');
        break;
      default:
        handleClickWorkspace(key);
        break;
    }
  };

  const ContentItem = ({
    avatarUrl,
    title,
    subTitle,
    hasCheck,
    id
  }: {
    avatarUrl: string;
    title: string;
    subTitle: string;
    hasCheck?: boolean;
    id?: string;
  }) => (
    <Flex
      gap={8}
      justify="flex-start"
      align="center"
      className="h-[44px] relative w-full"
    >
      <GTGSAvatar textName={title} size={40} src={avatarUrl || undefined} />
      <Flex vertical justify="center" align="flex-start" className="h-full">
        <Flex justify="flex-start" align="center" gap={4}>
          <GTGSTypography.Text
            strong
            className={`!text-[#111827] font-bold !text-[16px] ${hasCheck?'!max-w-[130px]': '!max-w-[160px]'}`}
            ellipsis
          >
            {title || ""}
          </GTGSTypography.Text>
        </Flex>
        <GTGSTypography.Text
          status="secondary"
          className="!text-[#4B5563] !text-[14px] !max-w-[160px]"
          ellipsis
        >
          {subTitle}
        </GTGSTypography.Text>
      </Flex>
      {hasCheck && (
        <Flex
          gap={10}
          justify="flex-end"
          align="center"
          className="absolute right-[8px]"
        >
           <GTGSIcon name="copy" size={16} fill={'#4B5563'} onClick={()=> {
            if (!id) return message.success('Copied workspace ID failed!');
            navigator.clipboard.writeText(id);
            message.success('Copied workspace ID successfully!');
           }} />
          <GTGSIcon name="check-circle-fill" size={16} fill={'#005CE6'} />
        </Flex>
      )}
    </Flex>
  );

  const itemsNoWorkspace: MenuProps['items'] = useMemo(
    () => [
      {
        label: (
          <ContentItem
            avatarUrl={data?.url || ''}
            title={profile?.name || profile?.preferred_username || ''}
            subTitle={profile?.email || ''}
            id={data?.id}
          />
        ),
        key: MenuType.PersonalProfile,
        style: { cursor: 'unset', pointerEvents: 'none' },
      },
      { type: 'divider', style: { margin: '8px 0' } },
      {
        label: 'Logout',
        key: MenuType.Logout,
        icon: <GTGSIcon name="sign-out" size={16} />,
        // style: { position: 'sticky', bottom: 0, background: '#fff' },
      },
    ],
    [data?.id, data?.url, profile?.email, profile?.name, profile?.preferred_username]
  );

  const items: MenuProps['items'] = useMemo(
    () => [
      {
        label: (
          <ContentItem
            avatarUrl=""
            title={profile?.name || profile?.preferred_username || ''}
            subTitle={profile?.email || ''}
          />
        ),
        key: MenuType.PersonalProfile,
        style: { cursor: 'unset', pointerEvents: 'none' },
      },
      { type: 'divider', style: { margin: '8px 0' } },
      { label: 'Profile details', key: MenuType.ProfileDetails },
      { label: 'Bill payment', key: MenuType.BillPayment },
      { label: 'Account settings', key: MenuType.AccountSettings },
      { type: 'divider', style: { margin: '8px 0' } },
      ...[...(workspaces ?? [])].map((workspace) => ({
        label: (
          <ContentItem
            avatarUrl={workspace?.avatarBase64}
            title={workspace?.name || ''}
            subTitle={`${workspace?.totalMember || 0} Member`}
            hasCheck={workspace?.id === workspaceId}
            id={workspace?.id}
          />
        ),
        key: workspace.id,
        style: { padding: '8px' },
      })),
      {
        label: 'Add workspace',
        key: MenuType.AddWorkspace,
        icon: <GTGSIcon name="building-add" size={16} />,
      },
      { type: 'divider', style: { margin: '8px 0' } },
      {
        label: 'Exit workspace',
        key: MenuType.ExitWorkspace,
        icon: <GTGSIcon name="arrows-collapse-vertical" size={16} />,
      },
      { type: 'divider', style: { margin: '8px 0' } },
      {
        label: 'Logout',
        key: MenuType.Logout,
        icon: <GTGSIcon name="sign-out" size={16} />,
      },
    ],
    [
      profile?.email,
      profile?.name,
      profile?.preferred_username,
      workspaceId,
      workspaces,
    ]
  );

  const itemRoot = useMemo(() => {
    if (!status) return [];
    return status === 'workspace' ? items : itemsNoWorkspace;
  }, [items, itemsNoWorkspace, status]);

  const updateWorkspaceProfileMutation = useCallback(
    async (values) => {
      setLoading(true);
      const dataSave = {
        displayName: values.displayName,
        avatarUrl: values.avatarUrl,
        phone: values.phone,
      };

      try {
        const res = await putWorkspacesProfile(workspaceId, dataSave);
        if (res) {
          message.success('Update user workspace successfully!');
          localStorage.setItem('workspaceId', workspaceId || '');
          window.open(window.location?.pathname || "/", '_self');
          setOpen(false);
        } else {
          message.error('Update user workspace failed!');
        }
      } catch (error) {
        message.error('Update user workspace failed!');
      } finally {
        setLoading(false);
      }
    },
    [workspaceId]
  );

  if (isLoading || isLoadingAvatar) {
    return (
      <Flex gap={16} align="center" style={{ height: '100%' }}>
        <Flex align="center" gap={8}>
          <Spin />
        </Flex>
      </Flex>
    );
  }

  return (
    <Flex gap={16} align="center" style={{ height: '100%' }}>
      <Dropdown
        menu={{ items: itemRoot, onClick }}
        className={styles.dropdown}
        overlayClassName={classNames('scrollable', styles.overlayClassName)}
        overlayStyle={{ width: '272px' }}
      >
        <Flex align="center" gap={8}>
          <GTGSAvatar
            size={40}
            src={data?.url || undefined}
            alt=" "
            textName={profile?.name || profile?.preferred_username || ''}
          />
        </Flex>
      </Dropdown>

      <ModalSetUpUser
        open={open}
        onCancel={() => setOpen(false)}
        onApply={(values) => updateWorkspaceProfileMutation(values)}
        loading={loading}
      />
    </Flex>
  );
};

export default Userinfo;
