import FaceBook from '../../../../assets/icons/facebook.svg';
import Ins from '../../../../assets/icons/instagram.svg';
import Tiktok from '../../../../assets/icons/tiktok.svg';
import { Img } from '@react-email/components';
const Icons = {
  facebook: FaceBook,
  instagram: Ins,
  tiktok: Tiktok,
} as const;

export const Icon = (props: { name: keyof typeof Icons }) => {
  const { name } = props;
  return <Img width={30} src={Icons[name]} />;
};
