import { ComponentProps, memo } from 'react';
import { Editor as CraftEditor } from '@craftjs/core';
import { Layout } from './EmailControls/Layout';
import { Button } from './EmailControls/Button';
import { Image } from './EmailControls/Image';
import { Container } from './EmailControls/Container';
import { Col } from './EmailControls/Col';
import { Row } from './EmailControls/Row';
import { DropContent } from './EmailControls/DropContent';
import { Text } from './EmailControls/Text';
import { Indicator } from './EmailControls/Indicator';
import { Divider } from './EmailControls/Divider';
import { Social } from './EmailControls/Social';

type IEditorProps = ComponentProps<typeof CraftEditor>;

export const Editor = memo((props: IEditorProps) => {
  return (
    <CraftEditor
      onRender={Indicator}
      resolver={{
        Layout,
        Button,
        Image,
        Container,
        Col,
        Row,
        DropContent,
        Text,
        Divider,
        Social,
      }}
      {...props}
    />
  );
});
