import { GTGSCollapse } from '@/components/GTGS';
import { useEditor } from '@craftjs/core';
import { CollapseProps, Flex } from 'antd';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useCallback } from 'react';

import HeroSection from '../themes/theme-1/components/HeroSection';
import TopBanner from '../themes/theme-1/components/TopBanner';
import FeatureSection from '../themes/theme-1/components/FeatureSection';
import CTA from '../themes/theme-1/components/CTA';
import GTGCosmeticsHeader from '../themes/theme-2/components/Header';
import FactSection from '../themes/theme-2/components/FactSection';
import StorySection from '../themes/theme-2/components/StorySection';
import ProductShowcase from '../themes/theme-2/components/ProductShowcase';

import headerImage from '../images/header-cosmetic.png';
import factImage from '../images/fact-section.png';
import storyImage from '../images/story-section.png';
import productImage from '../images/product-showcase.png';
import heroImage from '../images/hero-banner.png';
import aboutUsImage from '../images/about-us.png';
import featureImage from '../images/feature-section.png';

import { getLandingPageState } from '@/services/pageBuilderService';

const ToolBox = () => {
  const { connectors } = useEditor();
  const { id } = useParams();
  const { data } = useQuery(['getLatestState'], () =>
    getLandingPageState(id || '', 'latest')
  );

  const createItem = useCallback(
    (key, label, component, imagePath) => ({
      key,
      label,
      children: (
        <Flex vertical gap={16}>
          <div ref={(ref) => ref && connectors.create(ref, component)}>
            {imagePath && (
              <img
                src={imagePath}
                alt={label}
                className="border-2 border-solid border-gray-200"
              />
            )}
          </div>
        </Flex>
      ),
      style: {
        marginBottom: 8,
        background: 'transparent',
        borderRadius: 12,
        border: '1px solid #E2E8F0',
      },
    }),
    [connectors]
  );

  const sectionsConfig = {
    gtg_cosmetics: [
      createItem('1', 'Header', <GTGCosmeticsHeader />, headerImage),
      createItem('2', 'Fact Section', <FactSection />, factImage),
      createItem('3', 'Story Section', <StorySection />, storyImage),
      createItem('4', 'Product Showcase', <ProductShowcase />, productImage),
    ],
    gtg_crm_template: [
      createItem('1', 'Header', <TopBanner />, heroImage),
      createItem('2', 'CTA', <CTA />, aboutUsImage),
      createItem('3', 'Feature Section', <FeatureSection />, featureImage),
    ],
  };

  const items = sectionsConfig[data?.templateCode];

  return (
    <div>
      <GTGSCollapse
        className="gtg-collapse"
        accordion
        bordered={false}
        collapsible="header"
        style={{ background: 'transparent' }}
        size="small"
        items={items}
      />
    </div>
  );
};

export default ToolBox;
