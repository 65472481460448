import { Hr as EmailDivider } from '@react-email/components';
import { CSSProperties, PropsWithChildren } from 'react';

type IDividerProps = {
  style?: CSSProperties;
};

export const Divider = (props: IDividerProps) => {
  const { style } = props;

  return <EmailDivider style={style} />;
};
export const DividerSettings = () => {};

export const DividerDefaultProps: IDividerProps = {
  style: {
    border: '1px solid #000000',
  },
};

Divider.craft = {
  props: DividerDefaultProps,
  related: {
    settings: DividerSettings,
  },
};
