import classNames from 'classnames';
import {
  ComponentPropsWithoutRef,
  CSSProperties,
  ForwardedRef,
  forwardRef,
  memo,
} from 'react';
import isEqual from 'react-fast-compare';

import styles from './GTGSIcon.module.scss';
import { IconName } from './GTGSIcon.types';

interface GTGSIconProps extends ComponentPropsWithoutRef<'div'> {
  name: IconName;
  size?: number | 'small' | 'large';
  fill?: string;
}

function _GTGSIcon(props: GTGSIconProps, ref: ForwardedRef<HTMLDivElement>) {
  const { name, size = 'small', style, className, fill, ...divProps } = props;

  const sizeNew = typeof size === 'number' ? size : size === 'small' ? 24 : 32;

  const _style: CSSProperties = {
    ...(typeof sizeNew !== 'undefined'
      ? {
          width: sizeNew,
          height: sizeNew,
        }
      : {}),
    ...style,
  };

  const url = `/images/icons/${name}.svg`;

  if (fill) {
    _style.WebkitMaskImage = `url("${url}")`;
    _style.maskImage = `url("${url}")`;
    _style.backgroundColor = fill;
  } else {
    _style.backgroundImage = `url("${url}")`;
  }

  return (
    <div
      ref={ref}
      {...divProps}
      className={classNames(styles.root, className, {
        [styles.fillColor]: fill?.length,
      })}
      style={_style}
    />
  );
}

const GTGSIcon = memo(forwardRef(_GTGSIcon), isEqual);

export { GTGSIcon, type GTGSIconProps };
