import { memo } from 'react';
import { FormItem } from '../../Form';
import { Checkbox, Flex } from 'antd';
import { GTGSInput, GTGSTypography } from '@/components/GTGS';

export const ContentSettings = memo(() => {
  return (
    <>
      <Flex className="mb-4">
        <GTGSTypography.Title level={4}>Edit image</GTGSTypography.Title>
      </Flex>
      <Flex gap={16}>
        <FormItem className="flex-1" name="width" label="Width">
          <GTGSInput />
        </FormItem>
        <FormItem className="flex-1" name="height" label="Height">
          <GTGSInput />
        </FormItem>
      </Flex>
      <FormItem name="showMaximumWidth">
        <Checkbox>Show as maximum width on mobile devices</Checkbox>
      </FormItem>
      <FormItem name="link" label="Link">
        <GTGSInput />
      </FormItem>
    </>
  );
});
