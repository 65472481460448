import { useEditor } from '@craftjs/core';
import React, { useCallback, useState } from 'react';
import { GTGSCollapse, GTGSIcon, GTGSInput, GTGSTabs } from '@/components/GTGS';
import { TabsProps } from 'antd/lib';
import ToolBox from './ToolBox';
import { Modal } from 'antd';
import { usePageBuilder } from '../store/usePageBuilderStore';
import { useShallow } from 'zustand/react/shallow';
import useEditorState from '../store/useEditorState';

export const SettingsPanel = () => {
  const { formSettingVisible, setFormSettingVisible } = usePageBuilder(
    useShallow((state) => ({
      formSettingVisible: state.formSettingVisible,
      setFormSettingVisible: state.setFormSettingVisible,
    }))
  );

  const { selected, query, nodes, actions, activeTopBannerNode } =
    useEditorState();

  // if (selected?.data?.displayName.includes('GTGFORM')) {
  //   setFormSettingVisible(true);
  // }

  // const { selected, query, nodes, actions } = useEditor((state, query) => {
  //   const currentNodeId = query.getEvent('selected').last();

  //   let selected;

  //   if (currentNodeId) {
  //     selected = {
  //       id: currentNodeId,
  //       name: state.nodes[currentNodeId].data.name,
  //       settings:
  //         state.nodes[currentNodeId].related &&
  //         state.nodes[currentNodeId].related.settings,
  //       isDeletable: query.node(currentNodeId).isDeletable(),
  //       data: state.nodes[currentNodeId].data,
  //     };
  //   }

  //   if (selected?.data?.displayName.includes('GTGFORM')) {
  //     setFormSettingVisible(true);
  //   }

  //   const ROOT = 'ROOT'; // Define the ROOT constant
  //   const nodeList = state.nodes[ROOT]?.data?.nodes;

  //   const nodes = Object.keys(state.nodes)
  //     .filter((nodeId) => nodeList.includes(nodeId))
  //     .map((nodeId) => ({
  //       id: nodeId,
  //       name: state.nodes[nodeId].data.name,
  //       displayName: state.nodes[nodeId].data.displayName,
  //       data: state.nodes[nodeId].data,
  //       settings:
  //         state.nodes[nodeId].related && state.nodes[nodeId].related.settings,
  //     }))
  //     .sort((a, b) => nodeList.indexOf(a.id) - nodeList.indexOf(b.id));

  //   return {
  //     selected,
  //     nodes,
  //     isEnabled: state.options.enabled,
  //   };
  // });

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Content',
      icon: <GTGSIcon name="pencil" size={14} fill="currentColor" />,

      children: (
        <GTGSCollapse
          className="gtg-collapse"
          accordion
          bordered={false}
          collapsible="header"
          activeKey={
            selected?.data?.displayName.includes('GTGFORM')
              ? 'EtDtE_LWVq'
              : selected?.id
          }
          style={{ background: 'transparent' }}
          size="small"
          onChange={(key) => {
            actions.selectNode(key);
            const element = document.getElementById(key.join(''));
            if (element) {
              element.scrollTop = element.scrollHeight;
              element.scrollIntoView({ block: 'start', behavior: 'smooth' });
            }
          }}
          items={nodes.map((node) => ({
            key: node.id,
            label: node.displayName || node.name,
            children: selected?.data?.displayName.includes('GTGFORM')
              ? React.createElement(
                  nodes.find((node) => node.id === 'EtDtE_LWVq')
                    ?.settings as React.ComponentType
                )
              : selected?.settings
                ? React.createElement(selected?.settings as React.ComponentType)
                : ('No setting available' as React.ReactNode),
            style: {
              marginBottom: 8,
              background: 'transparent',
              borderRadius: 12,
              border: '1px solid #E2E8F0',
            },
          }))}
        />
      ),
    },
    {
      key: '2',
      label: 'Component',
      icon: (
        <GTGSIcon
          name="grid"
          size={14}
          fill="currentColor"
          className="rotate-45"
        />
      ),
      children: <ToolBox />,
    },
  ];

  return (
    <div>
      <GTGSTabs items={items} centered />
      <Modal
        open={formSettingVisible}
        onCancel={() => {
          console.log('cancel');
          setFormSettingVisible(false);
          actions.clearEvents();
          activeTopBannerNode();
        }}
        onOk={() => {
          setFormSettingVisible(false);
          actions.clearEvents();
        }}
        footer={false}
        title={`Edit ${selected?.data?.props?.label || selected?.name}`}
        onClose={() => {
          console.log('close');
          actions.clearEvents();
          activeTopBannerNode();
        }}
      >
        {selected?.settings
          ? React.createElement(selected.settings)
          : 'No setting available'}
      </Modal>
    </div>
  );
};
