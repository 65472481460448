import Show from '@/components/GTGS/GTGSShow/GTGSShow';
import { Segmented } from 'antd';
import { memo, useState } from 'react';
import { StyleSettings } from './Style';
import { ContentSettings } from './Content';
import { Form } from '../../Form';

type ITab = 'Content' | 'Style';

const Tabs = (props: { value: ITab; onChange: (value: ITab) => void }) => {
  const { onChange, value } = props;
  return (
    <Segmented
      options={['Content', 'Style']}
      onChange={(value) => {
        onChange(value as ITab);
      }}
      value={value}
      block
    />
  );
};

export const Settings = memo(() => {
  const [currentTab, setTabs] = useState<ITab>('Content');
  return (
    <div className="w-full flex flex-col gap-4">
      <div className="tabs">
        <Tabs value={currentTab} onChange={(v) => setTabs(v)} />
      </div>
      <Form>
        <Show>
          <Show.When isTrue={currentTab === 'Content'}>
            <ContentSettings />
          </Show.When>
          <Show.When isTrue={currentTab === 'Style'}>
            <StyleSettings />
          </Show.When>
        </Show>
      </Form>
    </div>
  );
});
