import { createRoute } from '@/helpers';
import React from 'react';
import { routesPath } from './constants';


const CasesPage = React.lazy(() => import('@/pages/Cases'));


// --------------------------------------------------------------------------- ROUTER
const RouterBookmark = {
  path: routesPath.BOOKMARK,
  children: [
    createRoute(routesPath.BOOKMARK, <CasesPage />),
  ],
};

export default RouterBookmark;
