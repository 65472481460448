import { FormBox } from '@/pages/PageBuilder';
import { setCookie } from '@/utils/cookieUtils';
import { Element } from '@craftjs/core';
import { message } from 'antd';
import { useState, ChangeEvent, FormEvent } from 'react';
// import { submitContactInfo } from '@/services/crmService';
interface FormData {
  fullname: string;
  companyName: string;
  phoneNumber: string;
  email: string;
  companyWebsite: string;
  employees: string;
  countryCode: string;
}

const DemoForm: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    fullname: '',
    companyName: '',
    phoneNumber: '',
    email: '',
    companyWebsite: '',
    employees: '1',
    countryCode: '+84',
  });

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  const handleSubmitContact = () => {
    const requestData = {
      firstName: formData.fullname.split(' ')[0] || '',
      lastName: formData.fullname.split(' ')[1] || '',
      email: formData.email,
      phone: `${formData.countryCode} ${formData.phoneNumber}`,
      content: 'GTG',
    };
    // TODO: Submit contact info
    fetch('https://api.gtgcrm.com/v1/crm/cta', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.status === "failure") {
          message.error('Something went wrong, please try again later');
          return;
        }
        setCookie(
          'liveChatCookie',
          JSON.stringify({
            contactId: data?.contactId || "",
            workspaceId: data?.workspaceId || "",
          })
        );
        console.log('Success:', data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });

    // submitContactInfo(requestData);
  };

  return (
    <div className="w-full max-w-full  p-6 md:p-8 bg-[#FFFFFF80] rounded-3xl ">
      <h2 className="text-xl md:text-2xl font-bold mb-6 text-center md:text-left">
        Get Your Free Demo Now!
      </h2>
      <form onSubmit={handleSubmitContact}>
        <Element canvas id="form-builder-2" is={FormBox}>
          <div>
            <label
              htmlFor="fullname"
              className="block mb-2 text-xs font-medium text-[#2D3748]"
            >
              Fullname
            </label>
            <input
              type="text"
              id="fullname"
              name="fullname"
              className="bg-white border border-gray-300 text-[#2D3748] text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              placeholder="Input here"
              value={formData.fullname}
              onChange={handleChange}
              required
            />
          </div>

          <div>
            <label
              htmlFor="companyName"
              className="block mb-2 text-xs font-medium text-[#2D3748]"
            >
              Company name
            </label>
            <input
              type="text"
              id="companyName"
              name="companyName"
              className="bg-white border border-gray-300 text-[#2D3748] text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              placeholder="Input here"
              value={formData.companyName}
              onChange={handleChange}
              required
            />
          </div>

          <div>
            <label
              htmlFor="phoneNumber"
              className="block mb-2 text-xs font-medium text-[#2D3748]"
            >
              Phone number
            </label>
            <div className="flex">
              <select
                id="countryCode"
                name="countryCode"
                className="bg-white border !border-r-0 border-gray-300 text-[#2D3748] text-xs rounded-l-lg p-2.5"
                value={formData.countryCode}
                onChange={handleChange}
              >
                <option value="+84">+84</option>
                <option value="+1">+1</option>
                <option value="+44">+44</option>
                <option value="+61">+61</option>
                <option value="+33">+33</option>
                <option value="+49">+49</option>
                <option value="+81">+81</option>
              </select>
              <input
                type="text"
                id="phoneNumber"
                name="phoneNumber"
                className="bg-white border border-l-0 border-gray-300 text-[#2D3748] text-xs rounded-r-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Input here"
                value={formData.phoneNumber}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div>
            <label
              htmlFor="email"
              className="block mb-2 text-xs font-medium text-[#2D3748]"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className="bg-white border border-gray-300 text-[#2D3748] text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              placeholder="Input here"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>

          <div>
            <label
              htmlFor="companyWebsite"
              className="block mb-2 text-xs font-medium text-[#2D3748]"
            >
              Company website
            </label>
            <input
              type="text"
              id="companyWebsite"
              name="companyWebsite"
              className="bg-white border border-gray-300 text-[#2D3748] text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              placeholder="Input here"
              value={formData.companyWebsite}
              onChange={handleChange}
              required
            />
          </div>

          <div>
            <label
              htmlFor="employees"
              className="block mb-2 text-xs font-medium text-[#2D3748]"
            >
              Number of employees
            </label>
            <select
              id="employees"
              name="employees"
              className="bg-white border border-gray-300 text-[#2D3748] text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              value={formData.employees}
              onChange={handleChange}
              required
            >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5+">5+</option>
            </select>
          </div>
        </Element>

        <button
          type="submit"
          className="w-full  xl:h-[48px] bg-[#005CE6] text-white font-semibold rounded-lg text-xs px-5 py-2.5 text-center"
        >
          Register
        </button>
      </form>
    </div>
  );
};

export default DemoForm;
