import React from 'react';
import { useEditor, useNode } from '@craftjs/core';

type CosmeticsStatisticsSectionProps = {
  title?: string;
  subtitle?: string;
  description?: string;
  stats?: {
    value: string;
    label: string;
  }[];
};

const CosmeticsStatisticsSection = ({
  title = 'A global community of trust',
  subtitle = 'The numbers speak',
  description = 'Join our trusted global community for meaningful connections and growth',
  stats = [
    {
      value: '2000+',
      label: 'Monthly users',
    },
    {
      value: '$5 million',
      label: 'Revenue generated',
    },
    {
      value: '99.9%',
      label: 'Uptime percentage',
    },
    {
      value: '15%',
      label: 'Market share',
    },
  ],
}) => {
  const {
    connectors: { connect, drag },
    id,
  } = useNode();
  return (
    <section
      id={id}
      className="bg-white py-16"
      ref={connect as React.LegacyRef<HTMLDivElement>}
    >
      <div className="max-w-4xl mx-auto text-center">
        <p className="text-blue-600 font-semibold text-[16px] leading-[24px]">
          {subtitle}
        </p>
        <h2 className="text-3xl font-bold text-gray-900 my-4">{title}</h2>
        <p className="text-gray-500 mb-10">{description}</p>
        <div className="flex flex-col sm:flex-row justify-center space-y-6 sm:space-y-0 sm:space-x-6">
          {stats.map((stat, index) => (
            <div
              key={index}
              className="bg-gray-50 p-6 rounded-lg w-full sm:w-1/4"
            >
              <p className="text-2xl font-bold text-gray-900">{stat.value}</p>
              <p className="text-gray-500">{stat.label}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

const CosmeticsStatisticsSectionSettings = () => {
  const { actions, connectors, query } = useEditor();
  const {
    actions: { setProp },
    title,
    subtitle,
    description,
  } = useNode((node) => ({
    title: node.data.props.title,
    subtitle: node.data.props.subtitle,
    description: node.data.props.description,
  }));
  return (
    <div>
      <div className="mb-5">
        <label className="block mb-2 font-medium text-gray-700">Title</label>
        <input
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          defaultValue={title}
          onChange={(e) => {
            setProp(
              (props: { title: string }) => (props.title = e.target.value),
              1000
            );
          }}
        />
      </div>
      <div className="mb-5">
        <label className="block mb-2 font-medium text-gray-700">
          Sub Title
        </label>
        <input
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          defaultValue={subtitle}
          onChange={(e) => {
            setProp(
              (props: { subtitle: string }) =>
                (props.subtitle = e.target.value),
              1000
            );
          }}
        />
      </div>
      <div className="mb-5">
        <label className="block mb-2 font-medium text-gray-700">
          Description
        </label>
        <textarea
          rows={8}
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          defaultValue={description}
          onChange={(e) => {
            setProp(
              (props: { description: string }) =>
                (props.description = e.target.value),
              1000
            );
          }}
        />
      </div>
    </div>
  );
};

CosmeticsStatisticsSection.defaultProps = {
  title: 'A global community of trust',
  subtitle: 'The numbers speak',
  description:
    'Join our trusted global community for meaningful connections and growth',
  stats: [
    { value: '2000+', label: 'Monthly users' },
    { value: '$5 million', label: 'Revenue generated' },
    { value: '99.9%', label: 'Uptime percentage' },
    { value: '15%', label: 'Market share' },
  ],
};

CosmeticsStatisticsSection.craft = {
  props: CosmeticsStatisticsSection.defaultProps,
  related: {
    settings: CosmeticsStatisticsSectionSettings,
  },
  displayName: 'Cosmetics Statistics',
};

export default CosmeticsStatisticsSection;
