import AdAccountSetup from '@/components/MarketingAds/AdAccountSetup';
import AdTypeSelector from '@/components/MarketingAds/AdsCampaignCreate';
import FacebookAdCampaign from '@/components/MarketingAds/AdsFacebookPostEngagementCreate';
import { createRoute } from '@/helpers';
import React from 'react';
import { routesPath } from './constants';

const RequestsPage = React.lazy(() => import('@/pages/Marketing'));
const CampaignsPage = React.lazy(() => import('@/pages/Marketing'));
const EmailPage = React.lazy(() => import('@/pages/Marketing'));
const SocialPage = React.lazy(() => import('@/pages/Marketing'));
const AdsPage = React.lazy(() => import('@/pages/Marketing'));
const FormsPage = React.lazy(() => import('@/pages/Marketing'));

// --------------------------------------------------------------------------- ROUTER
const RouterMarketing = {
  path: routesPath.MARKETING,
  children: [
    createRoute(routesPath.MARKETING, <RequestsPage />),
    createRoute(routesPath.CAMPAIGNS, <CampaignsPage />),
    createRoute(routesPath.EMAIL_MARKETING, <EmailPage />),
    createRoute(routesPath.SOCIAL, <SocialPage />),
    createRoute(routesPath.ADS, <AdsPage />),
    createRoute(routesPath.FORMS, <FormsPage />),
  ],
};
export const RouterMarketingWithoutLayout = {
  path: routesPath.MARKETING,
  children: [
    createRoute(`${routesPath.ADS}/connect`, <AdAccountSetup />),
    createRoute(`${routesPath.ADS}/campaign/create`, <AdTypeSelector />),
    createRoute(`${routesPath.ADS}/campaign/facebook/post-engagement/ad/create`, <FacebookAdCampaign />),
    // Add more routes as needed for specific marketing actions without layout
  ],
};

export default RouterMarketing;
