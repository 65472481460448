import React from 'react';

const TermsModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 p-4">
      <div className="relative w-[90vw] max-w-4xl max-h-[80vh]">
        <div className="relative bg-white rounded-lg shadow overflow-hidden max-h-full">
          <div className="flex items-center justify-between p-4 border-b rounded-t">
            <h3 className="text-xl md:text-2xl font-semibold text-gray-900">
              Terms and Conditions
            </h3>
            <button
              onClick={onClose}
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-lg w-8 h-8 ms-auto flex justify-center items-center"
            >
              ×
            </button>
          </div>

          <div className="p-4 md:p-6 space-y-4 text-sm md:text-base leading-relaxed text-gray-500">
            <iframe
              src="https://assets.gtgcrm.com/gtgcrm/gtg-term-condition.html"
              title="Terms and Conditions"
              className="w-full h-[50vh] md:h-[60vh] border-0"
            />
          </div>

          <div className="flex items-center justify-end p-4 border-t border-gray-200 rounded-b">
            <button
              onClick={onClose}
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2"
            >
              I accept
            </button>
            <button
              onClick={onClose}
              className="ml-3 py-2 px-4 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700"
            >
              Decline
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsModal;
