import { useNode } from '@craftjs/core';
import React, { useState } from 'react';

interface FAQ {
  question: string;
  answer: string;
}

const faqs: FAQ[] = [
  {
    question: 'How do I get started with your product?',
    answer:
      "Sign up on our website, explore features, customize your profile, and start using our product. We're here to help!",
  },
  {
    question: 'What payment methods do you accept?',
    answer:
      'We accept major credit cards (Visa, Mastercard, American Express), PayPal, and bank transfers for payment.',
  },
  {
    question: 'Is there a free trial available?',
    answer:
      "Yes! We offer a free trial period for new users. You can sign up and explore our product's features and functionalities before making a commitment.",
  },
  {
    question: 'Is technical support available?',
    answer:
      'Yes, technical support is available for our product. If you encounter any issues or have questions, simply reach out to our support team.',
  },
  {
    question: 'Can I cancel my subscription?',
    answer:
      'Yes, you can cancel your subscription. Simply access your account settings and follow the cancellation instructions.',
  },
  {
    question: 'Is my data secure with your product?',
    answer:
      'Yes, your data is secure with our product. We prioritize the protection and privacy of your information.',
  },
];

const FAQ: React.FC = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const toggleFAQ = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  const {
    connectors: { connect },
    id,
  } = useNode();

  return (
    <div className="w-full py-28 px-10" id={id}>
      <h2
        id="help-section"
        className="text-xl md:text-3xl font-bold text-center mb-4"
      >
        Frequently Asked Questions
      </h2>
      <p className="text-center text-gray-600  mb-8">
        Here are some of the most frequent questions we get asked.
        <br />
        Got more questions? Visit our{' '}
        <span className="text-blue-500 underline">help center</span>.
      </p>

      <div className="hidden md:grid md:grid-cols-3 gap-10">
        {faqs.map((faq, index) => (
          <div key={index} className=" p-4 md:p-10 rounded-lg">
            <h3 className="text-black font-medium mb-2">{faq.question}</h3>
            <p className="text-gray-700 text-sm">{faq.answer}</p>
          </div>
        ))}
      </div>

      <div className="md:hidden">
        {faqs.map((faq, index) => (
          <div
            key={index}
            className="border border-gray-200 mb-2 p-4 rounded-lg"
          >
            <button
              onClick={() => toggleFAQ(index)}
              className="flex justify-between items-center w-full text-left text-black font-medium"
            >
              {faq.question}
              <span className="text-xl">{openIndex === index ? '−' : '+'}</span>
            </button>
            <div
              className={`mt-3 text-gray-700 text-sm ${
                openIndex === index ? 'block' : 'hidden'
              }`}
            >
              {faq.answer}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
