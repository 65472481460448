import { create } from "zustand";
import { IWorkspace } from "../pages/WorkSpace/types";
import { IMenu, IMenuBilling, IStep } from "./types";

type WorkspaceState = {
    step: IStep;
    setStep: (step: IStep) => void;
    workspace: IWorkspace | undefined;
    setWorkspace: (workspace: IWorkspace) => void;
    menu: IMenu;
    setMenu: (menu: IMenu) => void;
    menuBilling: IMenuBilling;
    setMenuBilling: (menu: IMenuBilling) => void;
  };
  
  export const useWorkspaceStore = create<WorkspaceState>((set) => ({
    step: IStep.initial,
    setStep: (step: IStep) => set({ step }),
    workspace: undefined,
    setWorkspace: (workspace: IWorkspace) => set({ workspace }),
    menu: IMenu.updateProfile,
    setMenu: (menu: IMenu) => set({ menu }),
    menuBilling: IMenuBilling.VIEW_OR_DOWNLOAD,
    setMenuBilling: (menuBilling: IMenuBilling) => set({ menuBilling })
  }));