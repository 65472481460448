import { GTGSIcon } from '@/components/GTGS';
import { GTGSEmpty, GTGSEmptyProps } from '@/components/GTGS/GTGSEmpty/GTGSEmpty';
import { DATA_PAGE_SIZE } from '@/constants/pagination';
import { useResponsive } from '@/hooks';
import colors from '@/theme/colors.module.scss';
import { Button, Flex, Space, Table, TableProps } from 'antd';
import classNames from 'classnames';
import omit from 'lodash/omit';
import { ReactNode, useCallback, useMemo } from 'react';
import styles from './GTGSTable.module.scss';
import { TableTitle, TableTitleProps } from './components/TableTitle/TableTitle';

//------------------------------------------

interface GTGSTableProps<T> extends TableProps<T> {
  children?: ReactNode;
  setPageCurrentTable?: (current: number) => void;
  tableTitle?: TableTitleProps;
  // ===========================================================================
  // Table - multiple select props
  // ===========================================================================
  multipleSelect?: {
    type: 'checkbox' | 'radio';
    onChangeSelectedRow?: (
      selectedRowKeys: React.Key[],
      selectedRows: T[]
    ) => void;
    getCheckboxProps?: (record: T) => object;
  };
  emptyProps?: GTGSEmptyProps;
}

const JUMP_DEFAULT = 5;

//==================  MAIN =================
const GTGSTable = <T extends object>(props: GTGSTableProps<T>) => {
  const {
    children,
    className,
    pagination,
    setPageCurrentTable,
    dataSource,
    onChange,
    emptyProps,
    multipleSelect,
    tableTitle,
    ...rest
  } = props;

  const { xs } = useResponsive();

  const paginationNew = useMemo(
    () => (typeof pagination !== 'boolean' ? pagination : {}),
    [pagination]
  );

  const lengthJumpNext = useMemo(
    () =>
      Math.ceil(
        (paginationNew?.total ?? 0) / (paginationNew?.pageSize ?? 10)
      ) || 0,
    [paginationNew?.pageSize, paginationNew?.total]
  );

  const disablePrev = useMemo(
    () => (paginationNew?.current ?? 0) === 1,
    [paginationNew]
  );

  const disableNext = useMemo(
    () => (paginationNew?.current ?? 0) === lengthJumpNext,
    [lengthJumpNext, paginationNew]
  );

  // // ---------------------------------------------------------------------------  handle JumpPrev & JumNext
  const onJumpPrev = useCallback(
    () => setPageCurrentTable?.(1),
    [setPageCurrentTable]
  );

  const onJumpNext = useCallback(() => {
    setPageCurrentTable?.(lengthJumpNext);
  }, [lengthJumpNext, setPageCurrentTable]);

  if (!dataSource || dataSource?.length === 0) {
    return (
      <Flex style={{ minHeight: 418 }} align="center" justify="center">
        <GTGSEmpty {...omit(emptyProps || {}, ['searchEmpty'])} />
      </Flex>
    );
  }

  return (
    <>
    {tableTitle && <TableTitle {...tableTitle} />}
    <Table
      className={classNames(styles.root, className)}
      pagination={
        typeof pagination === 'boolean'
          ? pagination
          : {
              itemRender: (
                __,
                type: 'page' | 'prev' | 'next' | 'jump-prev' | 'jump-next',
                originalElement
              ) => {
                if (type === 'prev') {
                  return (
                    <Space align="end">
                      <Button
                        size="small"
                        className={classNames(styles.jumpPrev, styles.button)}
                        type="text"
                        onClick={(e) => {
                          e.stopPropagation();
                          onJumpPrev();
                        }}
                        icon={
                          <GTGSIcon
                            name="double-left"
                            size={16}
                            fill={
                              disablePrev
                                ? colors.colorDark30
                                : colors.colorBasePrimary
                            }
                          />
                        }
                        title="First Page"
                        disabled={disablePrev}
                      />
                      <Button
                        size="small"
                        icon={
                          <GTGSIcon
                            name="chevron-left"
                            size={16}
                            fill={
                              disablePrev
                                ? colors.colorDark30
                                : colors.colorBasePrimary
                            }
                          />
                        }
                        type="text"
                        className={styles.button}
                        disabled={disablePrev}
                      />
                    </Space>
                  );
                }

                if (type === 'next') {
                  return (
                    <Space align="end">
                      <Button
                        size="small"
                        type="text"
                        className={classNames(styles.jumpNext, styles.button)}
                        onClick={(e) => {
                          e.stopPropagation();

                          onJumpNext();
                        }}
                        icon={
                          <GTGSIcon
                          name="double-right"
                            size={16}
                            fill={
                              disableNext
                                ? colors.colorDark30
                                : colors.colorBasePrimary
                            }
                          />                        }
                        title="Last Page"
                        disabled={disableNext}
                      />
                      <Button
                        size="small"
                        icon={
                          <GTGSIcon
                          name="chevron-right"
                            size={16}
                            fill={
                              disableNext
                                ? colors.colorDark30
                                : colors.colorBasePrimary
                            }
                        />                        }
                        type="text"
                        className={styles.button}
                        disabled={disableNext}
                      />
                    </Space>
                  );
                }
                return originalElement;
              },
              showQuickJumper: !!(
                paginationNew?.showQuickJumper && lengthJumpNext > JUMP_DEFAULT
              ),
              position: ['bottomCenter'],
              locale: { items_per_page: 'rows/page' },
              pageSizeOptions: DATA_PAGE_SIZE,
              ...pagination,
              ...(xs ? { simple: true, defaultCurrent: 1 } : {}),
            }
      }
      dataSource={dataSource}
      onChange={onChange}
      rowSelection={
        multipleSelect
          ? {
              type: multipleSelect.type,
              onChange: multipleSelect.onChangeSelectedRow,
              getCheckboxProps: multipleSelect.getCheckboxProps,
            }
          : undefined
      }
      {...rest}
    >
      {children}
    </Table>
    </>
  );
};

export {
  GTGSTable,
  type GTGSTableProps,
};

