import { useNode } from '@craftjs/core';
import { Img as EmailImage } from '@react-email/components';
import { PropsWithChildren } from 'react';
import { Settings } from './Settings';

type IImageProps = {
  localImage?: string;
  width?: number;
  height?: number;
  link?: string;
  alignment?: string;
  cornerRadius?: string;
};

export const Image = (props: PropsWithChildren<IImageProps>) => {
  const {
    connectors: { connect, drag },
  } = useNode();
  const { link, width, height, alignment } = props;

  return (
    <EmailImage
      src={link}
      width={width}
      height={height}
      ref={(ref) => ref && connect(ref!)}
    />
  );
};

export const ImageDefaultProps = {
  background: '#ffffff',
  padding: 5,
};

Image.craft = {
  props: ImageDefaultProps,
  related: {
    settings: Settings,
  },
};
