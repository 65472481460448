import React from 'react';
import { useNode } from '@craftjs/core';

import { CheckOutlined } from '@ant-design/icons';

export default function Pricing() {
  const {
    connectors: { connect, drag },
  } = useNode();

  return (
    <div
      id="pricing-section"
      className="flex flex-col md:flex-row justify-between items-center gap-10 pt-20 pb-40 w-full md:p-20 p-0"
      ref={(ref) => ref && connect(drag(ref))}
    >
      <div className="relative w-2/3 md:w-1/3 border rounded-lg shadow-md transition-transform duration-300 px-2 md:px-4 py-4 md:py-8 h-auto">
        <div className="absolute top-0 left-0 right-0 h-auto flex flex-col items-start justify-start rounded-t-lg px-4 bg-white text-gray-800">
          <div className="flex items-center space-x-2 mb-2 mt-2">
            <span className="p-2 rounded-md bg-[#E5F0FF]">
              <div className="relative w-10 h-10 rounded-full bg-white flex items-center justify-center">
                <div className="absolute left-0 w-1/2 h-full bg-blue-600 rounded-l-full"></div>
              </div>
            </span>
            <div className="flex flex-col">
              <p className="text-sm mt-4 text-[#6F6C90]">For trial</p>
              <h2 className="text-2xl font-semibold">FREE PLAN</h2>
            </div>
          </div>
          <p className="text-sm mt-2 text-start text-balance px-2 text-[#6F6C90]">
            Experience the Full Power of Pro
          </p>
        </div>
        <div className="text-start mt-40 mb-6 px-4">
          <p className="text-4xl font-bold">
            $0
            <span className="text-lg font-medium text-gray-500">/7 days</span>
          </p>
        </div>
        <div className="px-4">
          <p className="text-lg font-semibold mb-4">What's included</p>
          <ul className="space-y-4">
            <li className="flex items-center">
              <span className="bg-blue-600 rounded-full flex items-center justify-center text-white mr-3 p-1">
                <CheckOutlined className="text-white" />
              </span>
              <span className="text-md font-light">
                All Features Unlocked During Your Free Trial
              </span>
            </li>
          </ul>
        </div>
        <div className="mt-6 px-4">
          <button className="w-full  text-sm py-2 md:py-4 rounded border border-[#0D0F2E] text-[#0D0F2E]">
            Register
          </button>
        </div>
      </div>

      <div className="relative w-full md:w-2/3 border rounded-lg shadow-md transition-transform duration-300 px-4 py-8 h-auto">
        <div className="absolute top-0 left-0 right-0 h-auto flex flex-col items-start justify-start rounded-t-lg px-4 bg-blue-600 text-white">
          <div className="flex items-center space-x-2 mb-2 mt-2">
            <span className="p-2 rounded-md bg-white">
              <div className="relative w-10 h-10 bg-[#E5F0FF]">
                <div className="absolute left-0 top-0 w-1/2 h-full bg-blue-600"></div>
              </div>
            </span>
            <div className="flex flex-col">
              <p className="text-sm mt-4 text-white">For startups</p>
              <h2 className="text-2xl font-semibold">PRO PLAN</h2>
            </div>
          </div>
          <p className="text-sm mt-2 text-start text-balance px-2 text-white">
            After you sign-up, you will have 7 days free to enjoy all features.
            Don't hesitate to join with us!
          </p>
          <div className="absolute top-2 right-0 px-4 py-2 text-xs font-medium bg-white text-gray-800 rounded-l-lg">
            Popular
          </div>
        </div>
        <div className="text-start mt-40 mb-6 px-4">
          <p className="text-4xl font-bold">
            $20
            <span className="text-lg font-medium text-gray-500">
              /seat/month
            </span>
          </p>
        </div>
        <div className="px-4">
          <p className="text-lg font-semibold mb-4">What's included</p>
          <ul className="space-y-4">
            {[
              'Streamline Your Sales with CRM for Contacts, Companies, and Task Management!',
              'Build and Launch Stunning Landing Pages with Our Premium Templates',
              'Create Custom Forms That Capture What Matters - Right on Your Landing Page!',
              'Design Powerful Marketing Emails with Ready-to-Use Templates',
              'Unlock Insights: Track Your Landing Page Metrics to Understand Visitor Behavior!',
              'Engage Effortlessly: Connect with Customers via Live Chat & Messaging!',
              'Boost Efficiency with Automation Workflows for Seamless Business Processes!',
            ].map((feature, idx) => (
              <li key={idx} className="flex items-center">
                <span className="bg-blue-600 rounded-full flex items-center justify-center text-white mr-3 p-1">
                  <CheckOutlined className="text-white" />
                </span>
                <span className="text-md font-light">{feature}</span>
              </li>
            ))}
          </ul>
        </div>
        <div className="mt-6 px-4">
          <button className="w-full  text-sm py-2 md:py-4 rounded bg-blue-600 text-white">
            SIGN UP AND ENJOY 7 DAYS OF FULL ACCESS TO ALL FEATURES—JOIN US
            TODAY!
          </button>
        </div>
      </div>
    </div>
  );
}
