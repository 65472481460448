import {
  Breadcrumb as AntdBreadcrumb,
  BreadcrumbProps as AntdBreadcrumbProps,
  Divider,
  Flex,
} from 'antd';
import { Link } from 'react-router-dom';
import { trans } from '@/i18n';
import { memo } from 'react';
import styles from './styles.module.scss';
import { GTGSIcon } from '../GTGSIcon/GTGSIcon';
import { routesPath } from '../../../router/constants';

export const BREADCRUMDS = {
  CRM: {
    title: trans('common.breadcumds.crm'),
    path: '/crm',
  },
  CONTACTS: {
    title: trans('common.breadcumds.contacts'),
    path: '/crm/contacts',
  },
  COMPANIES: {
    title: trans('common.breadcumds.companies'),
    path: '/crm/companies',
  },
  DEALS: {
    title: trans('common.breadcumds.deals'),
    path: '/crm/deals',
  },
  TASKS: {
    title: trans('common.breadcumds.tasks'),
    path: '/crm/tasks',
  },
  DASHBOARD: {
    title: trans('common.breadcumds.dashboard'),
    path: '/',
  },
  MARKETING: {
    title: trans('common.breadcumds.marketing'),
    path: '/marketing',
  },
  EMAIL: {
    title: trans('common.breadcumds.email'),
    path: '/marketing/email-builder',
  },
  AUTOMATED_EMAIL_TEMPLATE: {
    title: trans('common.breadcumds.automatedEmailTemplate'),
    path: routesPath.EMAIL_BUILDER_AUTOMATED_TEMPLATES,
  },
  ADS: {
    title: trans('common.breadcumds.ads'),
    path: '/ads',
  },

  LANDING_PAGES: {
    title: trans('common.breadcumds.landingPages'),
    path: '/content/landing-pages',
  },
  LIBRARY: {
    title: trans('common.breadcumds.library'),
    path: '/library',
  },
  MESSAGE_TEMPLATES: {
    title: trans('common.breadcumds.messageTemplates'),
    path: '/messaging-templates',
  },
  STAFF: {
    title: trans('common.breadcumds.staff'),
    path: '/staff',
  },
  NOTIFICATION_SETTING: {
    title: trans('common.breadcumds.notificationSetting'),
    path: '/account/notification-setting',
  },
  SETTINGS: {
    title: trans('common.breadcumds.settings'),
    path: '/settings',
  },
  ACCESS_LEVEL_CONTROL: {
    title: trans('common.breadcumds.accessLevelControl'),
    path: routesPath.ACCESS_LEVEL_CONTROL,
  },
  USER_MANAGEMENT: {
    title: trans('common.breadcumds.userManagement'),
    path: routesPath.USER_MANAGEMENT,
  },
  DELETE_WORKSPACE: {
    title: trans('common.breadcumds.deleteWorkspace'),
    path: routesPath.USER_MANAGEMENT,
  },
};

interface GTGSBreadcrumbProps extends AntdBreadcrumbProps {
  title?: string;
  backComponent?: any;
  extra?: React.ReactNode;
}

const _GTGSBreadcrumb = ({
  items,
  title,
  backComponent,
  extra,
  ...rest
}: GTGSBreadcrumbProps) => {
  function itemRender(currentRoute, _, items, __) {
    const isLast = currentRoute?.path === items[items.length - 1]?.path;
    const isBackItem = currentRoute?.path === items[items.length - 2]?.path;

    return isLast ? (
      <span className={`${styles['breadcrumb-item']} ${styles.active}`}>
        {currentRoute.title}
      </span>
    ) : isBackItem && backComponent ? (
      backComponent
    ) : (
      <Link to={currentRoute?.path} className={styles['breadcrumb-item']}>
        {currentRoute.title}
      </Link>
    );
  }

  return (
    <Flex
      justify="space-between"
      align="center"
      className="w-full py-[12px] px-[30px] border-b border-b-1 border-[#E5E7EB]/[0.2]"
    >
      <div className={styles['breadcrumb-container']}>
        {title && (
          <>
            <span className={styles['breadcrumb-title']}>{title}</span>
            <Divider
              type="vertical"
              className={styles['breadcrumb-separator']}
            />
          </>
        )}
        <AntdBreadcrumb
          items={items}
          itemRender={itemRender}
          {...rest}
          separator={
            <GTGSIcon name="chevron-right" fill="currentColor" size={12} />
          }
        />
      </div>
      {extra || null}
    </Flex>
  );
};

const GTGSBreadcrumb = memo(_GTGSBreadcrumb);

export { GTGSBreadcrumb, type GTGSBreadcrumbProps };
//Initiated Result
//Review & Confirm
