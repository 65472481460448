import { createBrowserRouter, Outlet } from 'react-router-dom';
import App from './App';
import NotFound from './pages/NotFound/Loadable';
import AccessDenied from './pages/Errors/AccessDenied';
import RouterBookmark from './router/RouterBookmark';
import RouterMarketing, {
  RouterMarketingWithoutLayout,
} from './router/RouterMarketing';
import RouterCommerce from './router/RouterCommerce';
import ErrorPage from './pages/Errors/ErrorPage';
import Guideline from './pages/Guideline/Guideline';
import { RouterGuideline } from './pages/Guideline/routerConfig';
import RouterReporting from './router/RouterReporting';
import RouterContent, {
  RouterContentWithoutLayout,
} from './router/RouterContent';
import RouterCRM, {
  RouterCRMWithoutLayout,
  RouterCRMWithoutLayoutCompany,
} from './router/RouterCRM';
import RouterSettings from './router/RouterSettings';
import RouterAutomations from './router/RouterAutomations';
import RouterChannels from './router/RouterChannels';
import RouterPricing from './router/RouterPricing';
import RouterLibrary from './router/RouterLibrary';
import RouterMessaging from './router/RouterMessaging';
import RouterWorkSpace from './router/RouterWorkSpace';
import RouterMetric from './router/RouterMetric';
import RouterPaymentPlan from './router/RouterPaymentPlan';
import LayoutBlank from './components/Layout/LayoutBlank';
import { routesPath } from './router/constants';
import RouterEmailBuilder from './router/RouterEmailBuilder';
// ===========================================================================
// MAIN
// ===========================================================================

const router = createBrowserRouter([
  {
    element: <LayoutBlank />,
    children: [
      RouterMarketingWithoutLayout,
      RouterContentWithoutLayout,
      RouterCRMWithoutLayout,
      RouterCRMWithoutLayoutCompany,
      RouterPaymentPlan,
      RouterWorkSpace,
      RouterPricing,
    ],
  },
  {
    path: '/',
    element: <App />,
    children: [
      RouterCommerce,
      RouterBookmark,
      RouterMarketing,
      RouterReporting,
      RouterContent,
      RouterCRM,
      RouterChannels,
      RouterAutomations,
      RouterLibrary,
      RouterMessaging,
      RouterMetric,
      RouterSettings,
      // RouterPaymentPlan,
      // RouterPaymentPlan,
      RouterEmailBuilder
    ],
  },

  {
    path: '/guideline',
    element: <Guideline />,
    children: RouterGuideline.map((item) => ({
      path: item.path,
      element: item.element,
    })),
  },
  // {
  //   ...RouterPricing,
  // },
  // {
  //   ...RouterWorkSpace,
  // },
  {
    path: '/403',
    element: <AccessDenied />,
  },
  {
    path: '*',
    element: <NotFound />,
  },
]);
export default router;
