import { AutoComplete as AntdAutoComplete, AutoCompleteProps } from 'antd';
import classNames from 'classnames';
import { memo } from 'react';
import isEqual from 'react-fast-compare';
import { colorTextSecondary } from '../GTGSColor/GTGSColor';
import { GTGSIcon } from '../GTGSIcon/GTGSIcon';
import styles from './styles.module.scss';
interface GTGCAutoCompleteProps extends AutoCompleteProps {}

function _GTGSAutoComplete(props: GTGCAutoCompleteProps) {
  const { children, className, size = 'large', suffixIcon, ...rest } = props;

  return (
    <div>
      <AntdAutoComplete
        style={
          props.value && props.disabled ? { color: colorTextSecondary } : {}
        }
        suffixIcon={
          suffixIcon ? <GTGSIcon name="caret-down" size={16} /> : null
        }
        className={classNames(
          styles.customAutocomplete,
          {
            [styles.inputDisabled]: !!props.disabled,
            [styles.inputFillDisabled]: props.disabled && props.value,
            [styles.heightLarge]: size === 'large',
            [styles.heightSmall]: size === 'middle' || size === 'small',
          },
          className
        )}
        size={size}
        {...rest}
      >
        {children}
      </AntdAutoComplete>
    </div>
  );
}

const GTGSAutoComplete = memo(_GTGSAutoComplete, isEqual);

export { GTGSAutoComplete, type GTGCAutoCompleteProps };

