import { createRoute } from '@/helpers';
import React from 'react';
import { routesPath } from './constants';


const CasesPage = React.lazy(() => import('@/pages/Cases'));

// --------------------------------------------------------------------------- ROUTER
const RouterReporting = {
  path: routesPath.REPORTING,
  children: [
    createRoute(routesPath.REPORTING, <CasesPage />),
  ],
};

export default RouterReporting;
