import { memo } from 'react';
import { FormItem } from '../../Form';
import { ColorPicker } from '../../SettingControls/ColorPicker';
import { Flex, Radio } from 'antd';
import { GTGSInput } from '@/components/GTGS';
export const DividerSettings = memo(() => {
  return (
    <>
      <Flex gap={16}>
        <FormItem className="flex-1" name="select" label="Height">
          <GTGSInput />
        </FormItem>
        <FormItem className="flex-1" name="dividerColor" label="Color">
          <ColorPicker />
        </FormItem>
      </Flex>
      <FormItem name="dividerStyle" label="Style">
        <Radio.Group>
          <Radio value="solid">Solid</Radio>
          <Radio value="dotted">Dotted</Radio>
          <Radio value="dashed">Dashed</Radio>
        </Radio.Group>
      </FormItem>
    </>
  );
});
