import { memo } from 'react';
import { Form, FormItem } from '../../Form';
import { ColorPicker } from '../../SettingControls/ColorPicker';
import { Flex, Select } from 'antd';
import { FontStyle } from '../../SettingControls/FontStyle';
import { GTGSInput } from '@/components/GTGS';
import { FontSelect } from '../../SettingControls/FontSelect';
import { FontSizeSelect } from '../../SettingControls/FontSizeSelect';

export const ButtonSettings = memo(() => {
  return (
    <>
      <Flex gap={8}>
        <FormItem className="flex-1" name="radius" label="Radius">
          <GTGSInput />
        </FormItem>
        <FormItem className="flex-1" name="buttonColor" label="Button color">
          <ColorPicker />
        </FormItem>
      </Flex>
      {/* Font */}
      <FormItem label={'Font'}>
        <Flex gap={8}>
          <FormItem noStyle name={'buttonFont'}>
            <FontSelect />
          </FormItem>
          <FormItem noStyle name={'buttonFontSize'}>
            <FontSizeSelect />
          </FormItem>
        </Flex>
      </FormItem>
      <Flex>
        <FormItem className="flex-1" name="textColor" label="Text color">
          <ColorPicker />
        </FormItem>
        <Flex className="flex-1">
          <FontStyle name="text" label="Style" />
        </Flex>
      </Flex>
    </>
  );
});
