import { Select, SelectProps } from 'antd';

export const LineHeightSelect = (props: SelectProps) => {
  return (
    <Select
      placeholder={'Select line height'}
      size="large"
      options={[
        {
          label: '1.75',
          value: 1.75,
        },
      ]}
      {...props}
    />
  );
};
