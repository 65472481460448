/* eslint-disable no-underscore-dangle */
const getEnv = (key: string): string => {
  return process.env[key] || '';
};

const config = {
  gatewayUrl: getEnv('REACT_APP_GATEWAY_API_URL'), 
  common: {
    COMMON_API_URL: getEnv('REACT_APP_GATEWAY_API_URL'),
    VERSION: getEnv('REACT_APP_VERSION'),
    FACEBOOK_APP_ID: getEnv('REACT_APP_FACEBOOK_APP_ID'),
  },
  socket: {
    SOCKET_API_URL: getEnv('REACT_APP_SOCKET_IO_URL'),
  },
  payment: {
    CHARGEBEE_SITE: getEnv('REACT_APP_CHARGEBEE_SITE'),
    CHARGEBEE_PUBLISHABLE_API_KEY: getEnv('REACT_APP_CHARGEBEE_PUBLISHABLE_API_KEY'),
  },
  // Keycloak
  keycloak: {
    ENDPOINT: getEnv('REACT_APP_KEYCLOAK_ENDPOINT'),
    REALM: getEnv('REACT_APP_KEYCLOAK_REALM'),
    CLIENTID: getEnv('REACT_APP_KEYCLOAK_CLIENTID'),
  },
  notification: {
    NOTIFICATION_API_URL: getEnv('REACT_APP_NOTIFICATION_API_URL'),
  },
  firebase_notification: {
    API_KEY: getEnv('REACT_APP_NOTIFICATION_API_KEY'),
    AUTH_DOMAIN: getEnv('REACT_APP_NOTIFICATION_AUTH_DOMAIN'),
    PROJECT_ID: getEnv('REACT_APP_NOTIFICATION_PROJECT_ID'),
    STORAGE_BUCKET: getEnv('REACT_APP_NOTIFICATION_STORAGE_BUCKET'),
    MESSAGING_SENDER_ID: getEnv('REACT_APP_NOTIFICATION_MESSAGING_SENDER_ID'),
    APP_ID: getEnv('REACT_APP_NOTIFICATION_APP_ID'),
    MEASUREMENT_ID: getEnv('REACT_APP_NOTIFICATION_MEASUREMENT_ID'),
    VALIDKEY: getEnv('REACT_APP_NOTIFICATION_VALIDKEY'),
  },
  metric: {
    API_URL: getEnv('REACT_APP_MATOMO_API_URL'),
    AUTH_TOKEN: getEnv('REACT_APP_MATOMO_TOKEN_AUTH'),
  },
};

export default config;
