import theme from '@/theme/themeConfig';
import { ConfigProvider } from 'antd';
import { useMemo } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import ScrollToTop from '../ScrollToTop';
const AppProvider = ({ children }) => {
  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            retry: false,
            keepPreviousData: true,
          },
        },
      }),
    []
  );

  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider theme={theme}>
        <ScrollToTop />
        {children}
      </ConfigProvider>
    </QueryClientProvider>
  );
};

export default AppProvider;
