import { useEditor } from '@craftjs/core';
import { GTGSButton, GTGSIcon, GTGSTypography } from '@/components/GTGS';
import {
  getLandingPageState,
  publishPage,
  saveState,
} from '@/services/pageBuilderService';
import { ConfigProvider, Flex, message, Segmented, Space } from 'antd';
import copy from 'copy-to-clipboard';
import lz from 'lzutf8';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { DesktopOutlined, MobileOutlined } from '@ant-design/icons';
import { usePageBuilder } from '../store/usePageBuilderStore';
import { useShallow } from 'zustand/react/shallow';
import { useParams } from 'react-router-dom';
import { useMemo } from 'react';

export const HeaderSetting = () => {
  const { device, setDevice } = usePageBuilder(
    useShallow((state) => ({
      device: state.device,
      setDevice: state.setDevice,
    }))
  );
  const { id } = useParams();

  const navigate = useNavigate();
  message.config({
    duration: 5,
    // top: 96,
  });

  const { nodes, query, enabled, canUndo, canRedo, actions } = useEditor(
    (state, query) => {
      return {
        nodes: state.nodes,
        enabled: state.options.enabled,
        canUndo: query.history.canUndo(),
        canRedo: query.history.canRedo(),
      };
    }
  );

  // TODO: move to hook to limit call api
  const { data } = useQuery(['getLatestState', id], () =>
    getLandingPageState(id || '', 'latest')
  );

  const json = query.serialize();
  const coreBase64 = useMemo(() => {
    return lz.encodeBase64(lz.compress(json));
  }, [json]);

  const editorChanged = useMemo(() => {
    return data?.dataChanged !== coreBase64;
  }, [data?.dataChanged, coreBase64]);

  const formNodes = Object.values(nodes).filter((n) =>
    n.data.displayName.includes('GTGFORM')
  );

  const hasUndefinedFields = useMemo(
    () => formNodes.map((node) => node.data.props.name).some((item) => !item),
    [formNodes]
  );

  const { mutate: saveLatestState } = useMutation(
    ({
      landingPageId,
      changedData,
    }: {
      landingPageId: string;
      changedData: string;
    }) => saveState(landingPageId, changedData),
    {
      onSuccess: () => {
        message.success({
          content: 'Save successfully',
        });
      },
    }
  );

  const { mutate: publishLandingPage } = useMutation(
    'publishPage',
    publishPage,
    {
      onSuccess: (data) => {
        message.success({
          content: (
            <Flex vertical gap={0} align="flex-start">
              <GTGSTypography.Text>Published successfully</GTGSTypography.Text>
              <GTGSTypography.Text>
                View it live here:{' '}
                {data.domains &&
                  data.domains.length > 0 &&
                  data.domains.map((domain: string, index: number) => (
                    <span key={index}>
                      <a href={`${domain}`} target="_blank" rel="noreferrer">
                        {domain}
                      </a>
                      {index < data.domains.length - 1 && ', '}
                    </span>
                  ))}
              </GTGSTypography.Text>
            </Flex>
          ),
        });
      },
    }
  );

  return (
    <Flex
      justify="space-between"
      style={{ background: '#0F172A', height: '100%' }}
    >
      <Space>
        <GTGSButton onClick={() => navigate('/content/landing-pages')}>
          EXIT
        </GTGSButton>
        <ConfigProvider
          theme={{
            components: {
              Segmented: {
                trackPadding: 0,
                itemSelectedBg: '#005CE6',
                trackBg: '#FFFFFF26',
                itemColor: '#fff',
                itemSelectedColor: '#fff',
                itemHoverColor: '#fff',
              },
            },
          }}
        >
          <Segmented
            value={device}
            onChange={(value) => setDevice(value as 'desktop' | 'mobile')}
            options={[
              { value: 'mobile', icon: <MobileOutlined /> },
              { value: 'desktop', icon: <DesktopOutlined /> },
            ]}
          />
        </ConfigProvider>
        <Flex>
          <ConfigProvider
            theme={{
              components: {
                Button: {
                  colorBgContainerDisabled: '#FFFFFF26',
                  borderColorDisabled: '#FFFFFF26',
                },
              },
            }}
          >
            <GTGSButton
              onClick={() => actions.history.undo()}
              disabled={!canUndo || !editorChanged}
              className="rounded-tr-none rounded-br-none p-[10px]"
              type="primary"
            >
              <GTGSIcon
                fill={!canUndo || !editorChanged ? '#929BB2' : '#fff'}
                name="undo"
                size={14}
              />
            </GTGSButton>
            <GTGSButton
              disabled={!canRedo}
              className="rounded-tl-none rounded-bl-none p-[10px]"
              type="primary"
              onClick={() => actions.history.redo()}
            >
              <GTGSIcon
                fill={canRedo ? '#fff' : '#929BB2'}
                name="redo"
                size={14}
              />
            </GTGSButton>
          </ConfigProvider>
        </Flex>
      </Space>
      <Space>
        <GTGSTypography.Text style={{ color: '#fff', fontSize: 24 }}>
          Landing Page Name
        </GTGSTypography.Text>
      </Space>
      <Space>
        <GTGSButton
          type="text"
          style={{ color: '#fff' }}
          onClick={() => {
            if (hasUndefinedFields) {
              message.error('Field name is missing in the form');
              return;
            }
            const json = query.serialize();
            const landingPageId = id || '';
            const changedData = lz.encodeBase64(lz.compress(json));

            saveLatestState({ landingPageId, changedData });
          }}
        >
          SAVE
        </GTGSButton>

        <a
          href={
            hasUndefinedFields ? '#' : `/content/landing-pages/${id}/preview`
          }
          target="_blank"
          onClick={(e) => {
            if (hasUndefinedFields) {
              e.preventDefault();
              message.error('Field name is missing in the form');
            }
          }}
        >
          <GTGSButton type="text" style={{ color: '#fff' }}>
            PREVIEW
          </GTGSButton>
        </a>
        <GTGSButton
          type="primary"
          onClick={() => {
            if (hasUndefinedFields) {
              message.error('Field name is missing in the form');
              return;
            }
            const json = query.serialize();
            copy(lz.encodeBase64(lz.compress(json)));
            saveLatestState({
              landingPageId: id || '',
              changedData: lz.encodeBase64(lz.compress(json)),
            });

            const payload = {
              pageId: id || '',
              dataChanged: lz.encodeBase64(lz.compress(json)),
              templateId: '2115795f-f1f2-4c3d-b412-cf8ccd581696',
              templateCode: 'gtg_cosmetics',
            };

            publishLandingPage(payload);
          }}
        >
          PUBLISH
        </GTGSButton>
      </Space>
    </Flex>
  );
};
