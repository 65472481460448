import React, { useState } from 'react';
import { useNode } from '@craftjs/core';
import ChatButton from '@/pages/PageBuilder/components/live-chat/ChatButton';
import { AiOutlineArrowDown } from 'react-icons/ai';
import { FiMenu } from 'react-icons/fi';
import { GTGSIcon } from '@/components/GTGS/GTGSIcon/GTGSIcon';

const Header: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const {
    connectors: { connect, drag },
  } = useNode();
  const scrollToSection = (sectionId) => {
    const targetSection = document.getElementById(sectionId);
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <header
      ref={(ref) => {
        if (ref) {
          connect(drag(ref));
        }
      }}
      className="relative px-11 md:px-36 bg-cover bg-no-repeat bg-center h-[60vh] md:h-[80vh]"
      style={{ backgroundImage: `url(/cosmetic/cosmetic-bg.png)` }}
    >
      <div className="container flex items-center justify-between py-6">
        <div className=" flex items-center">
          <img src="/images/logo/logo-template.svg" alt="logo" />
        </div>

        <div className="hidden xl:flex items-center space-x-8">
          <nav className="text-[16px] font-semibold space-x-8 text-[#183B56]">
            <span className="hover:text-gray-800 transition cursor-pointer">
              Home
            </span>
            <span className="hover:text-gray-800 transition cursor-pointer">
              Landings
            </span>
            <span className="hover:text-gray-800 transition cursor-pointer">
              Pages
            </span>
            <span className="hover:text-gray-800 transition cursor-pointer">
              Docs
            </span>
            <span className="hover:text-gray-800 transition cursor-pointer">
              Help
            </span>
          </nav>
          <button className="bg-transparent border-2 border-[#1565D8] text-[#1565D8] font-semibold py-2 px-6 rounded-md transition hover:bg-blue-600 hover:text-white">
            Get it now
          </button>
        </div>

        <div className="xl:hidden relative">
          <button onClick={() => setIsMenuOpen(!isMenuOpen)}>
            <FiMenu className="text-2xl text-[#183B56]" />
          </button>

          {isMenuOpen && (
            <div className="absolute right-0 top-full mt-2 w-36 bg-white shadow-lg rounded-lg flex flex-col items-start py-2 z-50">
              <nav className="flex flex-col items-start p-4 space-y-4 text-[#183B56] font-semibold">
                <span className="hover:text-gray-800 transition cursor-pointer">
                  Home
                </span>
                <span className="hover:text-gray-800 transition cursor-pointer">
                  Landings
                </span>
                <span className="hover:text-gray-800 transition cursor-pointer">
                  Pages
                </span>
                <span className="hover:text-gray-800 transition cursor-pointer">
                  Docs
                </span>
                <span className="hover:text-gray-800 transition cursor-pointer">
                  Help
                </span>
                <span className="hover:text-gray-800 transition cursor-pointer">
                  Get it now
                </span>
              </nav>
            </div>
          )}
        </div>
      </div>

      <div className="flex flex-col items-start justify-center h-full text-left max-w-2xl mb-5">
        <h2 className="text-[#36B37E] text-xs md:text-[18px] font-semibold">
          Made from Korea
        </h2>
        <h1 className="font-bold text-[#183B56] text-2xl md:text-[56px] leading-tight md:mt-2">
          Natural Cosmetic <br /> Purity for Every Skin
        </h1>
        <p className="text-[#5A7184] text-xs md:text-[18px] md:mt-4 max-w-xl leading-relaxed">
          Cosmetics with 100% Natural Ingredients. Processing by modern machine
          and research by many Cosmetic & Dermatologist Specialists in Korea.
        </p>
        <button
          onClick={() => scrollToSection('fact-section')}
          className="md:mt-4 flex items-center justify-center w-10 h-10 bg-transparent border border-[#183B56] rounded-full hover:bg-[#183B56] hover:text-white transition"
        >
          <GTGSIcon name="arrow-down" size={17}></GTGSIcon>
        </button>
      </div>

      <ChatButton />
    </header>
  );
};

export default Header;
