import React, { lazy, Suspense } from 'react';
import { useEditor, useNode } from '@craftjs/core';
import Header from './Header';
import FormSetting from '@/pages/PageBuilder/components/form-builder/FormSetting';

const FormBuilder = lazy(
  () => import('@/pages/PageBuilder/components/form-builder/FormBuilder')
);

type TopBannerProps = {
  title: string;
  subTitle: string;
  description: string;
  formTitle: string;
  buttonText: string;
};

function TopBanner({
  title,
  subTitle,
  description,
  formTitle,
  buttonText,
}: TopBannerProps) {
  const {
    connectors: { connect, drag },
    id,
  } = useNode();

  return (
    <>
      <div
        style={{
          backgroundImage: `url('/bg-water.png')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
        className="min-h-full py-20 pt-0 bg-white flex"
        ref={connect as unknown as React.RefObject<HTMLDivElement>}
        id={id}
      >
        <style>
          {`
            @media (max-width: 768px) {
              div[style] {
                background-image: url('/bg-2.png') !important; /* Use different background for mobile screens */
                background-position: left bottom !important;
              }
            }
          `}
        </style>
        <div className="w-full h-auto px-6 md:px-[150px]">
          <Header />
          <div className="bg-transparent grid grid-cols-1 md:grid-cols-2 gap-0 items-center justify-end">
            <div
              className="md:h-[430px] max-w-[590px] text-[#0F172A]flex justify-start"
              ref={connect as unknown as React.RefObject<HTMLDivElement>}
            >
              <h1 className="font-light leading-tight mb-2">
                <span className="block text-[20px] xl:text-[44px]">
                  {subTitle}
                </span>
                <span className="block text-[30px] xl:text-[64px] font-bold tracking-tight">
                  {title}
                </span>
              </h1>
              <p className="w-full font-normal md:text-[13px] xl:text-[18px] break-words">
                {description}
              </p>
            </div>

            <div className=" w-[90%] justify-center md:w-full flex md:justify-end ">
              <div className="w-full max-w-full  p-6 md:p-8 bg-[#FFFFFF80] rounded-3xl ">
                <h2 className="text-xl md:text-2xl font-bold mb-6 text-center md:text-left">
                  {formTitle}
                </h2>
                <Suspense fallback={<div>Loading...</div>}>
                  <FormBuilder buttonText={buttonText} />
                </Suspense>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

TopBanner.defaultProps = {
  title: 'With GTG CRM',
  subTitle: 'Unleash Your Potential',
  description:
    'Effortlessly connect your data, teams, and customers on a single AI-driven customer platform that scales with your business.',
  formTitle: 'Get Your Free Demo Now!',
  buttonText: 'Register',
};

const TopBannerSettings = () => {
  const { actions, connectors, query } = useEditor();
  const {
    actions: { setProp },
    title,
    subTitle,
    description,
    formTitle,
    buttonText,
  } = useNode((node) => ({
    title: node.data.props.title,
    subTitle: node.data.props.subTitle,
    description: node.data.props.description,
    formTitle: node.data.props.formTitle,
    buttonText: node.data.props.buttonText,
  }));
  return (
    <div>
      <div className="mb-5">
        <label className="block mb-2 font-medium text-gray-700">Title</label>
        <input
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          defaultValue={title}
          onChange={(e) => {
            setProp(
              (props: { title: string }) => (props.title = e.target.value),
              1000
            );
          }}
        />
      </div>
      <div className="mb-5">
        <label className="block mb-2 font-medium text-gray-700">
          Sub Title
        </label>
        <input
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          defaultValue={subTitle}
          onChange={(e) => {
            setProp(
              (props: { subTitle: string }) =>
                (props.subTitle = e.target.value),
              1000
            );
          }}
        />
      </div>
      <div className="mb-5">
        <label className="block mb-2 font-medium text-gray-700">
          Description
        </label>
        <textarea
          rows={8}
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          defaultValue={description}
          onChange={(e) => {
            setProp(
              (props: { description: string }) =>
                (props.description = e.target.value),
              1000
            );
          }}
        />
      </div>
      <div className="mb-5">
        <label className="block mb-2 font-medium text-gray-700">
          Form Title
        </label>
        <input
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          defaultValue={formTitle}
          onChange={(e) => {
            setProp(
              (props: { formTitle: string }) =>
                (props.formTitle = e.target.value),
              1000
            );
          }}
        />
      </div>
      <FormSetting />
      <div className="my-5">
        <label className="block mb-2 font-medium text-gray-700">Button</label>
        <input
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          defaultValue={buttonText}
          onChange={(e) => {
            setProp(
              (props: { buttonText: string }) =>
                (props.buttonText = e.target.value),
              1000
            );
          }}
        />
      </div>
    </div>
  );
};

TopBanner.craft = {
  props: TopBanner.defaultProps,
  related: {
    settings: TopBannerSettings,
  },
  displayName: 'Top Banner',
};

export default TopBanner;
