import React, { Suspense, lazy } from 'react';
import { FaFacebookF, FaLinkedinIn } from 'react-icons/fa';
// import DemoForm from './InfoForm';
import { useNode } from '@craftjs/core';
const FormBuilder = lazy(
  () => import('@/pages/PageBuilder/components/form-builder/FormBuilder')
);
const Footer: React.FC = () => {
  const {
    connectors: { connect },
    id,
  } = useNode();
  return (
    <div
      id={id}
      style={{
        backgroundImage: `url('/bg-2.png')`,
        backgroundSize: 'cover',
      }}
      className="min-h-[50vh] w-full py-20 pt-0 bg-transparent flex relative"
    >
      <div className="container mx-auto flex flex-col-reverse md:flex-row items-center justify-between gap-6">
        <div className="md:w-1/2 w-full flex justify-center items-center relative z-10">
          {' '}
          <div className="w-[90%] p-6 rounded-lg">
            <Suspense fallback={<div>Loading...</div>}>
              <FormBuilder buttonText={'Register'} />
            </Suspense>
          </div>
        </div>

        <div className="flex flex-col items-center md:items-center text-center px-4 md:w-1/2">
          <h2 className="text-[28px] md:text-[36px] font-bold text-[#0F172A] mb-2">
            Ready to Explore?
          </h2>
          <p className="text-[14px] md:text-[18px] text-[#0F172A] mb-4">
            We're dedicated to helping your entire team stay ahead of the game
            as your business expands.
          </p>

          <div className="flex flex-col items-center md:items-start justify-center space-y-4 mt-6 md:mt-0">
            <div className="text-2xl font-bold text-[#005CE6]">
              <span className="font-extrabold">GTG</span>{' '}
              <span className="bg-[#005CE6] text-white px-2 py-1 rounded">
                CRM
              </span>
            </div>

            <div className="flex items-center justify-center md:justify-start space-x-4 mt-4">
              <div className="flex space-x-4">
                <a
                  href="https://gtgsoft.com/"
                  className="p-2 bg-gray-200 rounded-full text-[#0F172A] hover:bg-gray-300"
                >
                  <FaFacebookF />
                </a>
                <a
                  href="https://gtgsoft.com/"
                  className="p-2 bg-gray-200 rounded-full text-[#0F172A] hover:bg-gray-300"
                >
                  <FaLinkedinIn />
                </a>
              </div>
              <p className="text-[12px] text-gray-500">© 2024. GTG CRM</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
