import config from '../config';
import httpService from './httpService';
import { customResponsePagination } from '@/helpers';

const baseUrl = `${config.gatewayUrl}/v1`;

export const getLandingPageState = async (
  landingPageId: string,
  versionId: string
) => {
  const { data } = await httpService.get(
    `${baseUrl}/pages/landing-pages/${landingPageId}/versions/${versionId}`
  );
  return data;
};

export const getLandingPageDetail = async (landingPageId: string) => {
  const { data } = await httpService.get(
    `${baseUrl}/pages/landing-pages/${landingPageId}`
  );
  return data;
};

export const saveState = async (landingPageId: string, changedData: string) => {
  const { data } = await httpService.post(
    `${baseUrl}/pages/landing-pages/${landingPageId}/state`,
    { changedData }
  );
  return data;
};

export const publishPage = async (payload: {
  pageId: string;
  templateId: string;
  templateCode: string;
  dataChanged: string;
}) => {
  const { data } = await httpService.post(
    // TODO: handle page id properly
    `${baseUrl}/pages/landing-pages/${payload.pageId}/versions/latest/publish`,
    payload
  );
  return data;
};

export const createLandingPage = async (payload: { name: string }) => {
  const { data } = await httpService.post(
    `${baseUrl}/pages/landing-pages`,
    payload
  );

  return data;
};

export const cloneLandingPage = async (sourceLandingPageId: string) => {
  const { data } = await httpService.post(
    `${baseUrl}/pages/landing-pages/${sourceLandingPageId}/clone`
  );
  return data;
};

export const getLandingPages = async (params) => {
  const payload = {
    ...params,
  };
  const { data } = await httpService.get(`${baseUrl}/pages/landing-pages`, {
    params: payload,
  });

  return {
    data: customResponsePagination({
      content: data.landingPages || [],
      totalElementsCount: Number(data.total || 0),
      currentPage: params?.page || 1,
      pageSize: params?.pageSize || 10,
    }),
    success: data.success,
  };
};

// Get all templates for a landing page
export const getPageTemplates = async () => {
  const { data } = await httpService.get(
    `${baseUrl}/landing-page-builder/templates`
  );
  console.log(data);
  return data;
};

export const updateLandingPage = async (payload: {
  id: string;
  templateId: string;
  templateCode: string;
  domain?: string;
}) => {
  const { id } = payload;
  const { data } = await httpService.put(
    `${baseUrl}/pages/landing-pages/${id}`,
    payload
  );

  return data;
};

export const deleteLandingPage = async (landingPageId: string) => {
  const { data } = await httpService.delete(
    `${baseUrl}/pages/landing-pages/${landingPageId}`
  );
  return data;
};

export const updateLandingPageDomain = async (payload: any) => {
  const { data } = await httpService.post(
    `${baseUrl}/landing-page-builder/${payload?.landingPageId}/custom-domain`,
    { customDomain: payload?.domain }
  );
  return data;
};

export const getDomains = async () => {
  const { data } = await httpService.get(
    `${baseUrl}/pages/landing-pages/domains`
  );
  return data;
};

const pageBuilderService = {
  saveState,
  publishPage,
  getLandingPageState,
  createLandingPage,
  cloneLandingPage,
  getLandingPages,
  getPageTemplates,
  updateLandingPage,
  deleteLandingPage,
  getLandingPageDetail,
  getDomains,
  updateLandingPageDomain,
};

export default pageBuilderService;
