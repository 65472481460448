import { createRoute } from '@/helpers';
import React from 'react';
import { routesPath } from './constants';

const MessagingPage = React.lazy(() => import('@/pages/Messaging'));

// --------------------------------------------------------------------------- ROUTER
const RouterMessaging = {
  path: routesPath.MESSAGING,
  children: [
    createRoute(routesPath.MESSAGING, <MessagingPage />),
  ],
};

export default RouterMessaging;
