import { Element, Frame, useEditor } from '@craftjs/core';
import { memo } from 'react';
import { Layout } from '../EmailControls/Layout';
import { Button } from '../EmailControls/Button';
import { Image } from '../EmailControls/Image';
import { Container } from '../EmailControls/Container';
import { Col } from '../EmailControls/Col';
import Logo from '../../../assets/images/Logo.png';
import heroContainer from '../../../assets/images/heroContainer.png';
import { Text } from '../EmailControls/Text';
import IFrame from '../IFrame';
export const Viewer = memo(() => {
  return (
    <div className="w-full h-full bg-[#F6F8FA] page-container">
      <IFrame>
        <div className="renderer h-full w-full">
          <Frame>
            <Element is={Layout} canvas>
              <Element is={Container} background="white" width="content-width">
                <Element is={Col} paddingTop="24px" paddingBottom="24px" canvas>
                  <Image link={Logo} />
                </Element>
              </Element>
              <Element is={Container} background="white" width="content-width">
                <Element is={Col} canvas>
                  <Image link={heroContainer} />
                </Element>
              </Element>
              <Element is={Container} background="white" width="content-width">
                <Element is={Col} canvas>
                  <Text headingLevel="h1">Welcome to ABC Company!</Text>
                </Element>
              </Element>
              <Element is={Container} background="white" width="content-width">
                <Element
                  align="left"
                  is={Col}
                  paddingRight="20px"
                  paddingLeft="20px"
                  canvas
                >
                  <Text>
                    t is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using Lorem Ipsum is that it has a
                    more-or-less normal distribution of letters, as opposed to
                    using.
                  </Text>
                </Element>
              </Element>
              <Element is={Container} background="white" width="content-width">
                <Element
                  align="left"
                  is={Col}
                  paddingRight="20px"
                  paddingLeft="20px"
                  canvas
                >
                  <Text>
                    t is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using Lorem Ipsum is that it has a
                    more-or-less normal distribution of letters, as opposed to
                    using.
                  </Text>
                </Element>
              </Element>
              <Element is={Container} background="white" width="content-width">
                <Element
                  align="left"
                  is={Col}
                  paddingRight="20px"
                  paddingLeft="20px"
                  canvas
                >
                  <Text>
                    t is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using Lorem Ipsum is that it has a
                    more-or-less normal distribution of letters, as opposed to
                    using.
                  </Text>
                </Element>
              </Element>
              <Element is={Container} background="white" width="content-width">
                <Element
                  align="center"
                  is={Col}
                  paddingRight="20px"
                  paddingLeft="20px"
                  canvas
                >
                  <Button>Button</Button>
                </Element>
              </Element>
            </Element>
          </Frame>
        </div>
      </IFrame>
    </div>
  );
});
