import { memo, useCallback, useMemo } from 'react';
import { GTGSInputNumber, GTGSInputNumberProps } from '../GTGSInputNumber';

type IBCurrency = 'USD' | 'KHR';

type GTGSInputCurrencyProps = GTGSInputNumberProps & {
  unit?: IBCurrency;
};

const _GTGSInputCurrency = memo((props: GTGSInputCurrencyProps) => {
  const { unit = 'USD' } = props;
  const onKeyPressNumber = useCallback((event) => {
    const key = event.keyCode;
    const value = event?.target?.value;
    if (
      key == 37 ||
      key == 38 ||
      key == 39 ||
      key == 40 ||
      key == 8 ||
      key == 46 ||
      key == 9
    ) {
      // Left / Up / Right / Down Arrow, Backspace, Delete, Tab keys
      return;
    }
    if (!/[0-9.]/.test(event.key) || (/[.]/.test(event.key) && value.includes('.'))) {
      event.preventDefault();
    }
  }, []);
  const _currencyConfig = useMemo(() => {
    return {
      ['USD']: {
        precision: 2,
        step: 0.01,
        formatter: (value, info) => {
          const { userTyping } = info;
          if (userTyping) {
            return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          }
          if (value) {
            const numberPart = value.toString().split('.');
            const integerNumber = `${numberPart[0]}`.replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ','
            );
            const floatNumber = numberPart?.[1] ? numberPart[1] : '00';
            if (integerNumber) {
              return `${integerNumber}.${floatNumber}`;
            }
          }

          return '';
        },
        onKeyDown: (e) =>{ 
          const value = e.currentTarget.value;
          if (value) {
            const posOfDot = value.indexOf('.');
            if (posOfDot !== -1) {
              const posOfSelectionStart =
                e.currentTarget.selectionStart;
              const posOfSelectionEnd =
                e.currentTarget.selectionEnd;
              if (posOfSelectionEnd && posOfSelectionStart) {
                const range = posOfSelectionEnd - posOfSelectionStart;
                if (posOfSelectionEnd > posOfDot && !isNaN(Number(e.key)) && range === 0) {
                  const numberPart = value.split('.');
                  if (numberPart[1] && numberPart[1].length === 2) {
                    e.preventDefault();
                  }
                }
              }
            }
          }
        }
      },
      ['KHR']: {
        precision: 0,
        step: 0,
        formatter: (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
      },
    } as {
      [key in IBCurrency]: Partial<GTGSInputNumberProps>;
    };
  }, []);

  const _unitConfig = _currencyConfig[unit];

  return (
    <GTGSInputNumber {..._unitConfig} {...props} onKeyDown={onKeyPressNumber} />
  );
});


const GTGSInputCurrency = memo(_GTGSInputCurrency);

export { GTGSInputCurrency, type GTGSInputCurrencyProps, type IBCurrency };
