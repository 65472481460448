import { memo, useCallback } from 'react';
import { GTGSInput, GTGSInputProps } from '../GTGSInput/GTGSInput';

//------------------------------------------

interface GTGSInputPhoneNumberProps extends GTGSInputProps {}

//==================  MAIN =================
function _GTGSInputPhoneNumber(props: GTGSInputPhoneNumberProps) {
  const { children, onChange, ...rest } = props;

  const onChangeInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const _value = event.target.value.replace(
        /[a-zA-Z\s\u00C0-\u1EF9@!#$%^&*'"()_+=[\]{};:\\|,.<>/?-]/gi,
        ''
      );

      onChange?.({
        ...event,
        target: { ...event.target, value: _value },
      });
    },
    [onChange]
  );
  return (
    <GTGSInput onChange={onChangeInput} {...rest}>
      {children}
    </GTGSInput>
  );
}

const GTGSInputPhoneNumber = memo(_GTGSInputPhoneNumber);

export { GTGSInputPhoneNumber, type GTGSInputPhoneNumberProps };
