import { Map } from '@/components/GTGS/Map';
import { Row, Section, Column } from '@react-email/components';
import { CSSProperties, PropsWithChildren } from 'react';
import { Icon } from './Icon';

type ISocialProps = {
  style?: CSSProperties;
  data: Array<{
    type: string;
    link: string;
  }>;
};

export const Social = (props: PropsWithChildren<ISocialProps>) => {
  const { data } = props;

  return (
    <Section width={100} align='left'>
      <Row>
        <Map
          list={data}
          renderItem={(item) => {
            return (
              <Column>
                <Icon name={item.type as any} />
              </Column>
            );
          }}
        />
      </Row>
    </Section>
  );
};
export const SocialSettings = () => {};

export const SocialDefaultProps: ISocialProps = {
  style: {},
  data: [
    {
      type: 'tiktok',
      link: '',
    },
  ],
};

Social.craft = {
  props: SocialDefaultProps,
  related: {
    settings: SocialSettings,
  },
};
