import { Editor, Element, Frame, Resolver } from '@craftjs/core';
import React from 'react';

import { getLandingPageState } from '@/services/pageBuilderService';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { FormBox } from '.';
import FormButton from './components/form-builder/FormButton';
import FormCheckbox from './components/form-builder/FormCheckbox';
import FormDatePicker from './components/form-builder/FormDatePicker';
import FormInput from './components/form-builder/FormInput';
import FormRadio from './components/form-builder/FormRadio';
import FormSelect from './components/form-builder/FormSelect';
import FormTextArea from './components/form-builder/FormTextArea';
import LoadPreviewPage from './components/LoadPreviewPage';
import CTA from './themes/theme-1/components/CTA';
import FAQ from './themes/theme-1/components/FAQ';
import Pricing from './themes/theme-1/components/Pricing';
import FeatureSection from './themes/theme-1/components/FeatureSection';
import Footer from './themes/theme-1/components/Footer';
import Header from './themes/theme-1/components/Header';
import HeroSection from './themes/theme-1/components/HeroSection';
import DemoForm from './themes/theme-1/components/InfoForm';
import Testimonial from './themes/theme-1/components/Testimonial';
import TopBanner from './themes/theme-1/components/TopBanner';
import FactSection from './themes/theme-2/components/FactSection';
import GTGCosmeticsHeader from './themes/theme-2/components/Header';
import StorySection from './themes/theme-2/components/StorySection';
import CosmeticsStatisticsSection from '@/pages/PageBuilder/themes/theme-2/components/CosmeticsStatisticsSection';
import ProductShowcase from './themes/theme-2/components/ProductShowcase';
import FAQCosmetic from './themes/theme-2/components/FAQCosmetic';
import RecommendationSection from './themes/theme-2/components/RecommendationSection';
import TestimonialCosmetic from './themes/theme-2/components/TestimonialCosmetic';
import FooterCosmetic from './themes/theme-2/components/Footer';
const Container = ({ children }: any) => {
  return <div className="h-full">{children}</div>;
};

const PreviewPage: React.FC = () => {
  const { id } = useParams();
  const { data } = useQuery(['getLatestState'], () =>
    getLandingPageState(id || '', 'latest')
  );

  const getResolver = (): Resolver => {
    if (data?.templateCode === 'gtg_cosmetics') {
      return {
        GTGCosmeticsHeader,
        FactSection,
        CosmeticsStatisticsSection,
        ProductShowcase,
        StorySection,
        FAQCosmetic,
        Container,
        LoadPreviewPage,
        RecommendationSection,
        TestimonialCosmetic,
        FooterCosmetic,
      };
    }

    return {
      HeroSection,
      Header,
      CTA,
      FeatureSection,
      Testimonial,
      FAQ,
      Pricing,
      Footer,
      TopBanner,
      Container,
      LoadPreviewPage,
      FormInput,
      FormButton,
      FormBox,
      DemoForm,
      FormSelect,
      FormTextArea,
      FormRadio,
      FormCheckbox,
      FormDatePicker,
    };
  };

  if (!data) return null;
  return (
    <div className="min-h-screen relative">
      <Editor resolver={getResolver()} enabled={false}>
        <Frame>
          <Element canvas id="root" is={Container}>
            <LoadPreviewPage />
          </Element>
        </Frame>
      </Editor>
    </div>
  );
};

export default PreviewPage;
