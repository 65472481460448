import React, { useState, useEffect } from 'react';
import { Layout, Tabs, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import {
  getConnectedAdAccounts,
  AdAccount,
  FacebookPage,
} from '@/services/adsService';
import Ad from './Ad';
import Targeting from './Targeting';
import BudgetAndSchedule from './BudgetAndSchedule';
import Automation from './Automation';
const { TabPane } = Tabs;
const { Header, Content } = Layout;

const FacebookAdCampaign = () => {
  const navigate = useNavigate();
  const [adAccountId, setAdAccountId] = useState('');
  const [campaignType, setCampaignType] = useState('new');
  const [adCreative, setAdCreative] = useState('existing');
  const [adAccounts, setAdAccounts] = useState<AdAccount[]>([]);
  const [facebookPages, setFacebookPages] = useState<FacebookPage[]>([]);
  const [previewData, setPreviewData] = useState({
    bodyCopy: '',
    headline: '',
    selectedFacebookPage: '',
  });

  const { data: accounts } = useQuery(['getConnectedAdAccounts'], () =>
    getConnectedAdAccounts({ platform: 'FACEBOOK', page: 1, pageSize: 3 })
  );

  useEffect(() => {
    if (accounts) {
      setAdAccounts(accounts.data.content);
    }
  }, [accounts]);

  const handlePreviewUpdate = (data: {
    bodyCopy: string;
    headline: string;
    selectedFacebookPage: string;
  }) => {
    setPreviewData(data);
  };

  return (
    <Layout className="min-h-screen">
      <Header className="bg-dark flex justify-between items-center px-6 py-4">
        <Button
          onClick={() => navigate('/marketing/ads')}
          type="default"
          className="text-black"
        >
          EXIT
        </Button>
        <Button className="px-4 md:px-14">PUBLISH</Button>
      </Header>
      <Content>
        <Tabs
          defaultActiveKey="1"
          type="line"
          size="large"
          tabBarGutter={32}
          centered
          tabBarStyle={{ marginBottom: 0 }}
        >
          <TabPane tab="Ad" key="1">
            <Ad
              adAccounts={adAccounts}
              facebookPages={facebookPages}
              adAccountId={adAccountId}
              campaignType={campaignType}
              adCreative={adCreative}
              setAdAccountId={setAdAccountId}
              setCampaignType={setCampaignType}
              setAdCreative={setAdCreative}
              setFacebookPages={setFacebookPages}
              onPreviewUpdate={handlePreviewUpdate}
            />
          </TabPane>
          <TabPane tab="Targeting" key="2">
            <Targeting previewData={previewData} />
          </TabPane>
          <TabPane tab="Budget & Schedule" key="3">
            <BudgetAndSchedule previewData={previewData}></BudgetAndSchedule>
          </TabPane>
          <TabPane tab="Automation" key="4">
            <Automation></Automation>
          </TabPane>
        </Tabs>
      </Content>
    </Layout>
  );
};

export default FacebookAdCampaign;
