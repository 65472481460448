import { createRoute } from '@/helpers';
import CreateTemplate from '@/pages/EmailBuilder/CreateTemplate';
import PageBuilder from '@/pages/PageBuilder';
import PageBuilderMiddleware from '@/pages/PageBuilder/components/PageBuilderMiddleware';
import PreviewPage from '@/pages/PageBuilder/PreviewPage';
import PreviewTemplate from '@/pages/PageBuilder/PreviewTemplate';
import React from 'react';
import { routesPath } from './constants';

const CasesPage = React.lazy(() => import('@/pages/Cases'));
const LandingPagesPage = React.lazy(() => import('@/pages/LandingPage'));
const LandingPageDetail = React.lazy(() => import('@/pages/PageBuilder/LandingPageDetail'));


// --------------------------------------------------------------------------- ROUTER
const RouterContent = {
  path: routesPath.CONTENT,
  children: [
    createRoute(routesPath.CONTENT, <CasesPage />),
    createRoute(routesPath.LANDING_PAGES, <LandingPagesPage />),
    createRoute(routesPath.LANDING_PAGE_DETAIL, <LandingPageDetail />),
    createRoute(routesPath.WEBSITE_PAGE, <CasesPage />),
    createRoute(routesPath.BLOG, <CasesPage />),
  ],
};

export const RouterContentWithoutLayout = {
  path: routesPath.CONTENT,
  children: [
    {
      path: routesPath.LANDING_PAGE_EDITOR,
      element: <PageBuilder />,
    },
    {
      path: routesPath.LANDING_PAGE_SELECT_TEMPLATE,
      element: <PageBuilderMiddleware />,
    },
    {
      path: routesPath.LANDING_PAGE_PREVIEW_TEMPLATE,
      element: <PreviewTemplate />,
    },
    {
      path: routesPath.LANDING_PAGE_PREVIEW,
      element: <PreviewPage />,
    },
    {
      path: routesPath.EMAIL_BUILDER_CREATE_TEMPLATE,
      element: <CreateTemplate />,
    },
  ],
};

export default RouterContent;
