import { Button, ButtonProps } from 'antd';
import { memo } from 'react';
import { FormItem } from '../../Form';
import { ActiveButton } from '../ActiveButton';
import Bold from '../../../../assets/icons/bold.svg';
import Underline from '../../../../assets/icons/underline.svg';
import Italic from '../../../../assets/icons/italic.svg';
type IFontStyle = {
  label: string;
  name: string;
};

export const FontStyle = memo((props: IFontStyle) => {
  const { label, name } = props;
  const fontWeightBold = `${name}Bold`;
  const fontItalic = `${name}Italic`;
  const textUnderline = `${name}Underline`;
  return (
    <FormItem label={label}>
      <FormItem noStyle name={fontWeightBold}>
        <ActiveButton position="start" icon={<img src={Bold} />} />
      </FormItem>
      <FormItem noStyle name={fontItalic}>
        <ActiveButton icon={<img src={Italic} />} />
      </FormItem>
      <FormItem noStyle name={textUnderline}>
        <ActiveButton position="end" icon={<img src={Underline} />} />
      </FormItem>
    </FormItem>
  );
});
