import classNames from 'classnames';
import {
  CSSProperties,
  memo
} from 'react';
import isEqual from 'react-fast-compare';

import { ImageProps } from 'antd';
import { IIIustrationsName } from './GTGSIIIustrations.types';

interface GTGSIIIustrationsProps extends ImageProps {
  name: IIIustrationsName;
  size?: { width: number; height: number};
}

function _GTGSIIIustrations(props: GTGSIIIustrationsProps) {
  const { name, size = {
    width: 275,
    height: 175,
  }, style, className, ...rest } = props;


  const _style: CSSProperties = {
    ...(typeof size !== 'undefined'
      ? {
         ...size
        }
      : {}),
    ...style,
  };

  const url = `/images/IIIustrations/${name}.svg`;
  

  return (
    <img
      className={classNames(className)}
      style={_style}
      src={url}
      {...rest}
    />
  );
}

const GTGSIIIustrations = memo(_GTGSIIIustrations, isEqual);

export { GTGSIIIustrations, type GTGSIIIustrationsProps };
