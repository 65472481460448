import { GTGSButton, GTGSInput } from '@/components/GTGS';
import { useNode } from '@craftjs/core';
import { Form, Input } from 'antd';
import React from 'react';

const FormButton = ({ label, placeholder }) => {
  const {
    connectors: { connect, drag },
    id,
  } = useNode();

  return (
    <div ref={(ref) => ref && connect(drag(ref))} className="col-span-2">
      <Form.Item>
        <GTGSButton
          type="primary"
          htmlType="submit"
          className="w-full xl:h-[48px] bg-[#005CE6] text-white font-semibold rounded-lg text-xs px-5 py-2.5 text-center"
        >
          Register
        </GTGSButton>
      </Form.Item>
    </div>
  );
};

FormButton.defaultProps = {
  label: 'Input',
  placeholder: 'Input',
};

const FormButtonSettings = () => {
  const {
    actions: { setProp },
    label,
    placeholder,
  } = useNode((node) => ({
    label: node.data.props.label,
    placeholder: node.data.props.placeholder,
  }));
  return (
    <div>
      <div className="mb-5">
        <label className="block mb-2 font-medium text-gray-700">Label</label>
        <input
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          defaultValue={label}
          onChange={(e) => {
            setProp(
              (props: { label: string }) => (props.label = e.target.value),
              1000
            );
          }}
        />
      </div>
      <div className="mb-5">
        <label className="block mb-2 font-medium text-gray-700">
          Placeholder
        </label>
        <textarea
          rows={8}
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          defaultValue={placeholder}
          onChange={(e) => {
            setProp(
              (props: { placeholder: string }) =>
                (props.placeholder = e.target.value),
              1000
            );
          }}
        />
      </div>
    </div>
  );
};

FormButton.craft = {
  related: {
    settings: FormButtonSettings,
  },
  displayName: 'Button',
  name: 'FormBuilder-Button',
  custom: {
    label: 'FormBuilder-Button',
    props: {
      label: 'FormBuilder-Button',
      placeholder: 'FormBuilder-Button',
    },
  },
};

export default FormButton;
