import { CSSProperties, useCallback, useMemo, useState } from 'react';
import { Menu as AntdMenu, MenuProps } from 'antd';
import { useMenuData } from './hooks/useMenuData';
import styles from './styles.module.scss';
import { useLocation } from 'react-router-dom';

const findParentKeys = (childKeys: string[], nodes: any[]): string[] => {
  const findParent = (
    key: string,
    nodes: any[],
    parentKey: string | null = null
  ): string | null => {
    for (const node of nodes) {
      if (node.key === key) {
        return parentKey;
      }
      if (node.children) {
        const foundParentKey = findParent(key, node.children, node.key);
        if (foundParentKey) {
          return foundParentKey;
        }
      }
    }
    return null;
  };
  const parentKeys = new Set<string>();
  for (const key of childKeys) {
    const parentKey = findParent(key, nodes);
    if (parentKey) {
      parentKeys.add(parentKey);
    }
  }
  return Array.from(parentKeys);
};

type IMenuProps = {
  style?: CSSProperties;
  setCollapsed?: (collapsed) => void;
  collapsed?: boolean;
};
const Menu = (props: IMenuProps) => {
  const { style, setCollapsed, collapsed } = props;
  const { items, constant } = useMenuData();
  const location = useLocation();

  const pathName = location.pathname;

  const _selectedKeys = useMemo(() => {
    const keys = Object.values(constant)
      .filter((p) => {
        if(!p.url) return false;
        if(p.url === '/' && pathName === '/') return true;
        if(p.url === '/' && pathName !== '/') return false;
        return p.url.length <= pathName.length && pathName?.includes(p.url);
      })
      .map((p) => p.code);
    return keys;
  }, [constant, pathName]);

  const [expandKeys, setExpandKeys] = useState<string[]>(() => {
    const parentKeys = findParentKeys(_selectedKeys, items);
    return parentKeys;
  });

  const _onExpand = useCallback((keys: string[]) => {
    setExpandKeys(keys.slice(keys.length - 1));
  }, []);

  const onClickItem: MenuProps['onClick'] = () => {
    setCollapsed && setCollapsed(false);
  };

  return (
    <AntdMenu
      className={styles.menuContainer}
      mode="inline"
      items={items}
      selectedKeys={_selectedKeys}
      inlineCollapsed={collapsed || false}
      openKeys={expandKeys}
      inlineIndent={16}
      theme="dark"
      onOpenChange={_onExpand}
      style={style}
      onClick={onClickItem}
    />
  );
};

export default Menu;
