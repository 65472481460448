import { useNode } from '@craftjs/core';
import { Form, Select } from 'antd';
import OptionSetting from './settings/OptionSetting';
import { formatFieldName } from '@/pages/CRM/helpers/stringHelper';

type FormSelectProps = {
  name?: string;
  label: string;
  placeholder: string;
  minLength: number;
  maxLength: number;
  options?: string[];
  isRequired?: boolean;
};

const FormSelect = ({
  name,
  label,
  placeholder,
  options,
  isRequired,
  ...props
}: FormSelectProps) => {
  const {
    connectors: { connect, drag },
    id,
  } = useNode();

  const isEnum = props['data-enum'];

  return (
    <div
      ref={(ref) => ref && connect(drag(ref))}
      className="w-full"
      data-form="true"
    >
      <Form.Item
        name={name}
        label={label}
        rules={[
          {
            required: isRequired,
            message: 'This field is required!',
          },
        ]}
      >
        <Select
          {...props}
          placeholder={placeholder}
          size="large"
          options={options?.map((item) => ({
            value: item,
            label: isEnum ? formatFieldName(item) : item,
          }))}
          className="w-full"
        />
      </Form.Item>
    </div>
  );
};

FormSelect.defaultProps = {
  label: 'Dropdown',
  placeholder: 'Select',
  minLength: 0,
  maxLength: 255,
  options: ['Option 1', 'Option 2', 'Option 3'],
  isRequired: false,
};

FormSelect.craft = {
  related: {
    settings: OptionSetting,
  },
  displayName: 'GTGFORMDropdown',
};

export default FormSelect;
