import { useEditor, useNode } from '@craftjs/core';
import { Font, Head, Html, Tailwind } from '@react-email/components';
import { PropsWithChildren } from 'react';
import { Settings } from './Settings';

type ILayoutProps = {
  background?: string;
  padding?: number;
  minHeight?: number;
  paddingTop?: number;

  paddingBottom?: number;
};

export const Layout = (props: PropsWithChildren<ILayoutProps>) => {
  const {
    children,

    paddingTop,
    paddingBottom,
  } = props;
  const {
    connectors: { select, hover },
  } = useEditor();
  const {
    connectors: { connect },
  } = useNode();

  return (
    <Html
      ref={(ref) => {
        if (ref) {
          connect(ref);
          select(ref, '');
          hover(ref, '');
        }
      }}
      style={{ paddingTop, paddingBottom }}
    >
      <Head>
        <style>
          {` * {
         box-sizing: border-box;
      }`}
        </style>
        <Font
          fontFamily="Roboto"
          fallbackFontFamily="Verdana"
          webFont={{
            url: 'https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap',
            format: 'embedded-opentype',
          }}
          fontWeight={400}
          fontStyle="normal"
        />
      </Head>
      {children}
    </Html>
  );
};

export const LayoutDefaultProps: ILayoutProps = {
  paddingTop: 24,
  paddingBottom: 24,
};

Layout.craft = {
  props: LayoutDefaultProps,
  related: {
    settings: Settings,
  },
};
