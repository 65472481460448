import React, { useEffect, useState } from 'react';
import { Form, Input, Radio, Select, Typography, message } from 'antd';
import {
  AdAccount,
  FacebookPage,
  getAdAccountFacebookPages,
} from '@/services/adsService';
import PreviewSection from '../PreviewSection';
import { getLandingPages } from '@/services/pageBuilderService';
import { useQuery } from 'react-query';

const { Title } = Typography;
const { Option } = Select;

interface AdProps {
  adAccounts: AdAccount[];
  facebookPages: FacebookPage[];
  adAccountId: string;
  campaignType: string;
  adCreative: string;
  setAdAccountId: (id: string) => void;
  setCampaignType: (type: string) => void;
  setAdCreative: (creative: string) => void;
  setFacebookPages: (pages: FacebookPage[]) => void;
  onPreviewUpdate: (data: {
    bodyCopy: string;
    headline: string;
    selectedFacebookPage: string;
  }) => void;
}

const Ad: React.FC<AdProps> = ({
  adAccounts,
  facebookPages,
  adAccountId,
  campaignType,
  adCreative,
  setAdAccountId,
  setCampaignType,
  setAdCreative,
  setFacebookPages,
  onPreviewUpdate,
}) => {
  const [showExistingPost, setShowExistingPost] = useState(true);
  const [bodyCopy, setBodyCopy] = useState('');
  const [headline, setHeadline] = useState('');
  const [selectedDomains, setSelectedDomains] = useState<string[]>([]);
  const [selectedFacebookPage, setSelectedFacebookPage] = useState<string>('');
  const { data: pages } = useQuery('getLandingPages', getLandingPages, {});

  const publishedPages = pages?.data?.content?.filter(
    (page) => page.status === 'published'
  );

  useEffect(() => {
    if (adAccountId) {
      getAdAccountFacebookPages({ externalId: adAccountId })
        .then((value) => setFacebookPages(value.data.content))
        .catch(() =>
          message.error('Something went wrong, please try again later')
        );
    }
  }, [adAccountId, setFacebookPages]);

  useEffect(() => {
    onPreviewUpdate({
      bodyCopy,
      headline,
      selectedFacebookPage,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bodyCopy, headline, selectedFacebookPage]);

  const handleSelectChange = (value: string, option: any) => {
    setAdAccountId(option.key);
    setCampaignType(value);
  };

  const handleFacebookPageChange = (value: string) => {
    setSelectedFacebookPage(value);
  };

  const handleAdCreativeChange = (e: any) => {
    const value = e.target.value;
    setAdCreative(value);
    setShowExistingPost(value === 'existing');
  };

  return (
    <div className="flex flex-col lg:flex-row h-screen">
      <div className="w-full lg:w-1/2 bg-white p-8">
        <div className="px-6 bt-2">
          <Title level={3} className="mb-4">
            Facebook Ad Campaign
          </Title>
          <Form layout="vertical">
            <Form.Item
              label="Ad account *"
              name="adAccount"
              rules={[
                { required: true, message: 'Please select an ad account' },
              ]}
            >
              <Select
                placeholder="Select an ad account"
                style={{ height: 40 }}
                onChange={handleSelectChange}
              >
                {adAccounts.map((account) => (
                  <Option key={account.id} value={account.name}>
                    {account.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Facebook page *"
              name="facebookPage"
              rules={[
                { required: true, message: 'Please select a Facebook page' },
              ]}
            >
              <Select
                placeholder="Select a page"
                style={{ height: 40 }}
                onChange={handleFacebookPageChange}
              >
                {facebookPages.map((facebookPage) => (
                  <Option key={facebookPage.id} value={facebookPage.name}>
                    {facebookPage.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Campaign type *"
              name="campaignType"
              rules={[
                { required: true, message: 'Please select a campaign type' },
              ]}
            >
              <Radio.Group
                onChange={(e) => setCampaignType(e.target.value)}
                value={campaignType}
              >
                <Radio value="new">Create new campaign</Radio>
                <Radio value="existing">Add to existing</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="Ad creative *"
              name="adCreative"
              rules={[
                {
                  required: true,
                  message: 'Please select an ad creative option',
                },
              ]}
            >
              <Radio.Group onChange={handleAdCreativeChange} value={adCreative}>
                <Radio value="existing">Use existing post</Radio>
                <Radio value="new">Create a new ad</Radio>
              </Radio.Group>
            </Form.Item>
            {showExistingPost ? (
              <Form.Item label="Select an existing post" name="existingPost">
                <Select placeholder="Select a post" style={{ height: 40 }}>
                  <Option value="Post 1">Post 1</Option>
                  <Option value="Post 2">Post 2</Option>
                </Select>
              </Form.Item>
            ) : (
              <>
                <Form.Item label="Website page url" name="landingPage">
                  <Select
                    placeholder="Select GTGCRM page"
                    style={{ height: 40 }}
                    onChange={(value) => setSelectedDomains(value)}
                  >
                    {publishedPages?.map((page) => (
                      <Option key={page.id} value={page.name}>
                        {page.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Image *"
                  name="media"
                  rules={[
                    { required: true, message: 'Please upload an image/video' },
                  ]}
                >
                  <div className="border-dashed border-2 border-gray-300 p-4 text-center">
                    Drag & drop file here or <a href="#">choose file</a>
                    <p className="text-xs">
                      File size limit under 5MB; Supported formats: PNG, JPEG
                    </p>
                  </div>
                </Form.Item>
                <Form.Item
                  label="Text/Body copy *"
                  name="bodyCopy"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter a message for your ad',
                    },
                  ]}
                >
                  <Input.TextArea
                    placeholder="Write a message for your ad"
                    value={bodyCopy}
                    onChange={(e) => setBodyCopy(e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  label="Headline *"
                  name="headline"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter a headline for your ad',
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter a headline"
                    value={headline}
                    onChange={(e) => setHeadline(e.target.value)}
                  />
                </Form.Item>
              </>
            )}
          </Form>
        </div>
      </div>

      <PreviewSection
        status="preview"
        estimatedAudienceSize={undefined}
        previewData={{
          bodyCopy,
          headline,
          selectedFacebookPage,
        }}
      />
    </div>
  );
};

export default Ad;
