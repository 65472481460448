import { getLandingPageState } from '@/services/pageBuilderService';
import { useEditor } from '@craftjs/core';
import lz from 'lzutf8';
import { useEffect } from 'react';
import { useQuery } from 'react-query';

import { useNavigate, useParams } from 'react-router-dom';
import cosmetics from '@/pages/PageBuilder/data/gtg_cosmetics.json';
import crm from '@/pages/PageBuilder/data/gtg_crm_template.json';

const InitPreviewTemplate = () => {
  const navigate = useNavigate();
  const { id, templateCode } = useParams();
  const { data } = useQuery(['getLatestState'], () =>
    getLandingPageState(id || '', 'latest')
  );

  const { actions } = useEditor();

  useEffect(() => {
    if (data) {
      if (data?.dataChanged) {
        const json = lz.decompress(lz.decodeBase64(data.dataChanged));
        actions.deserialize(json);
      } else {
        if (templateCode === 'gtg_cosmetics') {
          actions.deserialize(JSON.stringify(cosmetics));
        }
        if (templateCode === 'gtg_crm_template') {
          actions.deserialize(JSON.stringify(crm));
        }
        // navigate('templates');
      }
    }
  }, [data, actions, navigate, templateCode]);

  return null;
};

export default InitPreviewTemplate;
