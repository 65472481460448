import { useNode } from '@craftjs/core';
import { Button as EmailButton } from '@react-email/components';
import { PropsWithChildren } from 'react';
import { CSSProperties } from 'styled-components';

type IButtonProps = {
  width?: string;
  height?: string;
  padding?: string;
  borderRadius?: string;
  border?: string;
  background?: string;
  textAlign?: CSSProperties['textAlign'];
};

export const Button = (props: PropsWithChildren<IButtonProps>) => {
  const { children, ...rest } = props;
  const {
    connectors: { connect, drag },
  } = useNode();
  return (
    <EmailButton
      style={{ cursor: 'pointer', ...rest }}
      ref={(ref) => ref && connect(ref!)}
    >
      {children}
    </EmailButton>
  );
};
export const ButtonSettings = () => {};

export const ButtonDefaultProps: IButtonProps = {
  width: '148px',
  height: '42px',
  padding: '10px',
  borderRadius: '4px',
  border: '1px solid  #000000',
  textAlign: 'center',
};

Button.craft = {
  props: ButtonDefaultProps,
  related: {
    settings: ButtonSettings,
  },
};
