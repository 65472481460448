import { useNode } from '@craftjs/core';
import { Checkbox, Form, Space } from 'antd';
import CheckboxSetting from './settings/CheckboxSetting';
import React from 'react';

type FormCheckboxProps = {
  name?: string;
  label: string;
  minLength: number;
  maxLength: number;
  options?: string[];
  isRequired?: boolean;
};

const FormCheckbox = ({
  name,
  label,
  options,
  isRequired,
  ...props
}: FormCheckboxProps) => {
  const {
    connectors: { connect, drag },
    id,
  } = useNode();

  return (
    <div
      ref={(ref) => ref && connect(drag(ref))}
      className="w-full"
      data-form="true"
    >
      <Form.Item
        name={name}
        label={label}
        rules={[
          {
            required: isRequired,
            message: 'This field is required!',
          },
        ]}
      >
        <Checkbox.Group {...props}>
          <Space direction="vertical">
            {options?.map((option, index) => (
              <Checkbox key={index} value={option}>
                {option}
              </Checkbox>
            ))}
          </Space>
        </Checkbox.Group>
      </Form.Item>
    </div>
  );
};

FormCheckbox.defaultProps = {
  label: 'Label',
  minLength: 0,
  maxLength: 255,
  options: ['Option 1', 'Option 2', 'Option 3'],
  isRequired: false,
};

FormCheckbox.craft = {
  related: {
    settings: CheckboxSetting,
  },
  displayName: 'GTGFORMCheckbox',
};

export default FormCheckbox;
