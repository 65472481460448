import { GTGSButton, GTGSIcon, GTGSTypography } from '@/components/GTGS';
import {
  getLandingPageState,
  getPageTemplates,
  saveState,
  updateLandingPage,
} from '@/services/pageBuilderService';
import { Col, Flex, message, Row, Space } from 'antd';
import lz from 'lzutf8';
import { useMutation, useQuery } from 'react-query';
import { Link, useNavigate, useParams } from 'react-router-dom';
import cosmetics from '../data/gtg_cosmetics.json';
import crm from '../data/gtg_crm_template.json';
import { useState } from 'react';

const PageBuilderMiddleware = () => {
  const { id } = useParams();
  const { data: landingPageDetail } = useQuery(['getLatestState', id], () =>
    getLandingPageState(id || '', 'latest')
  );
  const navigate = useNavigate();

  const { mutate: saveLatestState } = useMutation(
    'saveState',
    ({
      landingPageId,
      changedData,
    }: {
      landingPageId: string;
      changedData: string;
    }) => saveState(landingPageId, changedData),
    {
      onSuccess: () => {
        if (id) {
          navigate('../editor', { relative: 'path' });
        } else {
          message.error('Invalid page ID');
        }
      },
      onError: () => {
        message.error({
          content: 'Some thing went wrong',
        });
      },
    }
  );

  const { data } = useQuery(['getThemes'], () => getPageTemplates());

  const { mutate: selectTemplate } = useMutation(
    ({ templateCode }: { templateCode: string }) =>
      updateLandingPage({
        id: id || '',
        templateId: '',
        templateCode,
      }),
    {
      onSuccess: (_, variables) => {
        // Use templateCode from variables in onSuccess
        const json = variables.templateCode.startsWith('gtg_cosmetics')
          ? JSON.stringify(cosmetics)
          : JSON.stringify(crm);

        const landingPageId = id || '';
        const changedData = lz.encodeBase64(lz.compress(json));
        saveLatestState({ landingPageId, changedData });
      },
      onError: () => {
        message.error({
          content: 'Something went wrong',
        });
      },
    }
  );

  return (
    <div className="p-6">
      <Flex vertical gap={8}>
        <Link to={'/content/landing-pages'}>
          <GTGSButton
            type="text"
            icon={<GTGSIcon name="arrow-left" />}
            className="w-fit p-0"
          >
            Back
          </GTGSButton>
        </Link>
        <GTGSTypography.Title level={3}>Choose a Template</GTGSTypography.Title>
        <GTGSTypography.Text>
          Select a theme to ensure a consistent experience for your visitors.
          While we recommend using a single theme for uniformity, you can switch
          to a different theme whenever you like. If you do change your theme,
          remember to update your blog and system page designs in the settings.
        </GTGSTypography.Text>
        <Row gutter={24}>
          {data?.templates?.map((template) => (
            <Col span={12}>
              <Flex
                vertical
                gap={8}
                key={template?.id}
                className="p-6 border border-gray-200 rounded-md"
              >
                <img
                  className=" h-[558px]"
                  src={template?.previewLink}
                  alt={template?.businessDomain}
                />
                <GTGSTypography.Title level={4}>
                  {template?.name || 'Template Starter'}
                </GTGSTypography.Title>
                <Space>
                  <GTGSButton
                    style={{ minWidth: 165 }}
                    onClick={() => {
                      selectTemplate({
                        templateCode: template?.code,
                      });
                    }}
                  >
                    SELECT
                  </GTGSButton>
                  <GTGSButton
                    style={{ minWidth: 165 }}
                    type="primary"
                    onClick={() => navigate(`${template?.code}`)}
                  >
                    PREVIEW
                  </GTGSButton>
                </Space>
              </Flex>
            </Col>
          ))}
        </Row>
      </Flex>
    </div>
  );
};

export default PageBuilderMiddleware;
