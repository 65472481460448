import { GTGSButton, GTGSTypography } from '@/components/GTGS';
import { useEditor } from '@craftjs/core';
import { memo } from 'react';

const LeftAction = memo(() => {
  const { actions, query, enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));
  const value = {
    ROOT: {
      type: { resolvedName: 'Container' },
      isCanvas: false,
      props: { background: '#ffffff', padding: 3, children: '23' },
      displayName: 'Container',
      custom: {},
      hidden: false,
      nodes: [],
      linkedNodes: {},
    },
  };
  return (
    <div className="flex gap-2">
      <GTGSButton
        onClick={() => {
          actions.deserialize(JSON.stringify(value));
        }}
      >
        Exit
      </GTGSButton>
      <GTGSButton
        onClick={() => {
          const json = query.serialize();
          console.log(json);
        }}
        type="text"
        style={{ color: '#fff' }}
      >
        Save
      </GTGSButton>
    </div>
  );
});

const RightAction = memo(() => {
  return (
    <div className="flex gap-2">
      <GTGSButton>preview</GTGSButton>
      <GTGSButton type="primary">Review and publish</GTGSButton>
    </div>
  );
});

const CenterAction = memo(() => {
  return (
    <div className="flex flex-1 justify-center">
      <div>
        <GTGSTypography.Title level={2} className="!text-white">
          Template Name
        </GTGSTypography.Title>
      </div>
    </div>
  );
});

export const Header = memo(() => {
  return (
    <div className="flex flex-row items-center h-full px-4">
      <div>
        <LeftAction />
      </div>
      <div className="flex flex-1">
        <CenterAction />
      </div>
      <div>
        <RightAction />
      </div>
    </div>
  );
});
