import { useNode } from '@craftjs/core';
import { DatePicker, Form } from 'antd';
import InputSettings from './settings/InputSetting';

type FormDatePickerProps = {
  name?: string;
  label: string;
  placeholder: string;
  options?: string[];
  required?: boolean;
};

const FormDatePicker = ({
  name,
  label,
  placeholder,
  required,
}: FormDatePickerProps) => {
  const {
    connectors: { connect, drag },
    id,
  } = useNode();

  return (
    <div
      ref={(ref) => ref && connect(drag(ref))}
      className="w-full"
      data-form="true"
    >
      <Form.Item
        name={name}
        label={label}
        rules={[
          {
            required: required,
            message: 'This field is required!',
          },
        ]}
      >
        <DatePicker className="w-full" size="large" />
      </Form.Item>
    </div>
  );
};

FormDatePicker.defaultProps = {
  label: 'Input',
  placeholder: 'Input',
  minLength: 0,
  maxLength: 255,
  options: [],
  required: false,
};

FormDatePicker.craft = {
  related: {
    settings: InputSettings,
  },
  displayName: 'GTGFORMDatePicker',
};

export default FormDatePicker;
