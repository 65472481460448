import { Flex, Select, SelectProps } from 'antd';
import { FormItem } from '../../Form';
import { GTGSInput } from '@/components/GTGS';

type IPaddingProps = {
  name: string;
};
export const Padding = (props: IPaddingProps) => {
  const { name } = props;

  const namePaddingTop = `${name}PaddingTop`;
  const namePaddingBottom = `${name}PaddingBottom`;
  const namePaddingLeft = `${name}PaddingLeft`;
  const namePaddingRight = `${name}PaddingRight`;
  return (
    <>
      <Flex gap={16}>
        <FormItem name={namePaddingTop} label="Top">
          <GTGSInput />
        </FormItem>

        <FormItem name={namePaddingBottom} label="Bottom">
          <GTGSInput />
        </FormItem>
      </Flex>
      <Flex gap={16}>
        <FormItem name={namePaddingLeft} label="Left">
          <GTGSInput />
        </FormItem>

        <FormItem name={namePaddingRight} label="Right">
          <GTGSInput />
        </FormItem>
      </Flex>
    </>
  );
};
