import { GTGSCollapse, GTGSTypography } from '@/components/GTGS';
import { useEditor } from '@craftjs/core';
import { CollapseProps } from 'antd';
import { memo } from 'react';
import { TemplateSettings } from './TemplateSettings';
import { TextStyleSettings } from './TextSettings';
import { ButtonSettings } from './ButtonSettings';
import { Form } from '../../Form';
import { DividerSettings } from './Divider';

export const Settings = memo(() => {
  const { query } = useEditor();

  const itemsNest: CollapseProps['items'] = [
    {
      key: '1',
      label: <GTGSTypography.Text strong>Template</GTGSTypography.Text>,
      children: <TemplateSettings />,
    },
    {
      key: '2',
      label: <GTGSTypography.Text strong>Text style</GTGSTypography.Text>,
      children: <TextStyleSettings />,
    },
    {
      key: '3',
      label: <GTGSTypography.Text strong>Button</GTGSTypography.Text>,
      children: <ButtonSettings />,
    },
    {
      key: '4',
      label: <GTGSTypography.Text strong>Divider</GTGSTypography.Text>,
      children: <DividerSettings />,
    },
  ];
  return (
    <Form>
      <GTGSCollapse
        accordion
        bordered={false}
        collapsible="header"
        style={{ background: 'transparent' }}
        size="small"
        expandIconPosition="start"
        items={itemsNest}
        
        ghost
      />
    </Form>
  );
});
