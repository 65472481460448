import { useEditor } from '@craftjs/core';

export const useTemplate = () => {
  const { query } = useEditor();
  const nodes = query.getNodes();
  const root = nodes['ROOT'];
  return {
    root,
    settings: root.related.settings,
  };
};
