import { Layout } from 'antd';
import { Toolbox } from '../../components/Editor/EmailProperties';
import { Header } from './components/Header';
import { TabsAction } from './components/Tabs';
import { Editor } from '../../components/Editor';
import { Viewer } from '../../components/Editor/Viewer';

const { Header: AntHeader, Sider, Content } = Layout;
const TOP_NAV_HEIGHT = 70;
const TOP_ACTION_HEIGHT = 62;
const SIDE_WIDTH = 450;
const siderStyle: React.CSSProperties = {
  overflow: 'auto',
  height: '100%',
  position: 'fixed',
  insetInlineStart: 0,
  top: TOP_NAV_HEIGHT + TOP_ACTION_HEIGHT + 10,
  bottom: 0,
  scrollbarWidth: 'thin',
  scrollbarColor: 'unset',
  width: '100%',
  padding: 0,
  paddingTop: 0,
};

const HeaderStyle: React.CSSProperties = {
  position: 'fixed',
  width: '100%',
  height: TOP_NAV_HEIGHT + TOP_ACTION_HEIGHT,
  top: 0,
  zIndex: 100,
  left: 0,
  padding: 0,
};
export const EmailCreateTemplate = () => {
  return (
    <Editor>
      <div className="min-h-screen relative">
        <Layout hasSider>
          <AntHeader style={HeaderStyle}>
            <div className="h-[70px]">
              <Header />
            </div>
            <div className="bg-white h-[62px] ">
              <TabsAction />
            </div>
          </AntHeader>
          <Layout hasSider className="h-full p-0">
            <Sider style={siderStyle} width={SIDE_WIDTH}>
              <Toolbox />
            </Sider>
            <Layout
              className="h-full pt-[140px]"
              style={{
                marginInlineStart: SIDE_WIDTH,
                padding: 0,
              }}
            >
              <Content
                style={{
                  height: 'calc(100vh - 135px)',
                  transition: 'width 0.3s ease-in-out',
                  marginTop: 135,
                }}
              >
                <Viewer />
              </Content>
            </Layout>
          </Layout>
        </Layout>
      </div>
    </Editor>
  );
};
