import { getBase64 } from '@/helpers';
import dayjs from '@/helpers/dayjsHelpers';
import { Flex, message, Progress, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { nanoid } from 'nanoid';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GTGSIcon, GTGSTypography } from '../GTGS';
import styles from './styles.module.scss';

const { Dragger } = Upload;

interface UploadAvatarAreaProps extends Omit<UploadProps, 'onChange'> {
  buttonElement?: ReactNode;
  maxSize?: number;
  value?: string;
  disabled?: boolean;
  submitId?: string;
  maxFile?: number;
  onPreviewFile?: (item: any) => void;
  onChange?: (url: string | undefined) => void;
}

export interface UploadFileList extends UploadFile {
  documentId?: string;
  documentEntityId?: string;
  date?: string;
  segment?: string;
  url?: string;
  version?: number | string;
  uploadDate?: number | string;
  fileSize?: string;
  file?: RcFile;
  fileExtension?: string;
}

const UploadAvatarArea = ({
  buttonElement,
  onChange,
  listType,
  maxSize = 1,
  maxFile = 1,
  value: urlValue,
  defaultFileList,
  accept = 'image/jpeg,image/png,image/jpg',
  disabled,
  multiple = false,
  action,
  submitId,
  onPreviewFile,
  ...rest
}: UploadAvatarAreaProps) => {
  const { t } = useTranslation();
  const [fileList, setFileList] = useState<UploadFileList[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (defaultFileList?.length && fileList?.length <= 0) {
      setFileList(defaultFileList as UploadFileList[]);
    }
  }, [defaultFileList, fileList?.length]);

  const beforeUpload = useCallback(
    (file: RcFile) => {
      message.destroy();
      setErrorMessage('');
      const dataAccept = accept.split(',');
      const isJpgOrPng = dataAccept.includes(file.type);

      if (!isJpgOrPng) {
        onChange?.(undefined);
        setFileList([]);
        setErrorMessage(t('common.components.invalidFileFormat'));
      }

      const isLt2M = file.size / 1024 / 1024 < maxSize;
      if (!isLt2M) {
        onChange?.(undefined);
        setFileList([]);
        setErrorMessage(t('common.components.maxiumFileSize', { maxSize }));
      }
      return isJpgOrPng && isLt2M;
    },
    [accept, maxSize, onChange, t]
  );

  const uploadButton = useMemo(
    () => (
      <Flex
        vertical
        align="center"
        justify="flex-start"
        className="w-full h-full"
        gap={8}
      >
        <Flex className="w-[132px] h-[132px] relative">
          {!errorMessage ? (
            <Progress
              strokeColor={'#0072DE'}
              type="circle"
              percent={progress}
              size={132}
              className="rounded-full absolute z-[5]"
            />
          ) : (
            <div className="w-full h-full top-0 left-0 rounded-full absolute z-[5] outline outline-2 outline-[#ff4d4f]" />
          )}

          <Flex className="w-full h-full rounded-full border border-1 border-[#E5E7EB] z-10 absolute top-0 left-0">
            <Flex
              className="w-[120px] h-[120px] rounded-full border border-1 border-[#F3F4F6] bg-[#F3F4F6] absolute z-20 left-[50%] top-[50%] overflow-hidden"
              style={{ transform: 'translate(-50%, -50%)' }}
              align="center"
              justify="center"
            >
              {(urlValue || fileList?.[0]?.url) && !errorMessage ? (
                <img
                  className="w-full h-full cursor-pointer"
                  alt=" "
                  src={urlValue || fileList?.[0]?.url || undefined}
                />
              ) : (
                <GTGSIcon name="user" size={40} fill="#9CA3AF" />
              )}
            </Flex>
          </Flex>

          <Flex
            className="w-[28px] h-[28px] rounded-full absolute bg-[#0072DE] bottom-[10px] right-[10px] z-30"
            align="center"
            justify="center"
          >
            <GTGSIcon name="plus" size={24} fill="#fff" />
          </Flex>
        </Flex>
        <Flex className="w-full" align="center" justify="center" vertical>
          <GTGSTypography.Text className="!text-[#4B5563] !text-[14px] !text-center !leading-[20px]">
            Set workspace picture
          </GTGSTypography.Text>
          <GTGSTypography.Text className="!text-[#ff4d4f] !text-[14px] !text-center !leading-[18px]">
            {errorMessage}
          </GTGSTypography.Text>
        </Flex>
      </Flex>
    ),
    [errorMessage, fileList, progress, urlValue]
  );

  const onDrop = useCallback(
    (e) => {
      if (fileList.length + e.dataTransfer.files?.length > maxFile) {
        onChange?.(undefined);
        setFileList([]);
        setErrorMessage(t('common.components.maxiumTotalFile', { maxFile }));
      }
    },
    [fileList.length, maxFile, onChange, t]
  );

  const queryFileRequest = useCallback(
    async (file: any) => {
      if (!file) {
        onChange?.(undefined);
        setFileList([]);
        setErrorMessage(t('common.components.fileEmpty'));
        return;
      }
      if (errorMessage) return;

      const onUploadProgress = (event) => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        setProgress(percent);
        if (percent === 100) {
          setTimeout(() => setProgress(0), 1000);
        }
      };

      const urlBase64 = await getBase64(file, onUploadProgress);

      const infoFile: UploadFileList = {
        uid: file.uid || nanoid(),
        name: file.name,
        version: file.lastModified || '',
        fileExtension: file.type,
        fileName: file.name.replace(/.jpeg|.png|.jpg/g, '') || '',
        fileSize: `${(file.size / 1024 / 1024).toFixed(2)} MB`,
        uploadDate: dayjs(new Date()).valueOf() || '',
        url: urlBase64,
        file,
      };

      return [infoFile];
    },
    [errorMessage, onChange, t]
  );

  const dummyRequest = useCallback(
    async (options: any) => {
      const file = options.file;
      // const body = {
      //   key: file.name,
      //   isPrivate: true,
      //   fileSizeBytes: new Blob([file]),
      // }
      const dataCustom = await queryFileRequest(file);

      console.log('dataCustom :>> ', dataCustom);
      if (dataCustom) {
        onChange?.(dataCustom[0].url || '');
        setFileList(dataCustom);
      }
    },
    [onChange, queryFileRequest]
  );

  const UploadCustom = useMemo(
    () => (
      <Dragger
        {...rest}
        multiple={multiple}
        defaultFileList={defaultFileList}
        showUploadList={false}
        fileList={fileList}
        className={styles.upload}
        beforeUpload={beforeUpload}
        onDrop={onDrop}
        // onChange={onChangeUpload}
        name="avatar"
        accept={accept}
        customRequest={dummyRequest}
        action={action}
      >
        <div>{uploadButton}</div>
      </Dragger>
    ),
    [
      accept,
      action,
      beforeUpload,
      defaultFileList,
      dummyRequest,
      fileList,
      multiple,
      onDrop,
      rest,
      uploadButton,
    ]
  );

  return (
    <Flex className="w-full h-[202px]">
      <ImgCrop
        rotationSlider={true}
        beforeCrop={beforeUpload}
        modalOk="Save"
        cropShape="round"
        modalWidth={600}
        modalTitle="Upload avatar"
        modalProps={{
          cancelButtonProps: {
            className: 'text-[14px] w-[80px]',
          },
          okButtonProps: {
            className: 'text-[14px] w-[80px]',
          },
        }}
      >
        {UploadCustom}
      </ImgCrop>
    </Flex>
  );
};

export default UploadAvatarArea;
