import React, { useState } from 'react';
import { FiMenu } from 'react-icons/fi';
import TermsModal from './TermsModal';
import ChatButton from '@/pages/PageBuilder/components/live-chat/ChatButton';

const Header: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeButton, setActiveButton] = useState<string | null>(null);

  const handleButtonClick = (buttonName: string, sectionId?: string) => {
    setActiveButton(buttonName);
    if (buttonName === 'Term') setIsModalOpen(true);
    if (sectionId)
      document
        .getElementById(sectionId)
        ?.scrollIntoView({ behavior: 'smooth' });
  };
  const handleLoginClick = () => {
    window.location.href = 'https://app.gtgcrm.com/';
  };
  const buttons = [
    { name: 'Home' },
    { name: 'Pricing', sectionId: 'pricing-section' },
    { name: 'Help', sectionId: 'help-section' },
    { name: 'Term' },
  ];

  return (
    <>
      <header className="w-full h-[96px] flex justify-between items-center mb-[40px] px-4 relative z-50">
        <div className="text-2xl font-bold text-[#005CE6]">
          <span className="font-extrabold">GTG</span>
          <span className="bg-[#005CE6] text-white px-2 py-1 rounded">CRM</span>
        </div>

        <div className="hidden lg:flex space-x-6">
          {buttons.map(({ name, sectionId }) => (
            <button
              key={name}
              onClick={() => handleButtonClick(name, sectionId)}
              className={`text-[#0F172A] w-[78px] h-[35px] rounded-lg ${
                activeButton === name ? 'bg-[#DEEDFF]' : ''
              } hover:bg-[#DEEDFF]`}
            >
              {name}
            </button>
          ))}
        </div>

        <button
          onClick={handleLoginClick}
          className="hidden lg:block px-4 py-2 border border-[#0D0F2E] rounded hover:bg-gray-100"
        >
          Login
        </button>

        <div className="lg:hidden relative">
          <button onClick={() => setIsMenuOpen(!isMenuOpen)}>
            <FiMenu size={24} />
          </button>
          {isMenuOpen && (
            <div className="absolute right-0 top-full mt-2 w-36 bg-white shadow-lg rounded-lg flex flex-col items-start py-2 z-50">
              {buttons.map(({ name, sectionId }) => (
                <button
                  key={name}
                  onClick={() => handleButtonClick(name, sectionId)}
                  className={`text-[#0F172A] w-full text-center px-4 py-2 rounded-lg ${
                    activeButton === name ? 'bg-[#DEEDFF]' : ''
                  } hover:bg-[#DEEDFF]`}
                >
                  {name}
                </button>
              ))}
              <button
                onClick={handleLoginClick}
                className="text-[#0F172A] w-full text-center px-4 py-2 rounded-lg hover:bg-[#DEEDFF]"
              >
                Login
              </button>
            </div>
          )}
        </div>
        <ChatButton />
      </header>

      <TermsModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </>
  );
};

export default Header;
