import { createRoute } from '@/helpers';
import React from 'react';
import { routesPath } from './constants';

const AccessPage = React.lazy(
  () => import('@/pages/WorkspaceSettings/AccessPage')
);
const EditsPage = React.lazy(
  () => import('@/pages/WorkspaceSettings/EditAccess')
);

const DeleteWorkspacePage = React.lazy(
  () => import('@/pages/WorkspaceSettings/DeleteWorkspace')
);
const UserManagementPage = React.lazy(
  () => import('@/pages/WorkspaceSettings/UserManagement')
);
// --------------------------------------------------------------------------- ROUTER
const RouterSettings = {
  path: routesPath.SETTINGS,
  children: [
    createRoute(routesPath.ACCESS_LEVEL_CONTROL, <AccessPage />),
    createRoute(routesPath.EDIT_ACCESS_LEVEL, <EditsPage />),
    createRoute(routesPath.USER_MANAGEMENT, <UserManagementPage />),
    createRoute(routesPath.DEACTIVATE_WORKSPACE, <DeleteWorkspacePage />),
  ],
};

export default RouterSettings;
