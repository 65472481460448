import { SHORT_DATE_FORMAT } from '@/constants/date-time';
import dayjs from 'dayjs';
import 'dayjs/locale/vi';
import { DateInput, DateRange } from '@/types/date-time';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import updateLocale from 'dayjs/plugin/updateLocale';

export const initDateTimeSetup = () => {
  dayjs.extend(customParseFormat);
  dayjs.extend(advancedFormat);
  dayjs.extend(weekday);
  dayjs.extend(localeData);
  dayjs.extend(weekOfYear);
  dayjs.extend(weekYear);
  dayjs.extend(utc);
  dayjs.extend(relativeTime);
  dayjs.extend(timezone);
  dayjs.extend(updateLocale)
};
const formatTimezone = (d: DateInput, ianaTimezone: string) =>
  ianaTimezone?.toLowerCase().trim() === 'etc/utc'
    ? dayjs.utc(d)
    : dayjs.tz(d, ianaTimezone);

export const convertToTimezone = (ianaTimezone: string, d: DateInput) => {
  if (!ianaTimezone) return '';

  if (d instanceof Date) {
    return formatTimezone(d, ianaTimezone);
  }

  if (typeof d === 'string' || typeof d === 'number') {
    const date = new Date(d);
    return formatTimezone(date, ianaTimezone);
  }

  return '';
};

export type FormatDateTimeFunc = (
  ianaTimezone: string,
  d: DateInput,
  format?: string
) => string;

export const formatDateTime: FormatDateTimeFunc = (
  ianaTimezone,
  d,
  format = SHORT_DATE_FORMAT
) => {
  if (d instanceof Date) {
    const date = convertToTimezone(ianaTimezone, d);
    return date ? date.format(format) : '';
  }

  if (typeof d === 'string' || typeof d === 'number') {
    const date = convertToTimezone(ianaTimezone, new Date(d));
    return date ? date.format(format) : '';
  }

  return '';
};

type ReturnType = 'second' | 'millisecond';
export const formatDateRange = (
  ianaTimezone: string,
  dateRange: DateRange,
  type: ReturnType = 'second'
) => {
  const startDate = dateRange.startDate
    ? formatTimezone(dateRange.startDate.toDate(), ianaTimezone)
        .startOf('day')
        .unix()
    : undefined;
  const endDate = dateRange.endDate
    ? formatTimezone(dateRange.endDate.toDate(), ianaTimezone)
        .endOf('day')
        .unix()
    : undefined;

  if (type === 'millisecond') {
    return {
      startDate: startDate ? startDate * 1000 : undefined,
      endDate: endDate ? endDate * 1000 : undefined,
    };
  }

  return { startDate, endDate };
};

export const getAllDateRange = (ianaTimezone: string) => {
  const now = formatTimezone(new Date(), ianaTimezone);
  return {
    Today: [now.clone().startOf('day'), now.clone().endOf('day')],
    Yesterday: [
      now.clone().subtract(1, 'day').startOf('day'),
      now.clone().subtract(1, 'day').endOf('day'),
    ],
    'This week': [
      now.clone().startOf('isoWeek').startOf('day'),
      now.clone().endOf('isoWeek').endOf('day'),
    ],
    'Last week': [
      now.clone().subtract(1, 'week').startOf('isoWeek').startOf('day'),
      now.clone().subtract(1, 'week').endOf('isoWeek').endOf('day'),
    ],
    'This month': [
      now.clone().startOf('month').startOf('day'),
      now.clone().endOf('month').endOf('day'),
    ],
    'Last month': [
      now.clone().subtract(1, 'month').startOf('month').startOf('day'),
      now.clone().subtract(1, 'month').endOf('month').endOf('day'),
    ],
  };
};

export const getDefaultDateRange = (ianaTimezone: string) => {
  const [startDate, endDate] = getAllDateRange(ianaTimezone)['This week'];
  return {
    startDate,
    endDate,
  };
};

export const getTimeFromNow = (d: DateInput, locale: string) =>
  dayjs(d).locale(locale).fromNow();

export const setDateTimeLocale = (locale: string) => dayjs.locale(locale);

export const dateTimeFormatter = dayjs;

export const formatLocalDateTime = (d: DateInput, format = SHORT_DATE_FORMAT) =>
  dayjs(d).format(format);
