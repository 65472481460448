import UserDisplayDetailsForm from '@/pages/WorkSpace/components/Form/UserDisplayDetailsForm';
import { Modal, ModalProps, Spin } from 'antd';
import { FC } from 'react';
import styles from './styles.module.scss';

interface IModalProps extends ModalProps {
    onApply?: (values: any) => void;
    loading?: boolean;
}

const ModalSetUpUser: FC<IModalProps> = ({
    open,
    onCancel,
    onApply,
    loading = false
}) => {

    const handleOnCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
        onCancel?.(e);
    };

    return (
        <Modal
            open={open}
            closable={false}
            title=""
            width={540}
            footer={null}
            className={styles.modal}
            centered
            maskClosable={false}
            destroyOnClose
        >
            <Spin spinning={loading}>
                <UserDisplayDetailsForm
                    onClickNext={onApply}
                    buttonText="Save"
                    header={{
                        title: 'Set up user display',
                        subTitle:
                            'This process involves configuring how your user profile will appear within the workspace before officially joining.',
                    }}
                    key="SetUpUserDisplayDetailsForm"
                    onCancel={handleOnCancel}
                />
            </Spin>
        </Modal>
    );
};

export { ModalSetUpUser, type IModalProps };
