import { Loading } from '@/components';
import { GTGSTypography } from '@/components/GTGS';
import { trans } from '@/i18n';
import colors from '@/theme/colors.module.scss';
import { Space } from 'antd';
import type { RcFile } from 'antd/es/upload/interface';
import { Suspense } from 'react';

function renderTitle(titleUp: string, titleDown?: string) {
  return (
    <Space size={0} direction="vertical">
      <GTGSTypography.Text strong size="sm">
        {titleUp}
      </GTGSTypography.Text>
      {titleDown && (
        <GTGSTypography.Text
          size="xs"
          style={{ color: colors.colorTextSecondary, fontWeight: 'normal' }}
        >
          {titleDown}
        </GTGSTypography.Text>
      )}
    </Space>
  );
}

function renderContent(
  contentUp: string | JSX.Element,
  contentDown?: string | JSX.Element,
  contentDownSmall?: boolean
) {
  return (
    <Space size={0} direction="vertical">
      <GTGSTypography.Text size="sm">{contentUp || '-'}</GTGSTypography.Text>

      <GTGSTypography.Text
        size={`${contentDownSmall ? 'xs' : 'sm'}`}
        style={
          contentDownSmall
            ? { color: colors.colorTextSecondary }
            : { color: colors.colorTextPrimary }
        }
      >
        {contentDown}
      </GTGSTypography.Text>
    </Space>
  );
}

function renderContentTruncate(
  content: string | number | null | undefined,
  line: number = 2
) {
  const styles = {
    display: '-webkit-box',
    WebkitLineClamp: line,
    WebkitBoxOrient: 'vertical' as const,
    overflow: 'hidden',
  };
  const c = content?.toString() ?? '';
  return (
    <span className="truncate" title={c} style={styles}>
      {c}
    </span>
  );
}

const getBase64 = (
  file: RcFile | Blob,
  onUploadProgress?: (ev) => void
): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onprogress = (ev) => {
      if (ev.loaded && ev.total) {
        onUploadProgress?.(ev);
      }
    };
    reader.onload = () => {
      resolve(reader.result as string);
    };
    reader.onerror = (error) => reject(error);
  });

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string?.slice(1)?.toLowerCase();
}

const replaceDataNull = (object: any) => {
  if (!object) return {};
  let ObjectData = {};
  Object.keys(object)?.forEach((key) => {
    if (key)
      ObjectData = {
        ...ObjectData,
        [key]:
          typeof object?.[key] !== 'boolean'
            ? object?.[key] && object?.[key] !== null
              ? typeof object?.[key] === 'string'
                ? object?.[key]?.trim()
                : object?.[key]
              : ''
            : object?.[key] || false,
      };
  });

  return ObjectData;
};

function paramsToUrl(object: any) {
  let string = '';
  for (const key in object) {
    string += `&${key}=${decodeURIComponent(object[key])}`;
  }

  if (string) return `?${string}`;
  return '';
}

function customOptions(options: any) {
  return (options ?? []).map((item) => ({
    value: item.code,
    // value: item.nameEn,
    label: item.nameEn || item.name,
  }));
}

function getOption(options: any, code: string) {
  return (
    (options ?? []).find((item) => item.value === code || item.label === code)
      ?.label || ''
  );
}

function truncate(str = '', n) {
  return str?.length > n ? str?.trim()?.slice(0, n - 1) + '...' : str;
}

const uniqueID = () => `_${Math.random().toString(36).substring(2, 9)}`;

function getErrorDetails(error: any) {
  const detailsMessage = error?.response?.data?.error?.message;

  let subText = '';
  if (detailsMessage) {
    subText = detailsMessage;
  }
  return subText;
}

function labelForm(label: React.ReactNode, isOptional = false) {
  return (
    <>
      <GTGSTypography.Text size="sm">{label}&nbsp;</GTGSTypography.Text>
      {isOptional && (
        <GTGSTypography.Text size="sm" status="secondary">
          {`(${trans('common.optional')})`}
        </GTGSTypography.Text>
      )}
    </>
  );
}

function titleCase(str: string, textJoin?: string) {
  const convertToArray = str.toLowerCase().split(' ');
  const result = convertToArray.map(function (val) {
    return val.replace(val.charAt(0), val.charAt(0).toUpperCase());
  });

  return result.join(textJoin ?? ' ');
}

function shallowObjectEqual(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (object1[key] !== object2[key]) {
      return false;
    }
  }

  return true;
}

function customLocationOptions(options: any, name?: string) {
  return (options ?? [])
    .map((item) => ({
      ...item,
      value: name
        ? item?.[name]?.toString()
        : item.addressCode?.toString() || item?.id?.toString(),
      label: item.name,
      id: item?.id?.toString(),
    }))
    ?.sort((a, b) => a.label?.localeCompare(b?.label));
}

function customFromKeyToOptions(keys: any) {
  return (keys ?? []).map((key) => ({
    value: key?.toString(),
    label: titleCase(key),
  }));
}
function customOptionsCustomer(options: any) {
  return (options ?? []).map((item) => ({
    value: item?.code?.toString() || item?.id?.toString(),
    code: item?.code?.toString() || item?.id?.toString(),
    label: item?.description,
    id: item?.id?.toString(),
  }));
}
// const getLocationCode = (countryCode) => {
//   const code = countryCode?.slice(0, 2) || '';
//   if (countries.isValid(code)) return code?.toUpperCase() || '';
//   return '';
// };
// const getNationalityName = (countryCode, lang = 'en') => {
//   return nationality.getName(getLocationCode(countryCode), lang);
// };
// const getCountryName = (countryCode, lang = 'en') => {
//   return countries.getName(getLocationCode(countryCode), lang);
// };
const checkULRType = (url, type?: string) => {
  const splitedArray = url.split('\\.');
  const lastValueOfArray = [
    ...(splitedArray[splitedArray.length - 1] ?? []),
    type ?? '',
  ].filter(Boolean);

  if (
    lastValueOfArray.includes('mp4') ||
    lastValueOfArray.includes('flv') ||
    lastValueOfArray.includes('m4a') ||
    lastValueOfArray.includes('3gp') ||
    lastValueOfArray.includes('mkv')
  ) {
    return 'video';
  } else if (
    lastValueOfArray.includes('mp3') ||
    lastValueOfArray.includes('ogg')
  ) {
    return 'audio';
  } else if (
    lastValueOfArray.includes('jpg') ||
    lastValueOfArray.includes('png') ||
    lastValueOfArray.includes('gif')
  ) {
    return 'photo';
  } else {
    return '';
  }
};

const customResponsePagination = (data: {
  content: any[];
  totalElementsCount: number;
  currentPage: number;
  pageSize: number;
}) => {
  const pageCount = Math.ceil(data.totalElementsCount / data.pageSize);
  const hasNext = data.currentPage < pageCount;
  const hasPrevious = data.currentPage > 1;

  return {
    currentPage: data.currentPage,
    hasNext,
    hasPrevious,
    pageCount,
    pageSize: data.pageSize,
    totalElementsCount: data.totalElementsCount,
    content: data.content,
  };
};

const SuspenseWrapper = ({ children }: { children: React.ReactNode }) => (
  <Suspense fallback={<Loading />}>{children}</Suspense>
);

const createRoute = (path: string, element: React.ReactNode) => ({
  path,
  element: <SuspenseWrapper>{element}</SuspenseWrapper>,
});

export {
  capitalizeFirstLetter,
  // getNationalityName,
  // getCountryName,
  checkULRType,
  customFromKeyToOptions,
  customLocationOptions,
  customOptions,
  customOptionsCustomer,
  customResponsePagination,
  getBase64,
  getErrorDetails,
  getOption,
  labelForm,
  paramsToUrl,
  renderContent,
  renderContentTruncate,
  renderTitle,
  replaceDataNull,
  titleCase,
  truncate,
  uniqueID,
  shallowObjectEqual,
  SuspenseWrapper,
  createRoute
};
