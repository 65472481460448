import { GTGSIcon, GTGSTypography } from '@/components/GTGS';
import { Flex } from 'antd';
// ===========================================================================
// MAIN
// ===========================================================================

interface BackFormProps {
  onBack?: () => void;
}
const BackForm = ({ onBack }: BackFormProps) => {
  return (
    <Flex
      justify="flex-start"
      align="center"
      gap={8}
      onClick={onBack}
      className="cursor-pointer"
    >
      <Flex
        className="!rounded-full border border-1 border-[#E5E7EB] p-2 "
        align="center"
        justify="center"
      >
        <GTGSIcon size={18} name="arrow-left" fill="#000000" />
      </Flex>
      <GTGSTypography.Text strong className="!text-[#000000] !text-[14px]">
        Back
      </GTGSTypography.Text>
    </Flex>
  );
};

export default BackForm;
