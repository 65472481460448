import { useNode } from '@craftjs/core';

function CTA({
  title,
  description,
  buttonText,
}: {
  title: string;
  description: string;
  buttonText: string;
}) {
  const {
    connectors: { connect },
    id,
  } = useNode();

  return (
    <div
      className="bg-white text-[#183B56] py-12 px-6 md:px-12 lg:px-24  text-center"
      ref={connect as React.LegacyRef<HTMLDivElement>}
      id={id}
    >
      <h2 className="text-2xl font-bold  mb-4 md:text-3xl lg:text-4xl">
        {title}
      </h2>
      <p className="mb-6 text-sm font-normal md:text-[18px] lg:text-lg max-w-3xl mx-auto ">
        {description}
      </p>
      <p className=" mb-8 font-normal  text-sm md:text-[18px] lg:text-lg max-w-3xl mx-auto">
        Request a demo to explore our premium software, or begin with our
        comprehensive set of free tools and scale up as your business grows.
      </p>
      <button className="w-[80%] md:w-[250px] md:h-[48px] text-[#FFFFFF] bg-[#005CE6]   font-semibold rounded-lg text-[10px] px-5 py-2.5 text-center  ">
        {buttonText}
      </button>
    </div>
  );
}

CTA.defaultProps = {
  title: 'About Us',
  description:
    'GTG CRM is a customer platform that provides all the software, integrations, and resources necessary to streamline your marketing, sales, and customer service efforts. With GTG CRMs unified platform, you can accelerate business growth by prioritizing what matters most: your customers.',
  buttonText: 'REQUEST NOW',
};

const CTASettings = () => {
  const {
    actions: { setProp },
    title,
    description,
    buttonText,
  } = useNode((node) => ({
    title: node.data.props.title,
    description: node.data.props.description,
    buttonText: node.data.props.buttonText,
  }));
  return (
    <div>
      <div className="mb-5">
        <label className="block mb-2 font-medium text-gray-700">Title</label>
        <input
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          defaultValue={title}
          onChange={(e) => {
            setProp(
              (props: { title: string }) => (props.title = e.target.value),
              1000
            );
          }}
        />
      </div>
      <div className="mb-5">
        <label className="block mb-2 font-medium text-gray-700">
          Description
        </label>
        <textarea
          rows={8}
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          defaultValue={description}
          onChange={(e) => {
            setProp(
              (props: { description: string }) =>
                (props.description = e.target.value),
              1000
            );
          }}
        />
      </div>
      <div className="mb-5">
        <label className="block mb-2 font-medium text-gray-700">
          Button Text
        </label>
        <input
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          defaultValue={buttonText}
          onChange={(e) => {
            setProp(
              (props: { buttonText: string }) =>
                (props.buttonText = e.target.value),
              1000
            );
          }}
        />
      </div>
    </div>
  );
};

CTA.craft = {
  props: CTA.defaultProps,
  related: {
    settings: CTASettings,
  },
  displayName: 'About Us',
};

export default CTA;
