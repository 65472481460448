import React, { useRef } from 'react';
import { useNode } from '@craftjs/core';

const StorySection: React.FC = () => {
  const {
    connectors: { connect, drag },
  } = useNode();
  const sectionRef = useRef<HTMLDivElement>(null);

  return (
    <div
      ref={(ref) => ref && connect(drag(ref))}
      className="bg-[#F9FBFE] p-10 flex flex-col items-center gap-8"
    >
      <div className="flex flex-col md:flex-row items-start md:items-center gap-8 w-full max-w-5xl">
        <div className="max-w-[440px] p-8">
          <h2 className="text-[40px] font-bold text-[#183B56] mb-8 leading-tight">
            <span>The Power</span> <br />
            <span>of Purity Source</span>
          </h2>
          <p className="text-[#183B56] text-balance text-[16px] font-light leading-relaxed mb-8">
            Inspired by hydration and Japan’s beauty rituals, our focus is on
            the core elements of skincare to combine powerful ingredients backed
            by science and authentically Japanese minimalist philosophy.
          </p>
          <p className="text-[#183B56] text-[16px] font-light  leading-relaxed mb-8">
            A blessing of water for every skin. Relieving dryness with gentle
            touch.
          </p>
          <ul className="space-y-2">
            <li className="flex items-center text-[#183B56] font-light  text-[16px] leading-relaxed">
              <span className="!text-green-500 mr-2">✔</span> Our Cosmetics is
              100% from Natural Ingredients
            </li>
            <li className="flex items-center text-[#183B56] font-light  text-[16px] leading-relaxed">
              <span className="!text-green-500 mr-2">✔</span> All of packaging
              process by modern machine
            </li>
            <li className="flex items-center text-[#183B56] font-light  text-[16px] leading-relaxed">
              <span className="!text-green-500 mr-2">✔</span> You can return
              our product if not original
            </li>
          </ul>
          <div className="mt-8 ">
            <img
              src="/cosmetic/leaf.png"
              alt="Leaf Image"
              width="172"
              height="258"
            />
          </div>
        </div>

        <div className="relative flex items-start">
          {/* Main Product Image */}
          <div className="border p-1 rounded-lg bg-white shadow-lg z-10 mr-[-10%]">
            <img src="/cosmetic/story-1.png" alt="Product Image 1" />
          </div>

          {/* Supporting Product Image, positioned slightly behind */}
          <div className="mt-[20%] rounded-lg shadow-lg">
            <img src="/cosmetic/story-2.png" alt="Product Image 2" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StorySection;
