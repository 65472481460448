import { getWorkspaces } from '@/services/workspacesService';
import { useQuery } from 'react-query';

const useGetWorkspacesQuery = (params) => {
  const { data: workspaces, isLoading, refetch, isFetching } = useQuery(
    ['getWorkspaces', { params} ],
    () => getWorkspaces(params),
    {
      cacheTime: 60 * 60,
      enabled: !!params?.size && params?.page !== undefined,
    }
  );

  return {
    data: (workspaces?.data.content ?? []) as any[] ,
    isLoading: isLoading,
    isFetching,
    refetch,
  };
};

export default useGetWorkspacesQuery;
