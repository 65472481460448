import { createElement, memo } from 'react';
import { useTemplate } from '../hooks/useTemplate';

export const TemplateSettings = memo(() => {
  const { settings: Settings } = useTemplate();

  return (
    <>
      <Settings />
    </>
  );
});
