import { useNode, useEditor } from '@craftjs/core';
import { useCallback, CSSProperties, useEffect } from 'react';
import { createPortal } from 'react-dom';
import Dot from '../../../../assets/icons/dot.svg';

const dragStyle: CSSProperties = {
  position: 'fixed',
  top: -1,
  background: '#005CE6',
  width: 16,
  border: '1px #005CE6',
  cursor: 'move',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};
const borderStyle: CSSProperties = {
  position: 'fixed',
  pointerEvents: 'none',
  border: '1px #005CE6',
};

export const Indicator = ({ render }) => {
  const { id } = useNode();
  const { query, isActive } = useEditor((_, query) => ({
    isActive: query.getEvent('selected').contains(id),
  }));
  const {
    isHover,
    dom,
    moveable,
    connectors: { drag },
    parent,
  } = useNode((node) => ({
    isHover: node.events.hovered,
    dom: node.dom,
    name: node.data.custom.displayName || node.data.displayName,
    moveable: query.node(node.id).isDraggable(),
    deletable: query.node(node.id).isDeletable(),
    parent: node.data.parent,
    props: node.data.props,
  }));
  const getPos = useCallback((dom: HTMLElement) => {
    const { top, left, width, height } = dom
      ? dom.getBoundingClientRect()
      : { top: 0, left: 0, width: 0, height: 0 };

    return {
      top: top,
      left: left,
      width: width,
      height: height,
    };
  }, []);

  const document = dom?.ownerDocument;
  const container = document?.querySelector('.renderer');
  const isShowDrag = Boolean(container) && isActive && moveable;
  const isShowBorder = Boolean(container) && (isHover || isActive);
  const Drag = () => {
    const domPosition = getPos(dom!);
    return (
      isShowDrag &&
      createPortal(
        <div
          ref={(r) => drag(r!)}
          style={{
            ...dragStyle,
            top: domPosition.top,
            left: domPosition.left - 16,
            height: domPosition.height,
            zIndex: 9999,
          }}
        >
          <img src={Dot} />
        </div>,
        container!
      )
    );
  };

  const Border = () => {
    const domPosition = getPos(dom!);
    return (
      isShowBorder &&
      createPortal(
        <div
          style={{
            ...borderStyle,
            ...domPosition,
            borderStyle: isActive ? 'solid' : 'dashed',
            zIndex: 9999,
          }}
        ></div>,
        container!
      )
    );
  };

  return (
    <>
      <Drag />
      <Border />
      {render}
    </>
  );
};
