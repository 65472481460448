import React from 'react';
import { Form, Input, Typography, Button } from 'antd';

const { Title, Text } = Typography;

const Automation: React.FC = () => {
  const [form] = Form.useForm();

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        console.log('Form Data:', values);
      })
      .catch((info) => {
        console.log('Validation Failed:', info);
      });
  };

  return (
    <div className="p-8 bg-white">
      <Title level={4}>Automate what happens after your ad is published</Title>

      <div className="mt-4">
        <Text strong>Add contacts to a list</Text>
        <Text type="secondary" className="block mt-1">
          When a contact interacts with your ads, add them to an active list so
          you can market to them later.
        </Text>
      </div>

      <Form
        form={form}
        layout="vertical"
        className="mt-4"
        onFinish={handleSubmit}
      >
        <Form.Item
          label="List name"
          name="listName"
          rules={[{ required: true, message: 'Please enter a list name' }]}
        >
          <Input className="w-1/2" placeholder="Input here" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Automation;
